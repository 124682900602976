/**
 * ConsignmentResultSet model
 * represents API result set when searching/listing consignments
 */

import { CONSIGNMENT_TYPES } from '@/shared/models/Consignment/Consignment';
import { consignmentSummaryFactory } from '@/shared/models/Consignment/ConsignmentSummary';

const create = ({ consignments = [], total = 0, numPages = -1 } = {}) => ({
  consignments: consignments.map(consignmentSummaryFactory.create),
  total,
  numPages,
});

const ConsignmentResultSet = {
  create,
};

const ConsignmentFilterTypes = {
  All: 'all',
  Outbound: CONSIGNMENT_TYPES.OUTBOUND,
  Inbound: CONSIGNMENT_TYPES.INBOUND,
  Transfer: CONSIGNMENT_TYPES.TRANSFER,
};

export { ConsignmentResultSet as default, ConsignmentFilterTypes };
