<script setup lang="ts">
  import { ValidationResult } from 'veritas';
  import { computed } from 'vue';

  import SvgIcon from '@/shared/icons/SvgIcon.vue';
  import CheckIcon from '@/shared/icons/svgs/check.svg';
  import CloseIcon from '@/shared/icons/svgs/close.svg';
  import DashIcon from '@/shared/icons/svgs/dash.svg';

  const props = defineProps<{
    validation: ValidationResult;
    validationKey: string;
  }>();

  const isValid = computed(() => !props.validation[props.validationKey]?.$invalid);

  const isDirty = computed(() => props.validation.$dirty);

  const svg = computed(() => {
    if (isValid.value) return CheckIcon;
    if (isDirty.value) return CloseIcon;
    return DashIcon;
  });

  const color = computed(() => {
    if (isValid.value) return '--success-100';
    if (isDirty.value) return '--danger-100';
    return '--copy-50';
  });
</script>

<template>
  <div class="has-text-grey">
    <SvgIcon :svg="svg" :style="`color: var(${color});`" class="mr-1" />
    <slot></slot>
  </div>
</template>

<style scoped>
  div {
    font-style: italic;
  }
  .validation-icon {
    padding-right: 0.35em;
  }
</style>
