import config, { appcues, datadog, geppetto, rum } from '@/config';

import UserMonitor from '@/shared/analytics/user-monitor';

const userMonitor = new UserMonitor({
  app: config,
  appcues,
  datadog,
  rum,
  ignoreErrorsCausedBy: geppetto.errorMonitoring?.ignoreErrorsCausedBy || [],
});

export default userMonitor;
