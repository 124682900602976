<script setup>
  import { computed, inject, ref } from 'vue';

  import ExternalLinkIcon from '@/shared/icons/svgs/share-external-link.svg';

  import ListBoxItem from './ListBoxItem.vue';
  import LIST_BOX_ITEM_PROPS from './listBoxItemProps';

  const props = defineProps({
    ...LIST_BOX_ITEM_PROPS,
    to: {
      type: Object,
      required: true,
    },
  });

  const item = ref(null);
  const inner = ref(null);

  const activateInner = event => {
    if (event.target === item.value?.$el) {
      event.stopPropagation();
      inner.value?.$el.click();
    }
  };

  const isExternalLink = computed(() => {
    const { to } = props;
    return typeof to === 'string' && to.startsWith('http') && !to.startsWith(document.location.origin);
  });

  const listBoxProps = computed(() => {
    const { to, ...otherProps } = props;
    return otherProps;
  });

  const disableTab = inject('disableTab', false);
</script>

<template>
  <ListBoxItem
    v-bind="listBoxProps"
    ref="item"
    :icon-right="isExternalLink ? ExternalLinkIcon : listBoxProps.iconRight"
    interactive
    no-padding
    @click="activateInner"
  >
    <RouterLink ref="inner" :to="to" class="list-box-item-router-link" :tabindex="disableTab ? -1 : 0">
      <slot></slot>
    </RouterLink>
  </ListBoxItem>
</template>

<style scoped lang="scss">
  .list-box-item-router-link {
    all: unset; // clear link styles to inherit from list-box-item
    display: block;
    flex-grow: 1;
    padding: 4px 0;

    .is-active &,
    &.router-link-active {
      font-weight: $font-weight-medium;
    }
  }
</style>
