import { asyncImport, withContextPrefix } from '@App/router/helpers';

const PickupRoutes = [
  ...withContextPrefix(
    {
      path: 'pickup',
      meta: {
        requiresAuthentication: true,
      },
    },
    [
      {
        path: 'create',
        name: 'pickup.create',
        // No actual content at this route, redirect to a child route
        redirect: { name: 'pickup.create.outbound' },
        meta: {
          context: {
            allowed: ['site'],
            selectSiteFor: 'pickup',
          },
        },
        children: [
          {
            path: 'outbound',
            name: 'pickup.create.outbound',
            component: asyncImport(() => import('@Pickup/views/PickupCreate.vue')),
            meta: {
              title: 'Create outbound pickup',
              enableFullStory: true,
            },
            props: {
              pickupType: 'outbound',
            },
          },
          {
            path: 'inbound',
            name: 'pickup.create.inbound',
            component: asyncImport(() => import('@Pickup/views/PickupCreate.vue')),
            meta: {
              title: 'Create inbound pickup',
              enableFullStory: true,
            },
            props: {
              pickupType: 'inbound',
            },
          },
          {
            path: 'transfer',
            name: 'pickup.create.transfer',
            component: asyncImport(() => import('@Pickup/views/PickupCreate.vue')),
            meta: {
              title: 'Create transfer pickup',
              enableFullStory: true,
            },
            props: {
              pickupType: 'transfer',
            },
          },
        ],
      },
      {
        path: 'prefill',
        name: 'pickup.prefill',
        // No actual content at this route, redirect to a child route
        redirect: { name: 'pickup.prefill.outbound' },
        meta: {
          context: {
            allowed: ['site'],
            selectSiteFor: 'pickup',
          },
        },
        children: [
          {
            path: 'outbound',
            name: 'pickup.prefill.outbound',
            component: asyncImport(() => import('@Pickup/views/PickupCreateFromConsignments.vue')),
            meta: {
              title: 'Create outbound pickup',
              enableFullStory: true,
            },
            props: {
              pickupType: 'outbound',
            },
          },
          {
            path: 'inbound',
            name: 'pickup.prefill.inbound',
            component: asyncImport(() => import('@Pickup/views/PickupCreateFromConsignments.vue')),
            meta: {
              title: 'Create inbound pickup',
              enableFullStory: true,
            },
            props: {
              pickupType: 'inbound',
            },
          },
          {
            path: 'transfer',
            name: 'pickup.prefill.transfer',
            component: asyncImport(() => import('@Pickup/views/PickupCreateFromConsignments.vue')),
            meta: {
              title: 'Create transfer pickup',
              enableFullStory: true,
            },
            props: {
              pickupType: 'transfer',
            },
          },
        ],
      },
      {
        path: 'manage',
        name: 'pickup.manage',
        component: asyncImport(() => import('@Pickup/views/PickupList.vue')),
        meta: {
          title: 'Manage pickups',
          enableFullStory: true,
          context: {
            allowed: ['site', 'org'],
          },
        },
      },
      // Temporal redirection, remove when we confirm this url is no longer used
      {
        path: 'list',
        meta: { enableFullStory: true },
        redirect: { name: 'pickup.manage' },
      },
      {
        path: ':pickupId',
        name: 'pickup.view',
        component: asyncImport(() => import('@Pickup/views/PickupView.vue')),
        props: true,
        meta: {
          title: 'View pickup',
          enableFullStory: true,
          context: {
            allowed: ['site'],
            actionDescription: 'viewing this pickup',
          },
        },
      },
      {
        path: '',
        redirect: { name: 'pickup.manage' },
      },
    ],
  ),
];

export default PickupRoutes;
