import { computed, ComputedRef, InjectionKey, provide } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import OrgUser from '@/shared/models/OrgUser';

import { forceNavigation } from '@App/router/forceNavigation';
import App from '@App/store/types';

type AppUser = OrgUser;

type UseUser = {
  user: ComputedRef<AppUser>;
  userIsAuthenticated: ComputedRef<boolean>;
};

export const userUserInjectionKey = Symbol('useUser') as InjectionKey<UseUser>;

const useManageUserSession = () => {
  const router = useRouter();
  const store = useStore();

  const user = computed<AppUser>(() => store.getters[App.getters.user]);
  const userIsAuthenticated = computed(() => user.value?.username !== undefined);

  // provide for useUser
  provide(userUserInjectionKey, {
    user,
    userIsAuthenticated,
  });

  const confirmSession = async () => store.dispatch(App.actions.confirmSession);

  const checkExistingSession = async () => {
    await store.dispatch(App.actions.checkExistingSession);
  };

  const clearSession = async () => {
    await store.dispatch(App.actions.logout);
  };

  const logout = async ({ force }: { force: boolean } = { force: false }) => {
    const logoutLocation = force ? forceNavigation({ name: 'auth.logout' }) : { name: 'auth.logout' };
    await router.push(logoutLocation);
  };

  return {
    user,
    userIsAuthenticated,

    confirmSession,
    checkExistingSession,
    clearSession,
    logout,
  };
};

export default useManageUserSession;
