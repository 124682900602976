import { PICKUP_ACTIONS } from './consts';
import CarrierPickup from './views/CarrierPickup.vue';

export default [
  {
    path: '/carrier-pickup',
    component: CarrierPickup,
    meta: { title: 'View pickup response' },
    props: route => ({
      action: Object.values(PICKUP_ACTIONS).includes(route.query.action) ? route.query.action : undefined,
      token: route.query.token,
    }),
  },
];
