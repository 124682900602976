<template>
  <ModalCard :title="title" size="small" :quick-close="false">
    <ControlSearch v-model="filter" placeholder="Search sites" />
    <div class="site-select-sites">
      <ControlRadio v-for="site in selectableSites" :key="site.id" v-model="siteId" :value="site.id" sensitive>
        <TextHighlight :highlight="filter" :text="site.name" />
      </ControlRadio>
    </div>
    <template #footer>
      <div class="buttons is-right">
        <BaseButton variant="secondary" @click="cancel()">Cancel</BaseButton>
        <BaseButton variant="primary" :disabled="!siteId" @click="confirm()">Confirm</BaseButton>
      </div>
    </template>
  </ModalCard>
</template>

<script setup>
  import { computed, ref, watch } from 'vue';

  import BaseButton from '@/shared/components/BaseButton.vue';
  import ControlRadio from '@/shared/components/controls/ControlRadio.vue';
  import ControlSearch from '@/shared/components/controls/ControlSearch.vue';
  import ModalCard from '@/shared/modals/ModalCard.vue';
  import { useModal } from '@/shared/modals/modalManager';

  import TextHighlight from '@/shared/TextHighlight/TextHighlight.vue';

  const props = defineProps({
    selectSiteFor: {
      type: String,
      default: undefined,
    },
    sites: {
      type: Array,
      required: true,
    },
  });

  const emit = defineEmits(['select:site']);

  const modal = useModal();

  const siteId = ref(null);
  const filter = ref('');

  const title = computed(() => (props.selectSiteFor ? `Select site for ${props.selectSiteFor}` : 'Select site'));

  watch([filter], () => {
    siteId.value = null;
  });

  const selectableSites = computed(() => {
    const allSites = Object.values(props.sites);
    return allSites.filter(s => s.name.toLowerCase().includes(filter.value.toLowerCase()));
  });

  const cancel = () => {
    emit('select:site', null);
    modal.close();
  };

  const confirm = () => {
    emit('select:site', siteId.value);
    modal.close();
  };
</script>

<style scoped lang="scss">
  .site-select-sites {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }
  .site-select-sites > * {
    padding: 5px;
  }
</style>
