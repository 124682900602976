import pickBy from 'lodash/pickBy';
import { computed, MaybeRefOrGetter, reactive, toValue, unref } from 'vue';

import { JSONAPIError, ValidationError } from '@/shared/services/api-client';

import createServerFailedRule, { type ServerErrors, type ServerValidation } from './createServerFailedRule';

export type BackendValidationPointerSelector = string | RegExp;

const useBackendValidation = (
  // Common path for server validation pointers. e.g. `/data/attributes/`
  rootPointer: BackendValidationPointerSelector,
  serverValidationState: MaybeRefOrGetter<ServerValidation[]>,
) => {
  const serverErrorsFlat = computed(() =>
    toValue(serverValidationState)
      ? toValue(serverValidationState).reduce((acc, serverError) => {
          acc[serverError.source.pointer] = {
            code: serverError.code,
            errorMessage: serverError.detail || serverError.title || 'is invalid',
            pointer: serverError.source.pointer,
          };
          return acc;
        }, {} as ServerErrors)
      : null,
  );

  const locateServerError = (subPointer: BackendValidationPointerSelector) => {
    const serverErrors = unref(serverErrorsFlat);
    if (!serverErrors) return null;

    if (typeof rootPointer === 'string' && typeof subPointer === 'string') {
      const pointer = subPointer ? `${rootPointer}/${subPointer}` : rootPointer;
      return serverErrors[pointer];
    }

    const rootPointerMatches = pickBy(serverErrors, (_, pointer) => new RegExp(rootPointer).test(pointer));
    return Object.entries(rootPointerMatches)
      .filter(([pointer]) => {
        const testPointer =
          rootPointer instanceof RegExp
            ? pointer.replace(rootPointer.exec(pointer)?.[0] || '', '')
            : pointer.replace(rootPointer, '');
        return new RegExp(subPointer).test(testPointer);
      })
      .map(([, value]) => value);
  };

  // Tracks the pointers/sub-pointers in use.
  const registeredPointers = reactive(new Set());
  // Used to capture and diff the value that caused a server validation error to be thrown.
  const oldValueCache = {};

  return {
    // Veritas rule helper function to display a server validation error based on a JSON API pointer.
    serverValidationRule: (subPointer: BackendValidationPointerSelector) => {
      const serverValidationRule = createServerFailedRule(locateServerError, oldValueCache);
      const pointer = [rootPointer, subPointer]
        .filter(Boolean) // filter null, empty or undefined
        .join('/');

      if (pointer) registeredPointers.add(pointer);

      return serverValidationRule(subPointer);
    },

    // Non-displayed server validation errors.
    // Server errors with pointers not registered by `serverValidationRule`.
    unhandledValidations: computed(() =>
      serverErrorsFlat.value
        ? Object.keys(serverErrorsFlat.value)
            .filter(errorPointer => !registeredPointers.has(errorPointer))
            .map(errorPointer => serverErrorsFlat.value![errorPointer])
        : [],
    ),

    // Reset method to clear tracked JSON pointers.
    // Use it for dynamic validations trees, before using any `serverValidationRule`.
    clearRegisteredPointers: () => registeredPointers.clear(),
  };
};

const errorHasPointer = (e: JSONAPIError): e is ServerValidation => !!e.source?.pointer;

export const extractValidationErrors = (error: ValidationError) => {
  const validationErrors: ServerValidation[] = [];
  const otherErrors: JSONAPIError[] = [];

  if (!error.apiErrors) {
    throw new Error('Validation error received with no server validation errors', { cause: error });
  }

  error.apiErrors.forEach(e => {
    if (errorHasPointer(e)) {
      validationErrors.push(e);
    } else {
      // no pointer = no bueno, we'll throw a different error at the end of this
      otherErrors.push(e);
    }
  });

  return { validationErrors, otherErrors };
};

export default useBackendValidation;
