import { PaginatedItems } from '@/shared/models/helpers/PaginatedItems';
import { GeppettoApiPaginatedResponse } from '@/shared/services/api-client';

export function createPaginatedItemsMeta<T>(
  responseData: GeppettoApiPaginatedResponse<T>,
): Omit<PaginatedItems<T>, 'items'> {
  const { meta } = responseData;

  return {
    total: meta.page.totalItems,
    offset: meta.page.startIndex,
    pageSize: meta.page.itemsPerPage,
    numPages: meta.page.totalItems ? Math.ceil(meta.page.totalItems / meta.page.itemsPerPage) : 0,
  };
}

/**
 * Map from a Geppetto listing response with a meta object containing pagination values
 * to a model that includes our own internal PaginatedItems type.
 * @param itemMapper: the create* method for an individual item model
 * @param responseData: an API response object containing { data, meta: { page: { ... } } }
 */
export default function mapPaginatedResponse<T, M>(
  itemMapper: (items: T) => M,
  responseData: GeppettoApiPaginatedResponse<T>,
): PaginatedItems<M> {
  const { data } = responseData;

  return {
    items: data.map(itemMapper),
    ...createPaginatedItemsMeta(responseData),
  };
}
