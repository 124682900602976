<template>
  <AppLayout theme="dark" class="view-login" hide-header>
    <AuthWrapper>
      <div class="content">
        <form novalidate @submit.prevent="login">
          <div class="logo">
            <img id="brand-img" src="@/assets/efm-logo-login.svg?url" height="100%" alt="efm" />
          </div>

          <h1 class="title">Log in</h1>
          <ErrorBoundary v-bind="errorBoundary">
            <FormFieldWrapper
              v-slot="inputAttrs"
              label="Username or email"
              :validation="$v.credentials.username"
              :validate-on="['input']"
            >
              <ControlText
                v-model="credentials.username"
                name="username"
                autocomplete="username"
                sensitive
                start-focused
                v-bind="inputAttrs"
              />
            </FormFieldWrapper>
            <FormFieldWrapper
              v-slot="inputAttrs"
              label="Password"
              :validation="$v.credentials.password"
              :validate-on="['input']"
            >
              <ControlText
                v-model="credentials.password"
                type="password"
                name="password"
                autocomplete="current-password"
                v-bind="inputAttrs"
              />
            </FormFieldWrapper>

            <br />
            <div class="buttons">
              <BaseButton id="login-btn" variant="primary" class="is-fullwidth" :loading="isLoading" type="submit">
                Log in
              </BaseButton>
            </div>
            <div class="buttons is-right">
              <router-link :to="{ name: 'auth.forgot-password' }">Forgot Password</router-link>
            </div>
          </ErrorBoundary>
        </form>
      </div>
    </AuthWrapper>
  </AppLayout>
</template>

<script>
  import { mapGetters } from 'vuex';

  import BaseButton from '@/shared/components/BaseButton.vue';
  import ControlText from '@/shared/components/controls/ControlText.vue';
  import FormFieldWrapper from '@/shared/components/controls/wrappers/FormFieldWrapper.vue';
  import { ErrorBoundary, useErrorBoundary } from '@/shared/errorHandling';
  import { AuthFeedbackError, NoAuthenticatedUserError, TempPasswordExpiredError } from '@/shared/services/auth/errors';
  import { required } from '@/shared/validation/customValidations';
  import { createLoader } from '@/shared/vuex-async-loader';

  import criticalBoundary from '@App/errorHandling/criticalBoundary';
  import AppLayout from '@App/layout/views/AppLayout.vue';
  import Auth from '@Auth/store/types';

  import AuthWrapper from '../components/AuthWrapper.vue';

  export default {
    name: 'UserLogin',
    components: {
      AppLayout,
      AuthWrapper,
      ErrorBoundary,
      ControlText,
      FormFieldWrapper,
      BaseButton,
    },

    setup() {
      const { errorBoundary } = useErrorBoundary({
        name: 'error:login',
        onErrorCaptured: ({ error }, { markAsCritical, markAsHandled }) => {
          if (
            error instanceof NoAuthenticatedUserError ||
            error instanceof TempPasswordExpiredError ||
            error instanceof AuthFeedbackError
          ) {
            markAsHandled(error);
          } else if (criticalBoundary.shouldSetCritical(error)) {
            markAsCritical(error);
          }
        },
      });
      return {
        errorBoundary,
      };
    },

    data() {
      return {
        credentials: {
          username: '',
          password: '',
        },
        apiErrors: {},
      };
    },

    validations() {
      return {
        credentials: {
          username: { required },
          password: { required },
        },
      };
    },

    computed: {
      ...mapGetters({
        user: Auth.getters.user,
        redirectPath: Auth.getters.redirectPath,
      }),

      isLoading: createLoader(Auth.actions.login),
    },

    created() {
      this.checkSession();
    },

    methods: {
      async login() {
        // get the value of the redirectPath early as the login action causes the component to be unmounted while
        // running the action, and, as a result any computed that was no accessed before will be undefined. For
        // isUserMissingConfirmation we have to use $store to get the value after the login dispatch data is updated
        const redirectTo = this.redirectPath ? { path: this.redirectPath } : { name: 'app.home' };

        this.$v.$touch();
        if (this.$v.$anyError) return;
        if (this.isLoading) return;

        this.errorBoundary.clearErrors();

        await this.$store.dispatch(Auth.actions.login, this.credentials);

        // GeneralAuth message: invalid credentials
        if (this.$store.getters[Auth.getters.isUserMissingConfirmation]) {
          await this.$router.push({ name: 'auth.complete-new-password' });
          return;
        }

        // Admin has requested a password reset
        if (this.$store.getters[Auth.getters.getResetPasswordUsername]) {
          await this.$router.push({ name: 'auth.reset-password' });
          return;
        }

        if (this.$store.getters[Auth.getters.userRequiresMFAVerification]) {
          await this.$router.push({ name: 'auth.verify-mfa' });
          return;
        }

        // force reload on successful login
        // this is a good opportunity to force the user to reload the app so they get
        // the latest release daily when they log in.
        // @see https://flip-eng.atlassian.net/browse/GEPPES-2223
        const targetRoute = this.$router.resolve({
          ...redirectTo,
          query: { force_reload: `${Date.now()}` },
        });
        window.location = targetRoute.fullPath;

        // note: this is the previous method for directing the user to an authenticated route in the app without
        // reloading the page. leaving this intact in case we go back to this method when we have more robust
        // version detection.
        // @see https://flip-eng.atlassian.net/browse/GEPPES-2223
        // await this.$store.dispatch(Auth.actions.clearRedirect);
        // await this.$router.push(redirectTo);
      },
      checkSession() {
        // If the user was logged in, redirect to an internal page
        if (this.user.id) {
          this.$router.push({ name: 'app.home' });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
  }
  :global(.view-login .login-button) {
    display: none;
  }
</style>
