/* eslint-disable @typescript-eslint/no-explicit-any */

import uniqueId from 'lodash/uniqueId';
import {
  AppContext,
  Component,
  ComponentInternalInstance,
  ComponentPublicInstance,
  computed,
  ExtractPropTypes,
  getCurrentInstance,
  h,
  provide,
  reactive,
  UnwrapNestedRefs,
  VNode,
} from 'vue';

import { ComponentProps } from 'vue-component-type-helpers';

import ModalInstance from './ModalInstance';
import ModalView from './ModalView.vue';
import useModal from './useModal';

const getAncestors = (instance: ComponentInternalInstance): ComponentInternalInstance[] => [
  ...(instance.parent ? getAncestors(instance.parent) : []),
  instance,
];

type ComponentInternalInstanceActual = ComponentInternalInstance & {
  provides: AppContext['provides'];
};
/**
 * All the provided values from the App through to the current component are accessible in the current
 * instance via ComponentInternalInstance['provides']. However, inherited provides are accessible
 * through the instance.provide object's prototype, where provided values with Symbol keys cannot be
 * iterated on. This makes them hard to discover when we need to re-provide them in createProvidesWrapper.
 * For this reason, we crawl the instance hierarchy and construct a plain object with all the provides
 * keys and values pre-assembled, ready to iterate over.
 * @see https://github.com/vuejs/core/blob/9fa82414eba9748313d2ef28c939224b8929f046/packages/runtime-core/src/apiInject.ts#L18-L30
 */
const gatherAllProvides = (instance: ComponentInternalInstance) => {
  const ancestors = getAncestors(instance) as ComponentInternalInstanceActual[];
  return [...ancestors, instance as ComponentInternalInstanceActual].reduce(
    (acc, ancestor) => ({ ...acc, ...ancestor.provides }),
    {},
  );
};

const createProvidesWrapper = (component: Component, provides: AppContext['provides']) => ({
  setup(props: any) {
    if (provides) {
      // we can't replace the entire provides object, so we manually provide each key
      // with the provided value.
      // must use Reflect.ownKeys to iterate over Symbol keys and string keys
      Reflect.ownKeys(provides).forEach(provideKey => {
        provide(provideKey, provides[provideKey]);
      });
    }
    return () => h(component, props);
  },
});

export default function defineModalStack() {
  const modalStack = reactive<ModalInstance[]>([]);

  const getCurrent = () => modalStack[modalStack.length - 1] || null;
  const closeAll = () => {
    modalStack.splice(0);
  };

  function addModalToStack<T extends Component>(
    component: T,
    props?: ComponentProps<T>,
    provides: AppContext['provides'] = {},
  ) {
    const key = uniqueId();
    const modalInstance: ModalInstance = {
      key,
      render: () => h(createProvidesWrapper(component, provides), props),
      isActive: computed(() => (modalStack.length ? key === modalStack[modalStack.length - 1].key : false)),
      close: () => {
        modalStack.splice(modalStack.map(modal => modal.key).indexOf(key), 1);
      },
    };

    // computed values are unwrapped when being added to reactive variables
    // manually typing modal as reactive type expects an unwrapped value
    modalStack.push(modalInstance as unknown as UnwrapNestedRefs<ModalInstance>);

    return modalInstance;
  }

  const modalManager = {
    // This will open a modal instance, however, if possible, use `modalManager().open(...)
    // For injections to work the componentInstance needs to be provided
    open<T extends Component>(component: T, props?: ComponentProps<T>, componentInstance?: ComponentPublicInstance) {
      let provides: AppContext['provides'] = {};
      if (componentInstance) {
        provides = gatherAllProvides(componentInstance.$);
      }

      return addModalToStack(component, props, provides);
    },
    modalStack,
    getCurrent,
    closeAll,
  };

  // composable to use modalManager
  // has the benefit of being able to inject provides from the parent view
  // into any modals opened through modalManager.open()
  const useModalManager = () => {
    const instance = getCurrentInstance();

    if (instance === null) {
      logger.warn('useModalManager cannot access current instance to source provides');
    }

    function open<T extends Component>(component: T, props?: ComponentProps<T>) {
      // all provides are available via instance.provides, but
      const provides = instance !== null ? gatherAllProvides(instance) : {};

      // special case for modals opened by modals, this provider needs to come
      // from ModalView, not be overridden by the parent
      if ('modal-instance' in provides) delete provides['modal-instance'];

      return addModalToStack(component, props, provides);
    }

    return {
      ...modalManager,
      open,
    };
  };

  return {
    ModalView: (props: { to: string | VNode }) =>
      h<ExtractPropTypes<typeof ModalView>>(ModalView, { modalStack, to: props.to }),
    // composition helpers
    useModalManager,
    useModal,
    // global helpers
    modalManager,
  };
}
