import { Interval } from '@/shared/DateTime';
import { instantFromDateAndTime } from '@/shared/DateTime/mappers';
import { NewDeliveryTimeSlot } from '@/shared/models';

import MappingError from '@/shared/services/errors/MappingError';

import { components } from '@/shared/services/schema/geppetto-sender-app/shared.schema';

export const mapDeliveryTimeSlotFeaturesToDeliveryTimeSlotRequest = (
  deliveryTimeSlot: NewDeliveryTimeSlot,
  timeZone: string,
): components['schemas']['DeliveryTimeSlot'] => {
  if (!deliveryTimeSlot) throw new MappingError('deliveryTimeSlot is undefined');
  if (!deliveryTimeSlot.slot?.dateRange?.length) throw new MappingError('DeliveryTimeSlot DateRange is required');
  if (!deliveryTimeSlot.slot?.timeRange?.start)
    throw new MappingError('DeliveryTimeSlot.timeRange.start Date[0] is required');
  if (!deliveryTimeSlot.slot?.timeRange?.end)
    throw new MappingError('DeliveryTimeSlot.timeRange.end Date[0] is required');
  if (!timeZone) throw new MappingError('address.timezone or timeZone is required');

  return {
    references: deliveryTimeSlot.slot?.references,
    slot: {
      window: Interval.from({
        start: instantFromDateAndTime(
          deliveryTimeSlot.slot?.dateRange[0],
          deliveryTimeSlot.slot?.timeRange.start,
          timeZone,
        ),
        end: instantFromDateAndTime(
          deliveryTimeSlot.slot?.dateRange[0],
          deliveryTimeSlot.slot?.timeRange?.end,
          timeZone,
        ),
      }).toString(),
      recurrences: deliveryTimeSlot.slot?.dateRange[1]
        ? deliveryTimeSlot.slot?.dateRange[0].until(deliveryTimeSlot.slot?.dateRange[1]).days
        : 0,
    },
  };
};
