/**
 * AuthStore - getter methods
 * @see https://vuex.vuejs.org/guide/getters.html
 */
import { InternalTypes as Auth } from './types';

const AuthGetters = {
  /**
   * Proxy getters are used instead of mapState to prevent Vuex store state
   * from ever being modified directly.
   */
  [Auth.getters.user]: state => state.user,
  [Auth.getters.redirectPath]: state => state.redirectPath,
  [Auth.getters.getUnconfirmedUser]: state => state.unconfirmedUser,
  [Auth.getters.getResetPasswordUsername]: state => state.resetPasswordUsername,
  [Auth.getters.isUserMissingConfirmation]: state => state.unconfirmedUser !== null,
  [Auth.getters.getVerificationCodeDetails]: state => state.verificationCodeDetails,
  [Auth.getters.isVerificationCodeSent]: state => !!state.verificationCodeDetails,
  [Auth.getters.isPasswordResetComplete]: state => state.isPasswordResetComplete,

  [Auth.getters.userRequiresMFAVerification]: state => state.MFAVerificationRequired,
};

export default AuthGetters;
