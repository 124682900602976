<script setup>
  import { computed } from 'vue';

  import SvgIcon from '@/shared/icons/SvgIcon.vue';
  import CheckIcon from '@/shared/icons/svgs/check.svg';
  import createSensitiveDirective from '@/shared/sensitive/createSensitiveDirective';

  import LIST_BOX_ITEM_PROPS from './listBoxItemProps';

  const props = defineProps({
    ...LIST_BOX_ITEM_PROPS,
    interactive: {
      // if set to true, this can be tabbed / arrowed to and Enter will fire it's event.
      type: Boolean,
      default: false,
    },
    doNotClose: {
      // if set to true, clicking / activating this item will not close the menu
      type: Boolean,
      default: false,
    },
    noPadding: {
      // removes padding from the slot to allow the child to handle it, used for buttons, checkbox etc..
      type: Boolean,
      default: false,
    },
  });

  const vSensitive = createSensitiveDirective(() => props.sensitive);

  const outerClasses = computed(() => ({
    'is-disabled': props.disabled,
    'is-active': props.active,
    'no-padding': props.noPadding,
  }));
</script>

<template>
  <div
    class="list-box-item"
    :class="outerClasses"
    :data-do-not-close="doNotClose || undefined"
    :data-is-interactive="interactive || undefined"
  >
    <SvgIcon v-if="iconLeft" :svg="iconLeft" class="list-box-item-icon" size="small" />
    <div v-sensitive class="list-box-item-slot">
      <slot></slot>
    </div>
    <SvgIcon v-if="active" :svg="CheckIcon" class="list-box-active" size="small" />
    <SvgIcon v-else-if="iconRight" :svg="iconRight" class="list-box-item-icon" size="small" />
  </div>
</template>

<style scoped lang="scss">
  .list-box-item {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    color: var(--copy-100);
    padding: 0 16px;
    transition: background-color 150ms ease;

    &[data-is-interactive] {
      cursor: pointer;
    }

    &[data-is-interactive]:hover,
    &.focused,
    &:active,
    &:focus,
    &:focus-within {
      background-color: var(--brand-10);
      text-decoration: none;
      outline: none;
    }

    &.is-disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .list-box-active {
    width: 16px;
    height: 16px;
    margin-left: auto;
    color: var(--brand-100);
  }

  .list-box-item-icon {
    flex-grow: 0;
    flex-shrink: 0;
    color: var(--brand-100);
  }

  .list-box-item-slot {
    padding: 4px 0;
    flex-grow: 1;
    display: flex;

    :slotted(> *) {
      flex-grow: 1;
    }

    .no-padding & {
      padding: 0;
    }
  }
</style>
