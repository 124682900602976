export default ({ moduleName, store }) => {
  const start = (dispatcher, actionName) => dispatcher(`${moduleName}/load`, actionName, { root: true });
  const end = (dispatcher, actionName) => dispatcher(`${moduleName}/end`, actionName, { root: true });

  return {
    before: action => {
      if (action.type.includes(moduleName)) return;
      start(store.dispatch, action.type);
    },
    after: action => {
      if (action.type.includes(moduleName)) return;
      end(store.dispatch, action.type);
    },
    error: action => {
      if (action.type.includes(moduleName)) return;
      end(store.dispatch, action.type);
    },
  };
};
