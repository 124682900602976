/**
 * Used a base class when defining custom Errors that are true exception cases
 * and cannot be handled automatically or resolved by the user. For example, a
 * JavaScript syntax error.
 *
 * This looks similar to AppError in terms of implementation, but should **not**
 * extend AppError because then it would match `instanceof AppError` checks which
 * are sometimes used to determine how to display an error to the user.
 */
export default class UnhandleableError extends Error {
  public name = 'UnhandleableError';

  constructor(message?: string, options?: Parameters<ErrorConstructor>[1]) {
    let userMessage = message;
    if (options?.cause instanceof Error && options.cause.message) userMessage = message;

    super(userMessage || 'Something went wrong', options);
  }
}
