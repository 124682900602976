import { addListenersTo, callHandlerIfOutside, parseEventTypes, removeListenersFrom } from './utils';

const EVENTS = ['click', 'mouseup', 'focus', 'keyup'];
const DEFAULT_EVENTS = ['click'];

export default {
  beforeMount(el, { arg: eventTypes, value: config }) {
    const options = typeof config === 'function' ? { handler: config } : config;

    el.__onOutsideEventTypes__ = options.eventTypes || parseEventTypes(eventTypes, EVENTS) || DEFAULT_EVENTS;

    // keep a reference to the handler with the element so it can  be removed later
    el.__onOutsideHandler__ = callHandlerIfOutside.bind(null, { el, ...options });

    addListenersTo(document, el.__onOutsideEventTypes__, el.__onOutsideHandler__);
  },
  unmounted(el) {
    removeListenersFrom(document, el.__onOutsideEventTypes__, el.__onOutsideHandler__);
  },
};
