import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const OrgStore = {
  namespaced: true,
  state: {
    user: null,
    platformDefaults: {
      helpContact: {
        name: 'efm',
        phone: '1300 769 605',
        email: 'help@efmlogistics.com.au',
      },
    },
  },
  getters,
  mutations,
  actions,
};

export default OrgStore;
