import { createFeature, currentSiteOrAnySites } from '@App/config/features';

const appFeatures = {
  sensitive: createFeature({
    enabled: ({ flags }) => flags.sensitive !== 'hide',
    config: ({ flags }) => flags.sensitive,
  }),
  browserCheck: createFeature({
    enabled: ({ flags }) => flags.browserCheck !== 'pass',
    config: ({ flags }) => flags.browserCheck,
  }),
  dts: createFeature({
    // using feature.scopes() is recommended with this feature to ensure the right
    // context is being used for the check
    enabled: currentSiteOrAnySites(site => site.deliveryTimeSlotsEnabled),
  }),
  palletManagement: createFeature({
    enabled: ({ site }) => site?.palletManagement?.enabled || false,
    config: ({ site }) => ({
      preferredTransferType: site?.palletManagement?.preferredTransferType,
      allowedPalletTypes: site?.palletManagement?.allowedPalletTypes || [],
    }),
  }),
  resolveIssues: createFeature({
    enabled: currentSiteOrAnySites(
      site =>
        site.consignmentImportConfiguration?.enabled && site.consignmentImportConfiguration?.transferPolicy !== 'never',
    ),
  }),
  consignmentImport: createFeature({
    enabled: currentSiteOrAnySites(site => site.consignmentImportConfiguration?.enabled),
    config: ({ site }) => ({
      transferPolicy: site?.consignmentImportConfiguration?.transferPolicy,
    }),
  }),
  orderConsolidation: createFeature({
    enabled: ({ site }) => !!site?.orderConsolidationEnabled,
  }),
  dangerousGoods: createFeature({
    // TODO: turn this from || to && once site data is populated
    // https://flip-eng.atlassian.net/browse/GEPPIE-8524
    // enabled: (context) => !!context.flags.dg && currentSiteOrAnySites(site => site.dangerousGoodsEnabled)(context),
    enabled: context => {
      if (context.flags.dgSite === 'enabled') return true;
      if (context.flags.dgSite === 'disabled') return false;
      return currentSiteOrAnySites(site => !!site.dangerousGoods.isTransacting)(context);
    },
    config: ({ site, flags }) => {
      const config = {
        hasTransacted: site?.dangerousGoods.hasTransacted,
      };
      if (flags.dgSitePast === 'enabled') config.hasTransacted = true;
      else if (flags.dgSitePast === 'disabled') config.hasTransacted = false;
      return config;
    },
  }),
  // note: in the future, this check must be done using authz. however, at present
  // there isn't an ergonomic way to source facts from authz, so we will get the
  // detail from org instead.
  userCanTransactWithDangerousGoods: createFeature({
    enabled: context => {
      if (context.flags.dgUser === 'enabled') return true;
      if (context.flags.dgUser === 'disabled') return false;
      return !!context.site?.userCanTransactWithDangerousGoods;
    },
  }),
  // https://flip-eng.atlassian.net/browse/GEPPIE-8785
  dangerousGoodsNotifiable: createFeature({
    enabled: ({ flags }) => !!flags.dgNotifiable,
  }),
  orderIntegration: createFeature({
    enabled: context => {
      if (context.flags.orderIntegration) return true;
      return currentSiteOrAnySites(site => !!site?.orderConfiguration?.integrationEnabled)(context);
    },
  }),
  newConsignmentForm: createFeature({
    enabled: ({ flags }) => !!flags.newConsignmentForm,
  }),
};

export default appFeatures;
