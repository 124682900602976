import credit20210331 from './credit-reporting/2021-03-31.html?raw';
import credit20220324 from './credit-reporting/2022-03-24.html?raw';

import privacy20210331 from './privacy/2021-03-31.html?raw';
import privacy20210528 from './privacy/2021-05-28.html?raw';
import privacy20220324 from './privacy/2022-03-24.html?raw';

import terms20210331 from './terms/2021-03-31.html?raw';

export const creditReportingFiles = {
  '2021-03-31': credit20210331,
  '2021-03-24': credit20220324,
};

export const privacyFiles = {
  '2021-03-31': privacy20210331,
  '2021-05-28': privacy20210528,
  '2021-03-24': privacy20220324,
};

export const termsFiles = {
  '2021-03-31': terms20210331,
};
