import uniqueId from 'lodash/uniqueId';

import DangerousGoods from '@/shared/models/DangerousGoods/DangerousGoods';
import type { PalletCounts } from '@/shared/types/ConsignmentPallets';
import ConsignmentFormDataLineItem from '@/shared/models/Consignment/ConsignmentFormDataLineItem';

export default interface ConsignmentLineItem {
  id?: UUID;
  /** item id or autogenerated key for vue for loops keys.
   * id is uuid and uniqueId is serial, so they never overlap */
  key?: UUID;
  /** @example Carton */
  packagingType?: string;
  /** @example cfa53003-7352-441d-84c4-8c27ac049f5f */
  shippingItemPresetId?: UUID;
  /** @example Carton - A1 */
  description?: string;
  /** @example 1 */
  quantity: number;
  /** @example 1000 */
  length?: Millimeters;
  /** @example 1000 */
  width?: Millimeters;
  /** @example 1000 */
  height?: Millimeters;
  volume?: MillimetersCubed;
  /** @example 2000 */
  weight?: Grams;
  /** @example ORDER1234 */
  reference?: string;
  itemIds: UUID[];

  pallets?: PalletCounts;
  dangerousGoods?: DangerousGoods[];
}

const create = ({
  id,
  description,
  height,
  length,
  packagingType,
  quantity,
  reference,
  weight,
  width,
  volume,
  itemIds,
  shippingItemPresetId,
  pallets,
  dangerousGoods,
}: Omit<ConsignmentLineItem, 'key'>): ConsignmentLineItem => ({
  id,
  key: id || uniqueId(),
  description,
  packagingType,
  length,
  height,
  width,
  volume,
  weight,
  quantity,
  reference,
  itemIds,
  shippingItemPresetId,
  pallets,
  dangerousGoods,
});

// TODO refactor with optional instead of nulls
export interface NewConsignmentLineItem {
  id: UUID | null;
  /** item id or autogenerated key for vue for loops keys.
   * id is uuid and uniqueId is serial, so they never overlap */
  key: UUID | null;
  /** @example Carton */
  packagingType: string | null;
  /** @example cfa53003-7352-441d-84c4-8c27ac049f5f */
  shippingItemPresetId: UUID | null;
  /** @example Carton - A1 */
  description: string | null;
  /** @example 1 */
  quantity: number;
  /** @example 1000 */
  length: Millimeters | null;
  /** @example 1000 */
  width: Millimeters | null;
  /** @example 1000 */
  height: Millimeters | null;
  /** @example 2000 */
  weight: Grams | null;
  /** @example ORDER1234 */
  reference: string | null;

  volume?: MillimetersCubed;

  pallets?: PalletCounts;

  dangerousGoods: DangerousGoods[];
}

type NewConsignmentLineItemPayload = Partial<Omit<NewConsignmentLineItem, 'key'>>;

const createNew = (
  {
    id,
    description,
    packagingType,
    length,
    height,
    width,
    volume,
    weight,
    quantity,
    reference,
    shippingItemPresetId,
    pallets,
    dangerousGoods,
  }: NewConsignmentLineItemPayload = {} as NewConsignmentLineItemPayload,
): NewConsignmentLineItem => ({
  id: id || null,
  key: id || uniqueId(),
  description: description || null,
  packagingType: packagingType || null,
  length: length || null,
  height: height || null,
  width: width || null,
  volume,
  weight: weight || null,
  quantity: quantity || 1,
  reference: reference || null,
  shippingItemPresetId: shippingItemPresetId || null,
  pallets,
  dangerousGoods: dangerousGoods || [],
});

export const isEmptyItem = (item: NewConsignmentLineItem | ConsignmentFormDataLineItem) =>
  item.reference === null &&
  item.description === null &&
  item.height === null &&
  item.width === null &&
  item.length === null &&
  item.weight === null;

export const consignmentLineItemFactory = {
  create,
  createNew,

  createNewFromConsignmentLineItem: (lineItem: ConsignmentLineItem): NewConsignmentLineItem => createNew(lineItem),
};
