import { HandleableError } from '@/shared/errorHandling/errors';

export default class PollyTimeoutError<TResponse> extends HandleableError {
  public response: TResponse;

  constructor(type: string, response: TResponse) {
    super(`polly retry failed: ${type}`);

    this.response = response;
  }
}
