/**
 * ConsignmentStore - action methods
 * @see https://vuex.vuejs.org/guide/actions.html
 */

import SenderService from '@/shared/services/sender';

import MainResourceError from '@App/errors/MainResourceError';
import InvalidConsignmentSiteError from '@Consignment/errors/InvalidConsignmentSiteError';
import {
  InternalTypes as Consignment,
  ConsignmentQuotesInternalTypes as ConsignmentQuotes,
} from '@Consignment/store/types';

const ConsignmentActions = {
  [Consignment.actions.reset]: async ({ dispatch, commit }) => {
    commit(Consignment.mutations.SET_CONSIGNMENT_ENQUIRIES, []);
    commit(Consignment.mutations.SET_VALIDATION_ERRORS, null);
    commit(Consignment.mutations.SET_CONSIGNMENT, { consignment: null, version: null });

    await dispatch(ConsignmentQuotes.actions.reset);
  },

  [Consignment.actions.fetchConsignment]: async ({ dispatch, commit }, { consignmentId, currentSiteId }) => {
    await dispatch(Consignment.actions.reset);

    let consignment;
    let version;
    try {
      const response = await SenderService.fetchConsignmentById({ id: consignmentId });
      consignment = response.consignment;
      version = response.version;
    } catch (error) {
      throw new MainResourceError(error, 'Consignment');
    }

    if (currentSiteId && consignment.siteId !== currentSiteId) {
      throw new InvalidConsignmentSiteError(consignment); // should I use a generic InvalidResourceSiteError?
    }

    commit(Consignment.mutations.SET_CONSIGNMENT, { consignment, version });
  },

  [Consignment.actions.fetchConsignmentVersion]: async ({ commit }, { consignmentId, previousVersion = null }) => {
    const { consignment, version } = await SenderService.fetchConsignmentVersion({
      id: consignmentId,
      previousVersion,
    });
    commit(Consignment.mutations.SET_CONSIGNMENT, { consignment, version });
  },

  [Consignment.actions.fetchConsignmentEnquiries]: async ({ commit }, { consignmentId }) => {
    const enquiries = await SenderService.fetchConsignmentEnquiries({ consignmentId });
    commit(Consignment.mutations.SET_CONSIGNMENT_ENQUIRIES, enquiries);
  },

  [Consignment.actions.deleteConsignment]: async (_, consignmentId) =>
    SenderService.deleteConsignment({ consignmentId }),

  [Consignment.actions.lodgeEnquiry]: async (_, enquiry) =>
    SenderService.postEnquiry({
      type: 'consignment',
      ...enquiry,
    }),
};

export default ConsignmentActions;
