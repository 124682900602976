<template>
  <ModalCard title="User preferences">
    <div style="margin-bottom: 16px">Set your preferences for this browser on this computer.</div>

    <FormFieldWrapper label="Default site">
      <ControlSelect
        v-model="defaultSite"
        :options="siteOptions"
        :control-wrapper-classes="{ 'is-fullwidth': true }"
        sensitive
      />
    </FormFieldWrapper>
    <FormFieldWrapper label="Home page">
      <ControlSelect
        v-model="defaultPage"
        :options="filteredPageOptions"
        :control-wrapper-classes="{ 'is-fullwidth': true }"
      />
    </FormFieldWrapper>

    <template #footer>
      <ActionsBar>
        <button type="button" class="button is-secondary" @click="modal.close">Cancel</button>
        <button type="button" class="button is-primary" @click="save">Save preferences</button>
      </ActionsBar>
    </template>
  </ModalCard>
</template>

<script setup>
  import mapValues from 'lodash/mapValues';
  import omitBy from 'lodash/omitBy';
  import { computed, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';

  import ActionsBar from '@/shared/components/ActionsBar.vue';
  import ControlSelect from '@/shared/components/controls/ControlSelect.vue';
  import FormFieldWrapper from '@/shared/components/controls/wrappers/FormFieldWrapper.vue';

  import instrumentation from '@/shared/instrumentation';

  import ModalCard from '@/shared/modals/ModalCard.vue';
  import { useModal } from '@/shared/modals/modalManager';
  import { useNotify } from '@/shared/notify';

  import appInstrumentationTypes from '@App/instrumentation/types';

  import Org from '@Org/store/types';

  import { useDefaultContext, useDefaultLandingPage } from '@Site/behaviours/defaultPage';

  const store = useStore();
  const router = useRouter();
  const modal = useModal();
  const { toast } = useNotify();

  const { getDefaultContext, setDefaultContext } = useDefaultContext();
  const { getDefaultLandingPage, setDefaultLandingPage } = useDefaultLandingPage();

  const defaultSite = ref();

  // TODO: org - all sites
  const savedDefaultContext = getDefaultContext();
  if (savedDefaultContext.type !== 'org') {
    defaultSite.value = savedDefaultContext.id;
  }

  const siteOptions = mapValues(store.getters[Org.getters.sites], site => site.name);

  const savedDefaultPage = getDefaultLandingPage();

  const defaultPage = ref(savedDefaultPage === 'site' ? 'consignment.readyToManifest' : savedDefaultPage);

  const pageOptions = {
    'estimate.create': 'Create estimate',
    'estimate.manage': 'Manage estimates',
    'consignment.create': 'Create consignment',
    'consignment.readyToManifest': 'Ready-to-manifest consignments',
    'track.consignments': 'Track consignments',
    'pickup.create': 'Create pickup',
    'pickup.manage': 'Manage pickups',
    'manifest.create': 'Create manifests',
    'manifest.manage': 'Manage manifests',
  };

  const filteredPageOptions = computed(() => {
    if (!defaultSite.value) {
      return omitBy(pageOptions, (option, key) => !router.resolve({ name: key }).meta.context?.allowed.includes('org'));
    }

    return pageOptions;
  });

  const save = () => {
    // TODO - currently undefined if a user has starred all sites in sites menu
    if (defaultSite.value) {
      setDefaultContext({ type: 'site', id: defaultSite.value });
    }

    setDefaultLandingPage(defaultPage.value);
    modal.close();
    toast({ message: 'User preferences saved', type: 'success' });
  };

  onMounted(() => {
    instrumentation.event(appInstrumentationTypes.USER_PREFERENCES_OPENED);
  });
</script>
