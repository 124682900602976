import { Address, createAddress } from '@/shared/models';
import { ADDRESS_FROM } from '@/shared/models/Address';

export interface AddressApiData {
  attributes: {
    countryId: string;
    from?: ADDRESS_FROM;
    gnafId?: string;
    id: string;
    line1: string;
    line2?: string;
    locality: string;
    postcode: string;
    subdivision: string;
    timeZone: string;
  };
}

const AddressMapper = {
  fromAPI(data: Partial<AddressApiData>): Address {
    return createAddress(data.attributes);
  },
};

export default {
  Address: AddressMapper,
};
