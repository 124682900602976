import { Temporal } from '@js-temporal/polyfill';

import { LineItem, NewLineItem } from '../LineItem';
import { MovementFlowType } from '../MovementFlow';
import Quote from '../Quote/Quote';

import {
  estimateAddresseeFactory,
  EstimateReceiver,
  EstimateSender,
  NewEstimateReceiver,
  NewEstimateSender,
} from './EstimateAddressee';

export type EstimateLineItem = LineItem;

export type EstimateConvertedConsignment = {
  id: UUID;
  connoteNumber: string;
  convertedAt: Temporal.Instant;
};

interface Estimate {
  id: UUID;
  siteId: UUID;
  type: MovementFlowType;
  createdAt: Temporal.Instant;
  deletedAt?: Temporal.Instant;
  updatedAt: Temporal.Instant;
  dispatchDate: Temporal.PlainDate;
  estimateNumber: string;
  references?: string[];
  sender: EstimateSender;
  receiver: EstimateReceiver;
  lineItems: EstimateLineItem[];
  quotes: Quote[];
  selectedQuoteIds: UUID[];
  convertedConsignment?: EstimateConvertedConsignment[];
  hasDangerousGoods: boolean;
}

function create({
  id,
  siteId,
  type,
  createdAt,
  deletedAt,
  updatedAt,
  dispatchDate,
  estimateNumber,
  sender,
  receiver,
  references,
  lineItems,
  quotes,
  selectedQuoteIds,
  convertedConsignment,
  hasDangerousGoods,
}: Estimate): Estimate {
  return {
    id,
    siteId,
    type,
    createdAt,
    deletedAt,
    updatedAt,
    dispatchDate,
    estimateNumber,
    sender,
    receiver,
    references,
    lineItems,
    quotes,
    selectedQuoteIds,
    convertedConsignment,
    hasDangerousGoods,
  };
}

export type NewEstimateLineItem = NewLineItem;

export interface NewEstimate {
  siteId: UUID;
  type: MovementFlowType;
  dispatchDate?: Temporal.PlainDate;
  sender: NewEstimateSender;
  receiver: NewEstimateReceiver;
  references: string[];
  lineItems: NewEstimateLineItem[];
  selectedQuoteIds: UUID[];
  hasDangerousGoods: boolean;
}

function createNew({
  siteId = '',
  type = 'outbound',
  dispatchDate,
  sender = estimateAddresseeFactory.createNewEstimateSender(),
  receiver = estimateAddresseeFactory.createNewEstimateReceiver(),
  references = [],
  lineItems = [],
  selectedQuoteIds = [],
  hasDangerousGoods = false,
}: Partial<NewEstimate> = {}): NewEstimate {
  return {
    siteId,
    type,
    dispatchDate,
    sender,
    receiver,
    references,
    lineItems,
    selectedQuoteIds,
    hasDangerousGoods,
  };
}

export interface EstimateUpdated {
  id: UUID;
  quotes: Quote[];
}

export const estimateFactory = {
  create,
  createNew,
};

export default Estimate;
