import { Temporal } from '@js-temporal/polyfill';

import { Interval } from '@/shared/DateTime';

import { CarrierPickup } from '../models';

import { PickupClientResponse } from './types';

export function carrierPickupClientToCarrierPickup(pickup: PickupClientResponse): CarrierPickup {
  return {
    carrierName: pickup.carrierName,
    carrierChargeAccount: pickup.carrierChargeAccount,
    pickupCloseTime: pickup.pickupCloseTime ? Temporal.Instant.from(pickup.pickupCloseTime) : undefined,
    pickupDate: Temporal.PlainDate.from(pickup.pickupDate),
    pickupWindow: Interval.from(pickup.pickupWindow),
    reference: pickup.reference,
    sender: pickup.sender,
    serviceName: pickup.serviceName,
    totalItems: pickup.totalItems,
    totalVolume: pickup.totalVolume,
    totalWeight: pickup.totalWeight,
    confirmation: pickup.confirmation
      ? {
          confirmedAt: Temporal.Instant.from(pickup.confirmation.confirmedAt),
          carrierReference: pickup.confirmation.carrierReference,
        }
      : undefined,
    rejection: pickup.rejection
      ? {
          rejectedAt: Temporal.Instant.from(pickup.rejection.rejectedAt),
          reason: pickup.rejection.reason,
        }
      : undefined,
  };
}
