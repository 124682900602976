import uniqueId from 'lodash/uniqueId';

import { Destination, destinationFactory, NewDestination } from '@/shared/models';
import DangerousGoods from '@/shared/models/DangerousGoods/DangerousGoods';
import { ModelWithKey } from '@/shared/models/helpers/ModelWithKey';

export interface FreightItemInput {
  _key: string;
  description: string;
  packagingType: string;
  width: Millimeters | null;
  height: Millimeters | null;
  length: Millimeters | null;
  weight: Grams | null;
  quantity: number;
  dangerousGoods: ModelWithKey<DangerousGoods>[];
}

const newFreightItem = (): FreightItemInput => ({
  _key: uniqueId(),
  packagingType: '',
  description: '',
  width: null,
  height: null,
  length: null,
  weight: null,
  quantity: 1,
  dangerousGoods: [],
});

export const createFreightItemInput = (
  {
    _key,
    packagingType = '',
    description = '',
    width = null,
    height = null,
    length = null,
    weight = null,
    quantity = 1,
    dangerousGoods,
  }: Partial<FreightItemInput> | undefined = newFreightItem(),
): FreightItemInput => ({
  _key: _key || uniqueId(),
  packagingType,
  description,
  width,
  height,
  length,
  weight,
  quantity,
  dangerousGoods: dangerousGoods || [],
});

interface FreightInput {
  _key: string;
  _isPreFill: boolean;
  destination: Destination | NewDestination;
  items: FreightItemInput[];
}

const newFreight = (): FreightInput => ({
  _key: uniqueId(),
  _isPreFill: false,
  destination: destinationFactory.createNew(),
  items: [createFreightItemInput()],
});

export const createFreightInput = (
  { _key, _isPreFill, destination, items }: Partial<FreightInput> | undefined = newFreight(),
): FreightInput => ({
  _key: _key || uniqueId(),
  _isPreFill: _isPreFill || false,
  destination: destination || destinationFactory.createNew(),
  items: items || [createFreightItemInput()],
});

export const isEmptyFreightItemInput = (item: FreightItemInput) =>
  !item.description &&
  !item.packagingType &&
  item.height === null &&
  item.width === null &&
  item.length === null &&
  item.weight === null;

export default FreightInput;
