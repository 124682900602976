import { createSharedComposable } from '@vueuse/core';
import { computed } from 'vue';

import { useMaybeOrg, useSiteId } from '@/app/context';

import { useConsignmentSearch } from '@/shared/behaviours/useConsignmentSearch';
import { plainDateNow } from '@/shared/DateTime/helpers';

function useConsignmentsWithIssuesFetch() {
  const { fetch, total, isLoading } = useConsignmentSearch();

  const siteId = useSiteId();
  const org = useMaybeOrg();

  const siteFilter = computed(() => (siteId.value ? [siteId.value] : Object.keys(org.value?.sites ?? {})));

  const fetchIssuesCount = async () => {
    if (!isLoading.value || !siteFilter.value) {
      const today = plainDateNow();
      await fetch({
        pageSize: 0,
        sites: siteFilter.value,
        serviceId: 'all',
        dispatchDate: today,
        issues: true,
        transfer: false,
      });
    }
  };

  return {
    count: total,
    fetchIssuesCount,
  };
}

export const useConsignmentsWithIssuesCount = createSharedComposable(useConsignmentsWithIssuesFetch);
