import { components, operations } from '@/shared/services/schema/geppetto-org/shippingitempresets.schema';

export type PackagingType = components['schemas']['PackagingType'];
export type ShippingItemPresetResponse = operations['getPreset']['responses']['200']['content']['application/json'];
export interface ShippingItemPreset extends Partial<Dimensions> {
  id: UUID;
  siteId: UUID;
  quickAccessCode: string;
  description: string;
  packagingType: PackagingType;
  weight?: Grams;
}

export type NewShippingItemPreset = Partial<Omit<ShippingItemPreset, 'id'>>;

export function integerIfDefined(value: string | number | undefined | null) {
  const number = parseInt(value as string, 10);
  return !Number.isNaN(number) ? number : undefined;
}

function create({
  id,
  siteId,
  quickAccessCode,
  description,
  packagingType,
  length,
  width,
  height,
  weight,
}: components['schemas']['Preset']): ShippingItemPreset {
  return {
    id,
    siteId,
    quickAccessCode,
    description,
    packagingType,
    length: integerIfDefined(length),
    width: integerIfDefined(width),
    height: integerIfDefined(height),
    weight: integerIfDefined(weight),
  };
}

function createNew({
  siteId,
  quickAccessCode,
  description,
  packagingType,
  length,
  width,
  height,
  weight,
}: NewShippingItemPreset = {}): NewShippingItemPreset {
  return {
    siteId,
    quickAccessCode,
    description,
    packagingType,
    length: integerIfDefined(length),
    width: integerIfDefined(width),
    height: integerIfDefined(height),
    weight: integerIfDefined(weight),
  };
}

export const shippingItemPresetFactory = {
  create,
  createNew,
};

export default ShippingItemPreset;
