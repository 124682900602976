import { Temporal } from '@js-temporal/polyfill';

import { Interval } from '@/shared/DateTime';

export const PICKUP_ACTION_FAIL_REASONS = {
  conflict: 'conflict',
  actioned: 'actioned',
} as const;

export interface CarrierPickup {
  carrierName: string;
  carrierChargeAccount: string;
  pickupCloseTime?: Temporal.Instant;
  pickupDate: Temporal.PlainDate;
  pickupWindow: Interval;
  reference: string;
  sender: { name: string };
  serviceName: string;
  totalItems: number;
  totalVolume: MillimetersCubed;
  totalWeight: Grams;
  confirmation?: {
    confirmedAt: Temporal.Instant;
    carrierReference: string;
  };
  rejection?: {
    rejectedAt: Temporal.Instant;
    reason: string;
  };
}
