<template>
  <AppProgress v-if="routeChangeInProgress" />
  <ErrorBoundary v-if="!error" v-bind="errorBoundary" id="app-main">
    <template #fallback="{ criticalError }">
      <ErrorFallback class="is-flex-grow-1 is-align-self-center" :error="criticalError">
        <li>
          <SvgIcon :svg="ArrowRightIcon" class="mr-1 has-text-primary" /> <a @click="logout()">Log out</a>, then log
          back in again
        </li>
      </ErrorFallback>
    </template>

    <BrowserCheck id="app-content">
      <ProgressIndicator v-if="loading" class="app-content-progress" message="Loading your user details" />
      <RouterView v-else ref="routerViewRef" />
    </BrowserCheck>
  </ErrorBoundary>

  <ModalView to="#modal-container" />

  <AppFooter id="footer" />
</template>

<script setup lang="ts">
  import { onBeforeMount, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';

  import ProgressIndicator from '@/shared/components/ProgressIndicator.vue';
  import { ErrorBoundary, useErrorBoundary } from '@/shared/errorHandling';
  import { PermissionDeniedError as PermissionDeniedAppError } from '@/shared/errorHandling/errors';
  import handleErrorCaptured from '@/shared/errorHandling/handleErrorCaptured';
  import { errorIsCausedBy, errorIsCausedByAnyOf, getErrorCauseBy } from '@/shared/errorHandling/helpers';
  import SvgIcon from '@/shared/icons/SvgIcon.vue';
  import ArrowRightIcon from '@/shared/icons/svgs/arrow-right.svg';
  import { ModalView } from '@/shared/modals/modalManager';
  import {
    CancelledError,
    NetworkError,
    NotAuthenticatedError,
    PermissionDeniedError,
  } from '@/shared/services/api-client';
  import InvalidOrganisationError from '@/shared/services/org/errors/InvalidOrganisationError';

  import BrowserCheck from '@App/BrowserCheck.vue';
  import appFeatures from '@App/config/appFeatures';
  import { useFeature } from '@App/config/features';
  import confirm from '@App/confirm';
  import ErrorFallback from '@App/ErrorFallback.vue';
  import AppFooter from '@App/layout/components/AppFooter.vue';
  import AppProgress from '@App/layout/components/AppProgress.vue';
  import useDebug from '@App/modules/Debug/behaviours/useDebug';
  import App from '@App/store/types';
  import useManageUserSession from '@App/store/useManageUserSession';
  import Auth from '@Auth/store/types';

  const router = useRouter();
  const { inProgress: routeChangeInProgress } = router;
  const store = useStore();

  const { checkExistingSession, clearSession, logout, userIsAuthenticated } = useManageUserSession();

  const { errorBoundary } = useErrorBoundary({
    name: 'error:app',
    onErrorCaptured: (
      { error },
      { errorIsDisplayed, errorIsHandled, markAsCritical, markAsDisplayed, markAsHandled },
    ) => {
      if (errorIsCausedBy(error, CancelledError)) {
        markAsDisplayed(error); // do not display for any reason
        return;
      }

      if (
        errorIsCausedByAnyOf<Error>(error, [NetworkError, PermissionDeniedError, PermissionDeniedAppError]) &&
        errorIsDisplayed(error)
      ) {
        markAsHandled(error);
        return;
      }

      const notAuthenticatedError = getErrorCauseBy(error, NotAuthenticatedError);
      if (!!notAuthenticatedError && !errorIsHandled(error) && notAuthenticatedError.isGeppettoAuth) {
        markAsHandled(error);
        logout({ force: true });
        return;
      }

      if (!errorIsDisplayed(error)) {
        markAsCritical(error);
      }
    },
  });

  useDebug();

  const feature = useFeature();
  if (feature.enabled(appFeatures.sensitive).value) {
    const sensitivePreviewType = feature.config(appFeatures.sensitive).value;
    document.body.classList.add(`sensitive-preview-${sensitivePreviewType}`);
  }

  const routerViewRef = ref();
  // it's generally not a good idea to add a global router guard in a component lifecycle hook, as the router guard
  // can't be removed when the component is unmounted. however in this case the risk is acceptable since the App
  // component should never be unmounted.
  router.onError(error => {
    // this is dangerous. handleErrorCaptured should generally not be called manually and fed an error, because
    // this does **not** result in the error being thrown to the root context where it can be reported.
    // in this case it's an acceptable risk, because we don't want module loading failures to be reported to
    // error monitoring when there's nothing we can do to resolve it.
    // this attempts to use the RouterView as the triggering element to simulate the error being thrown from
    // the router view component.
    handleErrorCaptured(error, routerViewRef.value, 'navigation');
  });
  watch(router.currentRoute, () => {
    errorBoundary.clearErrors();
  });

  const error = ref(false);
  const loading = ref(true); // Prevent router-view from rendering until we know if any data should be loaded

  // #modal-container will exist in the app, but may not in tests, storybook, etc
  if (!document.querySelector('#modal-container')) {
    const modalContainerEl = document.createElement('div');
    modalContainerEl.id = 'modal-container';
    document.body.appendChild(modalContainerEl);
  }

  const handleInvalidOrganisationError = async () => {
    error.value = true;
    await confirm({
      title: 'Access denied',
      message: 'You do not have access. Contact the site administrator.',
      confirmLabel: 'OK',
      cancelLabel: false,
    });

    // usually we check the action response to take action but this time
    // there is no other option than returning to login page
    await logout({ force: true });
    error.value = false;
  };

  const loadCurrentUserOrgData = async () => {
    try {
      // Go to org for list of sites and orgs the user has access to
      loading.value = true;
      await store.dispatch(App.actions.fetchUserAccess);

      // If the user hasn't accepted current legal terms, they must do so now
      if (!store.getters[App.getters.userHasAcceptedTerms]) {
        logger.info('[App] user has not accepted terms, redirecting to terms accept workflow');
        await store.dispatch(Auth.actions.saveRedirect, router.currentRoute.value.fullPath);
        await router.push({ name: 'auth.accept-terms' });
      }
    } catch (err) {
      // if anything fails in fetching user access, logout to prevent inconsistent state
      await clearSession();

      if (err instanceof InvalidOrganisationError) {
        await handleInvalidOrganisationError();
        return;
      }

      throw err;
    } finally {
      // let the error handling system register the error before removing loading state
      // wait until the next macrotick, as error handling seems to register at the end of the next microtick
      setTimeout(() => {
        loading.value = false;
      }, 0);
    }
  };

  watch(userIsAuthenticated, async isAuthenticated => {
    if (isAuthenticated) {
      await loadCurrentUserOrgData();
    }
  });

  onBeforeMount(async () => {
    // check for an existing session once, when the app loads
    await checkExistingSession();

    // If the user is not logged in, we have nothing left to load at this point
    if (!userIsAuthenticated.value) {
      loading.value = false;
    }
    // If session exists, it will trigger userIsAuthenticated watcher to hydrate org data
  });
</script>

<style lang="scss">
  #app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  #app-main,
  #app-content {
    display: flex;
    flex-direction: column;
  }

  // these all need flex: 1 for progress to go full-height
  #app-main,
  #app-content,
  #app-content > .app-content-progress {
    flex: 1;
  }

  #footer {
    justify-self: flex-end;
  }
</style>
