<template>
  <ErrorLayout :title="title">
    <template #figure>
      <img src="@/assets/404.svg?url" alt="404" />
    </template>
    <template #default>
      <p>Here are some helpful links to get you back on track:</p>
      <ul>
        <li>
          <SvgIcon :svg="ArrowRightIcon" class="mr-1 has-text-primary" />
          <a @click="$router.go(-1)">Go back</a>
        </li>
        <li v-if="userIsAuthenticated && defaultSite">
          <SvgIcon :svg="ArrowRightIcon" class="mr-1 has-text-primary" />
          <a @click="$router.push(createConLocation)">Create consignment</a>
        </li>
        <li v-if="userIsAuthenticated && defaultSite">
          <SvgIcon :svg="ArrowRightIcon" class="mr-1 has-text-primary" />
          <a @click="$router.push(manageConLocation)">Ready-to-manifest consignments</a>
        </li>
        <li v-if="userIsAuthenticated && defaultSite">
          <SvgIcon :svg="ArrowRightIcon" class="mr-1 has-text-primary" />
          <a @click="$router.push(trackConLocation)">Track consignments</a>
        </li>
      </ul>
    </template>
  </ErrorLayout>
</template>

<script>
  import { mapGetters } from 'vuex';

  import SvgIcon from '@/shared/icons/SvgIcon.vue';

  import ArrowRightIcon from '@/shared/icons/svgs/arrow-right.svg';

  import ErrorLayout from '@App/layout/views/ErrorLayout.vue';

  import App from '@App/store/types';
  import Org from '@Org/store/types';

  export default {
    name: 'PermissionDenied',
    components: {
      SvgIcon,
      ErrorLayout,
    },
    props: {
      title: {
        type: String,
        default: () => 'Access denied.',
      },
      error: {
        type: Object,
        default: null,
      },
    },
    computed: {
      ArrowRightIcon() {
        return ArrowRightIcon;
      },
      ...mapGetters({
        defaultSite: Org.getters.defaultSite,
        userIsAuthenticated: App.getters.userIsAuthenticated,
      }),
      createConLocation() {
        return {
          name: 'consignment.create',
          params: {
            contextType: 'site',
            contextId: this.defaultSite.id,
          },
        };
      },
      manageConLocation() {
        return {
          name: 'consignment.readyToManifest',
          params: {
            contextType: 'site',
            contextId: this.defaultSite.id,
          },
        };
      },
      trackConLocation() {
        return {
          name: 'track.consignments',
          params: {
            contextType: 'site',
            contextId: this.defaultSite.id,
          },
        };
      },
    },
  };
</script>

<style lang="scss" scoped></style>
