import type { Site } from '@/shared/models';

interface Organisation {
  id: UUID;
  name: string;
}

export interface OrganisationWithSites extends Organisation {
  sites: Record<UUID, Site>;
}

const newOrganisation = (): Organisation => ({
  id: '',
  name: '',
});

export const createOrganisation = ({ id, name }: Organisation | undefined = newOrganisation()) => ({
  id,
  name,
});

export const organisationGqlFields = ['uuid', 'name'];

export default Organisation;
