import localCache, { LocalCache } from '@/shared/local-cache/localCache';
import { Address } from '@/shared/models';
import APIClient, {
  ApiClientRequestConfig,
  ApiClientResponse,
  GeppettoApiResponse,
} from '@/shared/services/api-client';

import mappers, { AddressApiData } from './mappers';

interface AddressEstimate {
  line1: string;
  line2?: string;
  addressFinderLocationID: string;
}

type APIResponse = ApiClientResponse<GeppettoApiResponse<AddressApiData>>;

if (!import.meta.env.GEPPETTO_SERVICE_ADDRESS_URL) {
  throw new Error('GEPPETTO .env not correctly configured');
}

const baseUrl = import.meta.env.GEPPETTO_SERVICE_ADDRESS_URL;

class AddressService {
  private _api: APIClient;

  private addressesCache: LocalCache<APIResponse>;

  constructor() {
    this._api = new APIClient(baseUrl);
    this.addressesCache = localCache.create();
  }

  // /geppetto-address-api/spec/examples/estimate.jsonapi.json
  // POST /addresses
  // Body {
  //        "data": {
  //          "type": "addressEstimate",
  //          "attributes": {
  //            "line1": "Unit 6, 88 Burfitt Street",
  //            "line2":"",
  //            "localitySuggestionId":"393d1b90-ffc5-11e9-bef1-1efda64f35a6"
  //          }
  //        }
  //      }
  async getEstimateDetails(addressEstimate: AddressEstimate): Promise<Address> {
    const data = {
      data: {
        type: 'addressEstimate',
        attributes: {
          line1: addressEstimate.line1,
          line2: addressEstimate.line2,
          localitySuggestionId: addressEstimate.addressFinderLocationID,
        },
      },
    };

    const response = await this._api.post<GeppettoApiResponse<AddressApiData>>('addresses', data);
    return mappers.Address.fromAPI(response?.data?.data);
  }

  // /geppetto-address-api/spec/examples/suggestion.jsonapi.json
  // POST /addresses
  // Body {
  //        "data": {
  //          "type": "addressSuggestion",
  //          "attributes": {
  //            "addressSuggestionId": "393d1b90-ffc5-11e9-bef1-1efda64f35a6"
  //          }
  //        }
  //      }
  async getSuggestionDetails(addressFinderAddressSuggestionID: string): Promise<Address> {
    const data = {
      data: {
        type: 'addressSuggestion',
        attributes: {
          addressSuggestionId: addressFinderAddressSuggestionID,
        },
      },
    };

    const response = await this._api.post<GeppettoApiResponse<AddressApiData>>('addresses', data);

    return mappers.Address.fromAPI(response?.data?.data);
  }

  // GET /addresses/{id}
  async getAddress(addressServiceID: string, config?: ApiClientRequestConfig): Promise<Address | undefined> {
    if (!addressServiceID || typeof addressServiceID !== 'string') {
      return undefined;
    }

    const response = await this.addressesCache.getAsync(addressServiceID, async () =>
      this._api.get<GeppettoApiResponse<AddressApiData>>(`addresses/${addressServiceID}`, config),
    );

    return mappers.Address.fromAPI(response.data.data);
  }
}

export default new AddressService();
