import invert from 'lodash/invert';
import mapValues from 'lodash/mapValues';

import sumBy from 'lodash/sumBy';

import type { Consignment, ConsignmentLineItem, ConsignmentSummary, MovementFlowType } from '@/shared/models';
import { ConsignmentFilterTypes } from '@/shared/models/Consignment/ConsignmentResultSet';
import { CostCentre } from '@/shared/models/Site';
import { PalletCounts, PalletLender } from '@/shared/types/ConsignmentPallets';

import { Entries } from '@/shared/types/utility';

import formatMovementFlow from '@App/common/formatters/formatMovementFlow';

import { documentUnavailableDialog, pickupUnavailableDialog } from './dialogs';
import ConsignmentFormDataLineItem from '@/shared/models/Consignment/ConsignmentFormDataLineItem';

export * from './getIssueDisplayName';

export { pickupUnavailableDialog, documentUnavailableDialog };

// if transferPolicy is 'never' or 'automatic' that means the consignment was manifested off platform
export const isManifested = (consignment: Partial<Pick<Consignment, 'manifest' | 'transferPolicy'>>) =>
  !!consignment?.manifest || consignment?.transferPolicy !== 'manual';

export const hasManifestDocument = (consignment: Pick<Consignment, 'manifest'>) => !!consignment?.manifest;

// if transferPolicy is 'automatic' consignment must be edited (fixed) before the data can be sent to the carrier
// TODO replace for policy
export const isEditable = (consignment: Pick<Consignment, 'manifest' | 'transferPolicy' | 'issues'>) =>
  !isManifested(consignment) || !!(consignment.issues && consignment.transferPolicy === 'automatic');
// TODO replace for policy
export const isDeletable = (consignment: Pick<Consignment, 'manifest' | 'transferPolicy'>) =>
  !isManifested(consignment);

export const isImported = (consignment: Pick<Consignment, 'source'>) => !!consignment.source;

export const getValidConsignments = (consignments: ConsignmentSummary[]) =>
  consignments.filter(consignment => !consignment.issues);

export const formatCostCentre = (code: string, costCentres: CostCentre[] = []) => {
  const costCentre = costCentres.find(c => c.code === code);
  return `${code}${costCentre?.description ? ` | ${costCentre?.description}` : ''}`;
};

/**
 * cm to mm conversion with null return
 * @param val
 * @returns {number|null}
 */
export const cm2mm = (val?: number | string | null) =>
  val || val === 0 ? Number.parseFloat(val.toString()) * 10 : null;

/**
 * kg to gr conversion with null return
 * @param val
 * @returns {number|null}
 */
export const kg2gr = (val?: number | string | null) =>
  val || val === 0 ? Number.parseFloat(val.toString()) * 1000 : null;

/**
 * mm to cm conversion with null return
 * @param val
 * @returns {number|null}
 */
export const mm2cm = (val?: number | string | null) =>
  val || val === 0 ? Number.parseFloat(val.toString()) / 10 : null;

/**
 * gr to kg conversion with null return
 * @param val
 * @returns {number|null}
 */
export const gr2kg = (val?: number | string | null) =>
  val || val === 0 ? Number.parseFloat(val.toString()) / 1000 : null;
/**
 * mm to m conversion with null return
 * @param val
 * @returns {number|null}
 */
export const mm2m = (val?: number | string | null) =>
  val || val === 0 ? Number.parseFloat(val.toString()) / 1000 : null;
/**
 * mm^3 to m^3 conversion with null return
 * @param val
 * @returns {number|null}
 */
export const mm2m3 = (val?: number | string | null) =>
  val || val === 0 ? Number.parseFloat(val.toString()) / 1e9 : null;

export const consignmentTypeList = (allowAll = true, allLabel = 'All consignment types') => {
  const options = invert(ConsignmentFilterTypes);
  const labelled = mapValues(options, (value, key) => formatMovementFlow(key as MovementFlowType));
  delete labelled.all; // no label for 'all' and we might not want it!
  if (allowAll) {
    return { all: allLabel, ...labelled };
  }
  return labelled;
};

export const getConsignmentStatus = ({
  consignment,
}: {
  consignment: Pick<Consignment, 'issues' | 'manifest' | 'transferPolicy'>;
}) => {
  let label = 'Unknown';
  let color = 'chrome';

  if (consignment.issues) {
    label = 'Consignment with issues';
    color = 'warning';
  } else if (hasManifestDocument(consignment)) {
    // ToDOo: review this for manifested cons with issues
    label = 'Manifest created';
    color = 'info';
  } else {
    label = 'Consignment created';
    color = 'info';
  }

  return { label, color };
};

export const palletLenders: PalletLender[] = ['chep', 'loscam', 'other'];

export const getPalletLenderName = (lender: string) => {
  if (palletLenders.includes(lender as PalletLender)) {
    // todo: possibly source this from org?
    return lender[0].toUpperCase() + lender.slice(1);
  }
  throw new Error(`Unknown pallet lender '${lender}'`);
};

export const getPalletQuantities = (lineItems: ConsignmentLineItem[] | ConsignmentFormDataLineItem[]): PalletCounts => {
  const unsorted =
    lineItems?.reduce<PalletCounts>((acc, item) => {
      if (item.pallets) {
        (Object.entries(item.pallets) as Entries<typeof item.pallets>).forEach(([lender, count]) => {
          if (!count) return;
          acc[lender] = (acc[lender] ?? 0) + count;
        });
      }
      return acc;
    }, {}) || {};

  return Object.fromEntries(
    Object.entries(unsorted).sort(([lenderA], [lenderB]) => {
      if (lenderA === 'other') return 1;
      return lenderA.localeCompare(lenderB);
    }),
  );
};

/**
 * Total number of items for these consignments
 * @param {ConsignmentSummary[]} consignments
 * @return {number} Item count
 */
export const itemTotal = (consignments: ConsignmentSummary[]) => sumBy(consignments, c => c.itemQuantity);

/**
 * Total volume of all consignments
 * @param {ConsignmentSummary[]} consignments
 * @return {number} Volume in mm2
 */
export const volumeTotal = (consignments: ConsignmentSummary[]) => sumBy(consignments, c => c.volume);

/**
 * Total weight of all consignments
 * @param {ConsignmentSummary[]} consignments
 * @return {number} Total Weight in gr
 */
export const weightTotal = (consignments: ConsignmentSummary[]) => sumBy(consignments, c => c.weight);
