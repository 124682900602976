import instrumentation from '@/shared/instrumentation';

import userMonitor from '@App/instrumentation/userMonitor';

import { ConsignmentInstrumentation } from '@Consignment/instrumentation/types';

instrumentation.before(ConsignmentInstrumentation.ENQUIRY_CREATED, () => {
  userMonitor.event('enquiry started', {});
});

// TODO this should be store action name here
instrumentation.on(ConsignmentInstrumentation.ENQUIRY_CREATED, ({ duration, enquiry }) => {
  userMonitor.event('enquiry submitted', {
    duration,
    enquiry: {
      consignmentId: enquiry.consignmentId,
      subject: enquiry.subject,
    },
  });
});
