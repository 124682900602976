export default function flattenObject(source: object): object {
  return Object.assign(
    {},
    ...(function _flat(obj: object, prefix?: string): object[] {
      return Object.entries(obj).flatMap(([key, value]) => {
        const nestedKey: string = prefix ? `${prefix}.${key}` : key;
        return typeof value === 'object' ? _flat(value, nestedKey) : { [nestedKey]: value };
      });
    })(source),
  );
}
