import { MovementFlowType } from '@/shared/models';

export const flowTypeLabels: Record<MovementFlowType, string> = {
  outbound: 'Outbound',
  inbound: 'Inbound',
  transfer: 'Anywhere to anywhere',
} as const;

export default function formatMovementFlow(flowType: MovementFlowType): string {
  return flowTypeLabels[flowType];
}
