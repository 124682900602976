<template>
  <div :data-testref="dataTestref">
    <input
      ref="inputEl"
      v-sensitive
      autocomplete="off"
      class="input"
      :class="{
        'is-danger': hasError,
      }"
      :tabindex
      type="text"
      :value="modelValue"
      :disabled="disabled"
      v-bind="$attrs"
      @input="input"
    />
  </div>
</template>

<script setup lang="ts">
  import { computed, useAttrs } from 'vue';

  import { basicControl, TextControlProps } from '@/shared/components/controls/helpers/controlBehaviour';

  defineOptions({
    inheritAttrs: false,
  });

  const props = defineProps<
    TextControlProps & {
      disabled?: boolean;
      modelValue?: string | null;
      uppercase?: boolean;
    }
  >();

  const emit = defineEmits<{
    'update:modelValue': [string | null];
  }>();

  const attrs = useAttrs();

  const { inputEl, focus, select, vSensitive } = basicControl(props);

  function input(event: Event) {
    let { value } = event.target as HTMLInputElement;

    if (props.uppercase) value = value.toUpperCase();
    emit('update:modelValue', value !== '' ? value : null);
  }

  // if readonly, exclude from tab index
  const tabindex = computed(() => {
    const isReadOnly = 'readonly' in attrs;
    return isReadOnly ? -1 : Number(attrs.tabindex || 0);
  });

  defineExpose({
    focus,
    select,
  });
</script>
