import InMemoryStorage from '@/shared/developmentFlags/InMemoryStorage';
import { getConfig } from '@/shared/init';

let fallbackStorage;

const defaultStorage = () => {
  if (localStorage) return localStorage;

  if (!fallbackStorage) fallbackStorage = new InMemoryStorage();
  console.info('[developmentFlags] localStorage not found. fallback to in-memory.'); // eslint-disable-line no-console
  return fallbackStorage;
};

const cast = (value, definition) => {
  if (definition.type === 'boolean') return value === 'true';

  if (definition.type === 'enum') {
    return definition.options.includes(value) ? value : undefined;
  }

  if (definition.type === 'enums') {
    // comma separated enum
    return value ? value.split(',') : [];
  }

  if (definition.type === 'string') {
    return value.toString();
  }

  return undefined;
};

const developmentFlags = {
  resolve({ namespace, definition, name = 'feature', storage = defaultStorage() }) {
    const url = new URL(window.location.href);
    const urlFlagParams = [...url.searchParams].map(param => param[0]).filter(param => param.startsWith(`${name}.`));
    const urlFlagsRaw = urlFlagParams.reduce(
      (flags, flagParam) => ({
        ...flags,
        [flagParam.replace(`${name}.`, '')]: url.searchParams.get(flagParam),
      }),
      {},
    );

    const storageKey = `developmentFlags.${namespace}`;

    if ('clearAll' in urlFlagsRaw) {
      storage.removeItem(storageKey);
    }

    const storedFlags = JSON.parse(storage.getItem(storageKey)) || {};

    // get values of flags from url, then storage, then defaults
    const flags = Object.keys(definition).reduce((current, featureName) => {
      const value =
        typeof urlFlagsRaw[featureName] !== 'undefined'
          ? cast(urlFlagsRaw[featureName], definition[featureName])
          : storedFlags[featureName];

      return { ...current, [featureName]: value ?? definition[featureName]?.default };
    }, {});

    // save non-default values to persist on next request
    const nonDefaultFlags = Object.entries(flags)
      .filter(([featureName, value]) => value !== definition[featureName].default)
      .reduce((current, [featureName, value]) => ({ ...current, [featureName]: value }), {});
    if (!Object.keys(nonDefaultFlags).length) {
      storage.removeItem(storageKey);
    }
    if (Object.keys(nonDefaultFlags).length) {
      storage.setItem(storageKey, JSON.stringify(nonDefaultFlags));
    }

    // remove flags from url once they've been parsed and stored
    if (urlFlagParams.length) {
      urlFlagParams.forEach(paramName => url.searchParams.delete(paramName));
      // remove feature queries from the URL, preserving vue-router state
      window.history.replaceState(window.history.state, null, url);
    }

    const geppettoConfig = getConfig();
    if (geppettoConfig.dev) {
      /* eslint-disable no-console */
      console.groupCollapsed('flags');
      console.table(flags);
      console.groupEnd();
      /* eslint-enable no-console */
    }

    return flags;
  },
  save(namespace, flags) {
    const storageKey = `developmentFlags.${namespace}`;
    const storage = defaultStorage();
    storage.setItem(storageKey, JSON.stringify(flags));
  },
};

export { developmentFlags as default };
