import { TimeRange } from '@/shared/DateTime';
import createModel from '@/shared/models/helpers/createModel';
import { components } from '@/shared/services/schema/geppetto-sender-app/consignments.schema';
import { PalletLender, PalletTransferType } from '@/shared/types/ConsignmentPallets';

import { OrderConfiguration } from '../services/org/graphql/graphql';

import AgreedService, { agreedServiceGqlFields, createAgreedService } from './AgreedService/AgreedService';
import Organisation, { createOrganisation, organisationGqlFields } from './Organisation';
import PackagingType, { createPackagingType } from './PackagingType';

export interface ConsignmentImportConfiguration {
  enabled: boolean;
  transferPolicy?: components['schemas']['ConsignmentTransferPolicy'];
}

export interface CostCentre {
  code: string;
  description?: string;
}

interface Site {
  id: string;

  /**
   * This is the name that is displayed 'internally' on various menus and filters around the site.
   */
  name: string;

  /**
   * This name is used when the site address is being displayed. e.g. consignment sender / receiver
   */
  displayName: string;

  /**
   * The label is used to group sites and add extra searchable meta.
   */
  label: string;
  addressId: string;
  organisation: Organisation;
  costCentreConfiguration: {
    costCentres: CostCentre[];
    required: boolean;
  };
  packagingTypes: PackagingType[];
  agreedServices: AgreedService[];
  orderConsolidationEnabled: boolean;
  deliveryTimeSlotsEnabled: boolean;

  consignmentImportConfiguration: ConsignmentImportConfiguration;

  dangerousGoods: {
    hasTransacted: boolean;
    isTransacting: boolean;
  };
  userCanTransactWithDangerousGoods: boolean;

  palletManagement?: {
    enabled: boolean;
    allowedPalletTypes: PalletLender[];
    preferredTransferType?: PalletTransferType;
  };
  siteAccess: { defaultPickupWindow?: TimeRange };
  orderConfiguration?: OrderConfiguration;

  // site development flags
  devFlags: null | object;
}

export const createSite = (partial?: Partial<Site>): Site =>
  createModel<Site>(
    {
      ...partial,
      displayName: partial?.displayName || partial?.name,
      organisation: createOrganisation(partial?.organisation),
      agreedServices: partial?.agreedServices?.map(createAgreedService) || [],
      packagingTypes: partial?.packagingTypes?.map(createPackagingType) || [],
      siteAccess: {
        defaultPickupWindow: partial?.siteAccess?.defaultPickupWindow,
      },
      dangerousGoods: {
        isTransacting: !!partial?.dangerousGoods?.isTransacting,
        hasTransacted: !!partial?.dangerousGoods?.hasTransacted,
      },
      userCanTransactWithDangerousGoods: !!partial?.userCanTransactWithDangerousGoods,
    },
    {
      id: '',
      name: '',
      displayName: '',
      label: '',
      addressId: '',
      organisation: createOrganisation(),
      costCentreConfiguration: {
        required: false,
        costCentres: [],
      },
      packagingTypes: [],
      agreedServices: [],
      orderConsolidationEnabled: false,
      deliveryTimeSlotsEnabled: false,
      consignmentImportConfiguration: partial?.consignmentImportConfiguration ?? {
        enabled: false,
        transferPolicy: 'manual',
      },
      orderConfiguration: partial?.orderConfiguration,
      dangerousGoods: {
        isTransacting: false,
        hasTransacted: false,
      },
      userCanTransactWithDangerousGoods: false,
      palletManagement: undefined,
      siteAccess: {
        defaultPickupWindow: undefined,
      },
      devFlags: null,
    },
  );

export const siteGqlFields = [
  'uuid',
  'name',
  'displayName',
  'label',
  'streetAddressId',
  { agreedServices: agreedServiceGqlFields },
  { organisation: organisationGqlFields },
  // { packagingTypes: PackagingType.gqlFields }, // hard-coded from enum for now

  // site level features
  'orderConsolidationEnabled',
  'deliveryTimeSlotsEnabled',
  {
    costCentreConfiguration: [
      'required',
      {
        costCentres: ['code', 'description'],
      },
    ],
  },
  {
    consignmentImportConfiguration: ['enabled', 'transferPolicy'],
  },
  {
    orderConfiguration: ['integrationEnabled'],
  },
  {
    palletManagement: ['enabled', 'allowedPalletTypes', 'preferredTransferType'],
  },
  {
    dangerousGoods: ['isTransacting', 'hasTransacted'],
  },
  'userCanTransactWithDangerousGoods',
  'devFlags',
  {
    siteAccess: [{ defaultPickupWindow: ['start', 'duration'] }],
  },
];

export default Site;
