export default {
  // vuelidate default validations
  required: 'cannot be blank',
  between: 'enter a value between {{min}} and {{max}}',
  alpha: 'alphabet characters only',
  alphaNum: 'alpha-numeric characters only',
  noSpaces: 'cannot include spaces',
  numeric: 'enter a valid number',
  integer: 'enter a valid number',
  decimal: 'enter a valid number, accepts decimal values',
  email: 'must be a valid email',
  sameAsPassword: 'passwords do not match',

  // custom
  validDate: 'enter a valid date',
  futureDate: 'the date cannot be in the past',
};
