import { ConsignmentConsolidationSuggestion as ConsolidationSuggestion, NewConsignment } from '@/shared/models';
import APIClient from '@/shared/services/api-client';

import { operations } from '@/shared/services/schema/geppetto-sender-app/consignments.schema';

import * as mappers from './mappers';

export default class ConsolidationClient {
  private apiClient: APIClient;

  constructor(apiClient: APIClient) {
    this.apiClient = apiClient;
  }

  public async requestSuggestions(consignment: NewConsignment): Promise<ConsolidationSuggestion[]> {
    const response = await this.apiClient.post<
      operations['getConsolidationCandidates']['responses']['200']['content']['application/json']
    >('/v0/consolidations', mappers.consolidation.consignmentToConsolidationRequest(consignment));

    return mappers.consolidation.clientConsolidationCandidatesToConsolidationSuggestions(response.data);
  }
}
