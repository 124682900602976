const shippingItemInstrumentationTypes = {
  PRESET_SELECTED: 'shipping item preset selected',
  PRESET_CREATED: 'shipping item preset created',
  PRESET_UPDATED: 'shipping item preset updated',
  PRESET_DELETED: 'shipping item preset deleted',

  PRESET_IMPORT: 'shipping item presets imported',
  PRESET_EXPORT: 'shipping item presets exported',
  PRESET_BULK_DELETE: 'shipping item presets bulk deleted',

  PRESET_OPENED: 'shipping item preset opened',

  VALIDATION_ERROR: 'shipping item preset validation error',
};

export default shippingItemInstrumentationTypes;
