import { markRaw, reactive } from 'vue';

interface ErrorBoundaryStore {
  criticalError?: Error;
  displayErrors: boolean;
  errors: Error[];
}
type ErrorBoundariesStore = Record<string, ErrorBoundaryStore>;

class ErrorStore {
  private errorBoundaries = reactive<ErrorBoundariesStore>({});

  registerErrorBoundary(name: string) {
    this.errorBoundaries[name] = {
      displayErrors: false,
      errors: [],
    };
  }

  unregisterErrorBoundary(name: string) {
    delete this.errorBoundaries[name];
  }

  setCriticalErrorBoundary(name: string, error: Error) {
    this.getErrorBoundary(name).criticalError = error;
  }

  /**
   * Clear the errors from an error boundary, but don't reset registered and display status.
   * @param name
   */
  clearErrors(name: string) {
    // reset doesn't need to throw if the boundary doesn't exist, as this may be
    // called during component unmount
    if (this.hasErrorBoundary(name)) {
      delete this.getErrorBoundary(name).criticalError;
      this.getErrorBoundary(name).errors = [];
    }
  }

  addError(name: string, error: Error) {
    // Errors should never be reactive, as their .render property may be used as a
    // <component :is=""> prop
    const nonReactiveError = markRaw(error);

    this.getErrorBoundary(name).errors.push(nonReactiveError);
  }

  registerErrorBoundaryDisplay(name: string) {
    const errorBoundary = this.errorBoundaries[name];
    if (errorBoundary.displayErrors) logger.warn(`Error Boundary ${errorBoundary} is already displaying errors`);
    errorBoundary.displayErrors = true;
  }

  unregisterErrorBoundaryDisplay(name: string) {
    if (this.hasErrorBoundary(name)) {
      this.getErrorBoundary(name).displayErrors = false;
    }
  }

  hasErrorBoundary(name: string) {
    return Object.keys(this.errorBoundaries).includes(name);
  }

  getErrorBoundary(name: string) {
    if (!this.hasErrorBoundary(name)) throw new Error(`Error boundary '${name}' not found`);
    return this.errorBoundaries[name];
  }

  canErrorBoundaryDisplayErrors(name: string) {
    return this.getErrorBoundary(name).displayErrors;
  }

  errorBoundaryIsCritical(name: string) {
    return !!this.getErrorBoundary(name).criticalError;
  }
}

const errorStore = new ErrorStore();

export default errorStore;
