import AppError from './AppError';

/**
 * Meant to be used a base class when defining custom Errors that should be
 * handleable. An error is "handleable" if it occurs as a normal part of operating
 * the app and does not represent an exception.
 */
export default class HandleableError extends AppError {
  public name = 'HandleableError';

  public readonly display = true;
}
