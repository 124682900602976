<template>
  <div class="card-footer-item has-text-centered is-text-subhead2">
    <p>
      To change your response to this pickup request, <br />
      email <a :href="mailto"> efm Logistics</a> or call 1300 769 705.
    </p>
  </div>
</template>

<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    pickup: { required: false, type: Object, default: null },
  });

  const ENQUIRIES_EMAIL = 'enquiries@efmlogistics.com.au';

  const mailto = computed(
    () =>
      `mailto:${ENQUIRIES_EMAIL}${
        props.pickup ? `?subject=Pickup enquiry: ${props.pickup.sender.name} [${props.pickup.reference}]` : ''
      }`,
  );
</script>

<style scoped></style>
