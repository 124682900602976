import { RouteMeta, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import instrumentation from '@/shared/instrumentation';

import appInstrumentationTypes from '@App/instrumentation/types';
import { useUserStorage } from '@App/scopedStorage';

import Org from '@Org/store/types';

export interface DefaultContext {
  id: UUID;
  type: 'site' | 'org';
}

type RouteMetaWithAllowed = RouteMeta & { allowed?: ('site' | 'org')[] };

export const useDefaultLandingPage = () => {
  const userStorage = useUserStorage();
  const defaultLandingPageKey = 'default-landing-page';

  const getDefaultLandingPage = () => userStorage.getItem<string>(defaultLandingPageKey) || 'site';

  const setDefaultLandingPage = (page: string) => {
    userStorage.setItem(defaultLandingPageKey, page);
    instrumentation.event(appInstrumentationTypes.USER_PREFERENCES_PAGE_DEFAULT_SET, { page });
  };

  const clearDefaultLandingPage = () => {
    userStorage.removeItem(defaultLandingPageKey);
  };

  return {
    getDefaultLandingPage,
    setDefaultLandingPage,
    clearDefaultLandingPage,
  };
};

export const useDefaultContext = () => {
  const store = useStore();
  const router = useRouter();

  const userStorage = useUserStorage();
  const defaultContextKey = 'default-context';

  const { getDefaultLandingPage, clearDefaultLandingPage } = useDefaultLandingPage();

  const getDefaultContext = (): DefaultContext =>
    userStorage.getItem(defaultContextKey) || {
      id: store.getters[Org.getters.defaultSite].id,
      type: 'site',
    };

  const setDefaultContext = (newContext: DefaultContext): void => {
    userStorage.setItem(defaultContextKey, {
      id: newContext.id,
      type: newContext.type,
    });

    instrumentation.event(appInstrumentationTypes.USER_PREFERENCES_SITE_DEFAULT_SET);

    const currentLandingPage = getDefaultLandingPage();
    const meta = router.resolve({ name: currentLandingPage }).meta?.context as RouteMetaWithAllowed;
    const allowedContextTypes = meta?.allowed || [];
    if (newContext.type === 'org' && !allowedContextTypes.includes(newContext.type)) {
      clearDefaultLandingPage();
    }
  };

  const clearDefaultContext = (): void => {
    userStorage.removeItem(defaultContextKey);
  };

  return {
    getDefaultContext,
    setDefaultContext,
    clearDefaultContext,
  };
};
