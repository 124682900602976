/**
 * AppStore - getter methods
 * @see https://vuex.vuejs.org/guide/getters.html
 */

import Auth from '@Auth/store/types';
import Org from '@Org/store/types';

import { InternalTypes as App } from './types';

const AppGetters = {
  [App.getters.user]: (state, getters) => ({
    ...getters[Auth.getters.user],
    ...getters[Org.getters.user],
  }),

  [App.getters.userIsAuthenticated]: (state, getters) => getters[Auth.getters.user]?.username !== undefined,
  [App.getters.userHasAcceptedTerms]: (state, getters) => getters[Org.getters.userHasAcceptedTerms],
  [App.getters.userHasChangedSite]: state => state.userHasChangedSite,
};

export default AppGetters;
