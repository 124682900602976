import { name as Address, store as AddressStore } from '@Address';
import { name as Auth, store as AuthStore } from '@Auth';
import { name as Consignment, store as ConsignmentStore } from '@Consignment';
import { name as ConsignmentTrack, store as ConsignmentTrackStore } from '@ConsignmentTrack';
import { name as GlobalSearch, store as GlobalSearchStore } from '@GlobalSearch';
import { name as Org, store as OrgStore } from '@Org';

const modules = {
  [Auth]: {
    store: AuthStore,
  },
  [Consignment]: {
    store: ConsignmentStore,
  },
  [ConsignmentTrack]: {
    store: ConsignmentTrackStore,
  },
  [Org]: {
    store: OrgStore,
  },
  [Address]: {
    store: AddressStore,
  },
  [GlobalSearch]: {
    store: GlobalSearchStore,
  },
};

export default modules;
