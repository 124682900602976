import { asyncImport, withContextPrefix } from '@App/router/helpers';

const DangerousGoodsPresetRoutes = [
  ...withContextPrefix(
    {
      path: 'dangerous-goods-presets',
      meta: {
        requiresAuthentication: true,
      },
    },
    [
      {
        path: '',
        name: 'dangerous-goods-presets.manage',
        component: asyncImport(() => import('@DangerousGoods/views/ManageDangerousGoodsPresets.vue')),
        meta: {
          title: 'Dangerous goods presets',
          enableFullStory: false,
          context: {
            allowed: ['site'],
          },
        },
      },
    ],
  ),
];

export default DangerousGoodsPresetRoutes;
