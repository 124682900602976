import { computed, ComputedRef, inject, reactive } from 'vue';

import ModalInstance from '@/shared/modalStack/ModalInstance';

export default function useModal() {
  const modal = inject<ComputedRef<ModalInstance>>('modal-instance');

  return reactive({
    close: () => modal?.value?.close(),
    isActive: computed(() => modal?.value?.isActive || false),
  });
}
