/**
 * SpanEvent
 *
 * Represent a process that has a start and an end, and create an instrumentation event that
 * signals the completion of the process with a measurement of how long it took.
 */
export default class SpanEvent {
  constructor(name, createPayload = {}, instrumentation = {}) {
    this.name = name;
    this.start = Date.now();
    this.createPayload = createPayload;
    this.instrumentation = instrumentation;

    // Fire a 'before' event that subscribers can use to provide useful state at the time
    this.beforePayload = this.instrumentation.event(`${name}:before`, createPayload);
  }

  /**
   * Signal the end of the span, generating an event in the instrumentation system.
   *
   * @param afterPayload State at the end of the span that might be useful to record.
   */
  async end(afterPayload) {
    // Prevent the span from being 'ended' multiple times
    if (this.completed) return;

    // Create the event in the instrumentation system
    // Keys in beforePayload will be overwritten by keys in afterPayload, so implementors need
    // to be carefully to choose well named keys for before/after values
    this.instrumentation.event(this.name, {
      ...this.beforePayload,
      ...afterPayload,
      duration: Date.now() - this.start,
    });

    this.completed = true;
  }
}
