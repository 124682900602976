import messageExtractor from '@/shared/validation/message-extractor';

export default {
  inject: {
    customErrorMessages: { default: null },
  },
  props: {
    /**
     *  customMessages overrides
     */
    customMessages: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    /**
     *  vuelidate validation model
     */
    validation: {
      required: false,
      type: Object,
      default: () => ({
        $anyError: false,
        $error: false,
        $invalid: false,
        $params: {},
        $touch: () => undefined,
      }),
    },
    /**
     *  errors keys to be ignored by displayedErrors computed property
     */
    ignoredErrors: { required: false, type: Array, default: () => [] },
  },

  computed: {
    /**
     * returns error keys with corresponding messages
     */
    errors() {
      // messageExtractor will always return validation errors for invalid
      // checks, even if the validation has not been marked as "dirty"
      // Returning an empty list when not dirty allows us to defer displaying
      // errors until we're ready based on user interaction.
      return this.validation.$errors?.length ? messageExtractor(this.validation) : {};
    },

    /**
     * returns only error messages in an array
     *
     * @returns {[]}
     */
    errorMessages() {
      return Object.values(this.errors);
    },

    /**
     * returns only error messages that are not ignore to display
     *
     * @returns {*[]}
     */
    displayErrorMessages() {
      return Object.keys(this.errors)
        .filter(errorName => !this.ignoredErrors.includes(errorName))
        .map(errorName => this.errors[errorName]);
    },

    invalid() {
      return this.validation.$invalid;
    },

    dirty() {
      return this.validation.$dirty;
    },

    showErrors() {
      // the vuelidate property "$error" or "$anyError" will be true when
      // child validations have errors, whereas this will return true when
      // this specific level of the validation tree is dirty
      return this.validation.$dirty && this.errorMessages.length > 0;
    },
  },

  methods: {
    /**
     * helper function to set the validation object into dirty state.
     * It needs to be called on value changes or blur (if required)
     * @protected
     */
    touch() {
      if (this.validation?.$touch && typeof this.validation.$touch === 'function') this.validation.$touch();
    },

    reset() {
      if (this.validation.$reset) {
        this.validation.$reset();
      }
    },
  },
};
