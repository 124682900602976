import { computed, unref } from 'vue';
import { useStore } from 'vuex';

import Org from '@Org/store/types';

import storageEngine from './storageEngine';
import { ScopedStorage } from './types';

const useUserStorage = (): ScopedStorage => {
  const store = useStore();
  const user = computed(() => store.getters[Org.getters.user]);

  const createKey = (suffix?: string) => {
    if (!user.value) throw new Error('User is required to use user storage');
    return `gep:user:${user.value.id}${suffix ? `:${suffix}` : ''}`;
  };

  const getItem = <T>(key: string): T | undefined => {
    const value = storageEngine.getItem(createKey(key));
    return value ? (JSON.parse(value) as T) : undefined;
  };

  const _getItems = () => {
    const userKey = createKey();
    const items: Record<string, unknown> = {};
    for (let i = 0; i < storageEngine.length; i += 1) {
      const key = storageEngine.key(i);
      if (key?.startsWith(userKey)) {
        const value = storageEngine.getItem(key);
        // pair the key down from 'gep:user:abc-123:foo' to 'foo'
        items[key.slice(userKey.length + 1)] = value ? JSON.parse(value) : undefined;
      }
    }
    return items;
  };

  const setItem = (key: string, value: unknown) => {
    const userKey = createKey(key);
    return storageEngine.setItem(userKey, JSON.stringify(unref(value)));
  };

  const removeItem = (key: string) => {
    const userKey = createKey(key);
    storageEngine.removeItem(userKey);
  };

  const clear = () => {
    const items = _getItems();
    Object.keys(items).forEach(key => removeItem(key));
  };

  return {
    createKey,
    getItem,
    _getItems,
    setItem,
    removeItem,
    clear,
  };
};

export default useUserStorage;
