/**
 * ManifestResultSet model
 * represents API result set when searching/listing manifests
 */

import { CONSIGNMENT_TYPES } from '@/shared/models/Consignment/Consignment';
import ManifestSummary from '@/shared/models/ManifestSummary';

const create = ({ manifests = [], total = 0, numPages = -1 } = {}) => ({
  manifests: manifests.map(ManifestSummary.create),
  total,
  numPages,
});

const createFromApi = ({ data = [], meta: { page: { totalItems = 0, itemsPerPage = 0 } = {} } = {} }) =>
  create({
    manifests: data.map(ManifestSummary.createFromApi),
    total: totalItems,
    numPages: itemsPerPage ? Math.ceil(totalItems / itemsPerPage) : -1,
  });

const ManifestResultSet = {
  create,
  createFromApi,
};

const ConsignmentFilterTypes = {
  All: 'all',
  Outbound: CONSIGNMENT_TYPES.OUTBOUND,
  Inbound: CONSIGNMENT_TYPES.INBOUND,
  Transfer: CONSIGNMENT_TYPES.TRANSFER,
};

export { ManifestResultSet as default, ConsignmentFilterTypes };
