<template>
  <NavigationListBox class="header-menu" size="sm">
    <template #trigger>
      <button type="button" class="header-menu-button" :class="{ active: isActive }">
        {{ title }}
        <SvgIcon :svg="ChevronDownIcon" size="small" class="caret" />
      </button>
    </template>
    <template #menu>
      <slot></slot>
    </template>
  </NavigationListBox>
</template>

<script setup>
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';

  import NavigationListBox from '@/shared/components/NavigationListBox.vue';
  import SvgIcon from '@/shared/icons/SvgIcon.vue';
  import ChevronDownIcon from '@/shared/icons/svgs/chevron-arrow-down.svg';

  const props = defineProps({
    title: {
      type: String,
      required: true,
    },
    baseRoute: {
      type: String,
      required: true,
    },
  });

  const route = useRoute();

  const isActive = computed(() => {
    if (!props.baseRoute) return false;
    return route.matched.some(({ name }) => name.startsWith(`${props.baseRoute}.`));
  });
</script>

<style scoped lang="scss">
  .header-menu-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background: #fff;
    border-radius: var(--radius-large);
    color: var(--copy-70);
    font-weight: $font-weight-medium;
    font-size: 15px;
    line-height: 20px;
    cursor: pointer;
    border: 0;
    transition:
      color 0.2s ease,
      background-color 0.2s ease;

    .caret {
      margin-left: 5px;
    }

    &:active,
    &:focus {
      outline: 1px solid var(--brand-100);
    }

    &.active {
      font-weight: $font-weight-bold;
    }
  }

  .header-menu:hover {
    .header-menu-button {
      color: var(--copy-100);
      background-color: var(--chrome-20);
    }
  }
</style>
