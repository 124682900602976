export const setPageTitleHook = (doc, defaultTitle, to, from, failure) => {
  // Return early if,
  // - navigation failed, the route will not be visited
  // - it's the same route, avoid internal navigation (filters) to override view setting custom titles
  if (failure || (from?.name && to?.name && from.name === to.name)) return;

  const routeConfig = [...to.matched].reverse().find(route => route.meta.title !== undefined);

  doc.title = routeConfig?.meta.title || defaultTitle;
};
