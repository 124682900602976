import mapValues from 'lodash/mapValues';

import FullStory from '@/shared/FullStory';
import instrumentation from '@/shared/instrumentation';

import appFeatures from '@App/config/appFeatures';
import appInstrumentationTypes from '@App/instrumentation/types';
import userMonitor from '@App/instrumentation/userMonitor';
import App from '@App/store/types';
import Auth from '@Auth/store/types';

// load instrumentation subscribers. This is an interim solution while we come up with a way
// to load these dynamically and off load them when they are not needed. For the moment the
// import is done here to keep track of all instrumentation imports
import '@AddressBook/instrumentation';
import '@Consignment/instrumentation';
import '@ConsignmentTrack/instrumentation';
import '@Autoprint/instrumentation';
import '@DangerousGoods/instrumentation';
import '@Pickup/instrumentation';
import '@GlobalSearch/instrumentation';
import '@Manifest/instrumentation';
import '@Org/instrumentation';
import '@ShippingItemPreset/instrumentation';
import '@App/instrumentation/forms';
import '@Order/instrumentation';

// Before the session check starts, record if the user is already authenticated
instrumentation.before(App.actions.checkExistingSession, ({ store }) => ({
  wasAuthenticated: store.getters[App.getters.userIsAuthenticated],
}));
instrumentation.on(App.actions.checkExistingSession, ({ store, wasAuthenticated }) => {
  const isAuthenticated = store.getters[App.getters.userIsAuthenticated];
  const sessionResumed = !wasAuthenticated && isAuthenticated;

  if (sessionResumed) {
    userMonitor.setUser(store.getters[App.getters.user].id);
    userMonitor.event('user session started', { 'usr.auth_method': 'session' });
  } else if (!isAuthenticated) {
    userMonitor.setUser(null);
  }
});

instrumentation.on(App.actions.confirmSession, ({ store }) => {
  const isAuthenticated = store.getters[App.getters.userIsAuthenticated];

  if (!isAuthenticated) {
    userMonitor.setUser(null);
  }
});

instrumentation.on(Auth.actions.login, ({ store }) => {
  const isAuthenticated = store.getters[App.getters.userIsAuthenticated];
  if (isAuthenticated) {
    userMonitor.setUser(store.getters[App.getters.user].id);
    userMonitor.event('user session started', { 'usr.auth_method': 'login' });
  }
});

instrumentation.on(Auth.actions.completeNewPassword, ({ store }) => {
  const isAuthenticated = store.getters[App.getters.userIsAuthenticated];
  if (isAuthenticated) {
    userMonitor.setUser(store.getters[App.getters.user].id);
    userMonitor.event('user session started', { 'usr.auth_method': 'login' });
  } else {
    userMonitor.setUser(null);
  }
});

instrumentation.on(Auth.actions.logout, () => {
  userMonitor.setUser(null);
});

instrumentation.on(appInstrumentationTypes.ADVANCED_LISTING_FILTER_OPEN, () => {
  userMonitor.event('advanced filter open');
});

instrumentation.on(appInstrumentationTypes.CONTEXT_CHANGED, ({ context, feature }) => {
  const featuresEnabled = mapValues(appFeatures, featureDefinition => feature.enabled(featureDefinition).value);
  const currentContext = context ? { siteId: context.site?.id || '', orgId: context.org?.id || '' } : undefined;
  userMonitor.setSite(currentContext, { features: featuresEnabled });
});

instrumentation.on(appInstrumentationTypes.USER_GUIDE_OPENED, () => {
  userMonitor.event('user guide opened');
  FullStory.event('user guide opened');
});

instrumentation.on(appInstrumentationTypes.USER_PREFERENCES_OPENED, () => {
  userMonitor.event('user preferences opened');
  FullStory.event('user preferences opened');
});

instrumentation.on(appInstrumentationTypes.USER_PREFERENCES_SITE_DEFAULT_SET, () => {
  userMonitor.event('user preferences site default set');
  FullStory.event('user preferences site default set');
});

instrumentation.on(appInstrumentationTypes.USER_PREFERENCES_PAGE_DEFAULT_SET, ({ page }) => {
  userMonitor.event('user preferences page default set', { page });
  FullStory.event('user preferences page default set', { page });
});

instrumentation.on(appInstrumentationTypes.TRACKED_WINDOW_BLOCKED, () => {
  userMonitor.event('tracked window blocked');
  FullStory.event('tracked window blocked');
});

instrumentation.on(appInstrumentationTypes.BROWSER_CHECK_SEEN, ({ reason }) => {
  userMonitor.event('browser check seen', { browserCheckFailReason: reason });
  FullStory.event('browser check seen', { browserCheckFailReason: reason });
});

instrumentation.on(appInstrumentationTypes.BROWSER_CHECK_DISMISSED, ({ reason }) => {
  userMonitor.event('browser check dismissed', { browserCheckFailReason: reason });
  FullStory.event('browser check dismissed', { browserCheckFailReason: reason });
});

const appInstrumentation = {
  install(app, options) {
    instrumentation.install(app, options);
  },
};

export default appInstrumentation;
