import { asyncImport, withContextPrefix } from '@App/router/helpers';

const AddressBookRoutes = [
  ...withContextPrefix(
    {
      path: 'address-book',
      meta: {
        requiresAuthentication: true,
      },
    },
    [
      {
        path: '',
        name: 'addressbook.manage',
        component: asyncImport(() => import('@AddressBook/views/ManageAddressBook.vue')),
        meta: {
          title: 'Address Book',
          enableFullStory: true,
          context: { allowed: ['site'] },
        },
      },
    ],
  ),
];

export default AddressBookRoutes;
