import { unref } from 'vue';

export { default as mergeValidationResults } from './mergeValidationResults';
export { default as withAsync } from './withAsync';

export function isFunction(val) {
  return typeof val === 'function';
}

export function isObject(val) {
  return val !== null && (typeof val === 'object' || isFunction(val));
}
