import Contact, { createContact } from '@/shared/models/Contact';
import DeliveryInstructions from '@/shared/models/DeliveryInstructions';
import Location, { createLocation } from '@/shared/models/Location';

/**
 * Tries to create a shared representation of the data structure of a sender or receiver.
 * Encapsulates essential details about location, and contact details, and delivery instructions (if
 * used for a delivery).
 *
 * @deprecated If used in a form, use AddresseeFormData. Other resources should use their own model.
 */
interface Addressee {
  location: Location;
  contact: Contact;
  deliveryInstructions?: DeliveryInstructions;
  addressBookEntryId?: string;
}

const newAddressee = (): Addressee => ({
  location: createLocation(),
  contact: createContact(),
});

export const addresseeFactory = {
  // Creates a new Addressee model.
  create: ({ location, contact, deliveryInstructions, addressBookEntryId }: Addressee): Addressee => ({
    location,
    contact,
    deliveryInstructions,
    addressBookEntryId,
  }),
};

/**
 * @deprecated Use `addresseeFactory.create` instead.
 */
export const createAddressee = (a: Addressee | undefined = newAddressee()) => addresseeFactory.create(a);

export default Addressee;
