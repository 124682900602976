// https://sweetalert2.github.io/#usage
// > It's possible to import JS and CSS separately, e.g. if you need to customize styles:
import Swal, { SweetAlertResult } from 'sweetalert2/dist/sweetalert2';

import { h, onBeforeUnmount, VNode, withDirectives } from 'vue';

import { POSITION, ToastInterface, useToast } from 'vue-toastification';

import { THEME } from '@/shared/consts';
import SvgIcon from '@/shared/icons/SvgIcon.vue';
import CloseIcon from '@/shared/icons/svgs/close.svg';
import TextParagraphs from '@/shared/notify/components/TextParagraphs.vue';
import createSensitiveDirective from '@/shared/sensitive/createSensitiveDirective';
import { DictValues } from '@/shared/types/utility';
import h2html from '@/shared/utils/h2html';

import DialogContent from './components/DialogContent.vue';
import ToastContent from './components/ToastContent.vue';

export const DIALOG_TYPE = {
  PRIMARY: THEME.PRIMARY,
  DANGER: THEME.DANGER,
  WARNING: THEME.WARNING,
} as const;
export const TOAST_TYPE = {
  DANGER: THEME.DANGER,
  INFO: THEME.INFO,
  SUCCESS: THEME.SUCCESS,
  WARNING: THEME.WARNING,
} as const;
export const ACTIONS = {
  CONFIRM: 'confirm',
  CANCEL: 'cancel',
  TIMEOUT: 'timeout',
} as const;

export const TOAST_TIMEOUT = {
  default: 5000,
  withAction: 10000,
} as const;

/**
 * Reformat the result returned by sweetalerts2 to be more consistent for our needs.
 */
function _normaliseUserAction(result: SweetAlertResult) {
  // User clicked confirm button
  if (result.value) {
    return ACTIONS.CONFIRM;
  }

  switch (result.dismiss) {
    // Toasts can time out
    case Swal.DismissReason.timer:
      return ACTIONS.TIMEOUT;

    // Treat all other dismissals as cancel (enumerated for future knowledge)
    case Swal.DismissReason.backdrop:
    case Swal.DismissReason.cancel:
    case Swal.DismissReason.close:
    case Swal.DismissReason.esc:
    default:
      return ACTIONS.CANCEL;
  }
}

type ToastID = Parameters<ToastInterface['dismiss']>[0]; // annoyingly not exported from the lib ;/

/**
 * Clear all alerts (used when logged out)
 */
const clear = () => {
  Swal.close();
};

interface DialogOptions {
  title: string;
  message?: string;
  confirmButton?: string;
  cancelButton?: string;
  type?: (typeof DIALOG_TYPE)[keyof typeof DIALOG_TYPE];
  returnFocus?: boolean;
  reverseButtons?: boolean;
  size?: 'small' | 'large';
  customClassContainer?: string;
  sensitive?: boolean;

  [otherProps: string]: unknown;
}

/**
 * Shorthand for generating a Sweetalert2 dialog with the settings to display
 * a consistent alert style.
 */
const dialog = async ({
  title,
  message = undefined,
  confirmButton = 'Ok',
  cancelButton = undefined,
  type = DIALOG_TYPE.PRIMARY,
  returnFocus = true,
  reverseButtons = false,
  size = 'small',
  customClassContainer = '',
  sensitive = false,
  ...otherConfig
}: DialogOptions) => {
  if (!title) throw new Error('Cannot create a dialog without a title');

  const sizes = {
    small: 560,
    large: 800,
  };

  const content: { title: string | VNode; html?: string } = {
    title,
  };

  if (sensitive) {
    const TitleComponent = {
      setup() {
        const vSensitive = createSensitiveDirective(true);
        return () => withDirectives(h('div', title), [[vSensitive]]);
      },
    };
    content.title = h2html(TitleComponent as never);
  }

  if (message || type !== DIALOG_TYPE.PRIMARY) {
    content.html = h2html(
      DialogContent as never,
      { type, sensitive },
      {
        default: () => (typeof message === 'string' ? h(TextParagraphs, { text: message }) : message),
      },
    );
  }

  const cancelButtonConfig = cancelButton
    ? {
        showCancelButton: true,
        cancelButtonText: cancelButton,
      }
    : {};

  // Raise the alert!
  const action = await Swal.fire({
    allowOutsideClick: false,
    allowEscapeKey: false,
    ...content,
    confirmButtonText: confirmButton,
    ...cancelButtonConfig,
    customClass: {
      container: `alert alert-${type} ${customClassContainer}`,
      confirmButton: `button is-${type} alert-btn-confirm`,
      cancelButton: 'button is-secondary',
    },
    reverseButtons: !reverseButtons,
    returnFocus,
    width: sizes[size] || sizes.small,
    ...otherConfig,
  });

  return _normaliseUserAction(action);
};

interface ToastAction {
  text: string;
  callback: () => Promise<void>;
}

export interface ToastOptions {
  title?: string;
  message: string;
  sensitive?: boolean;
  type?: DictValues<typeof TOAST_TYPE>;
  dismissable?: boolean;
  action?: ToastAction;
}

// Shorthand for generating a toast consistent with our styling.
const toast = ({
  type = TOAST_TYPE.SUCCESS,
  title,
  message,
  sensitive = false,
  dismissable = false,
  action,
}: ToastOptions) => {
  if (!message) throw new Error('Cannot create a toast without a message');

  const ToastComponent = h(ToastContent, {
    type,
    title,
    message,
    action,
    sensitive,
  });
  const toastInstance = useToast();

  return toastInstance(ToastComponent, {
    position: POSITION.BOTTOM_RIGHT,
    timeout: action ? TOAST_TIMEOUT.withAction : TOAST_TIMEOUT.default,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    draggablePercent: 0.6,
    closeButton: dismissable
      ? h('button', { class: 'button toast-action' }, h(SvgIcon, { svg: CloseIcon, size: 'small' }))
      : false,
    icon: false,
    hideProgressBar: !action,
  });
};

export const notify = {
  actions: ACTIONS,
  // kept for backwards compatibility
  types: { ...DIALOG_TYPE, ...TOAST_TYPE },
  DIALOG_TYPE,
  TOAST_TYPE,

  clear,
  dialog,
  alert,
  toast,
};

export function useNotify() {
  const toastsToDismissOnUnmount: ToastID[] = [];
  const toastInstance = useToast();

  onBeforeUnmount(() => {
    if (toastsToDismissOnUnmount.length) {
      toastsToDismissOnUnmount.forEach(toastId => {
        toastInstance.dismiss(toastId);
      });
    }
  });

  const toastFn = ({
    action,
    dismissOnUnmount = !!action,
    ...args
  }: Parameters<typeof toast>[0] & { dismissOnUnmount?: boolean }) => {
    const toastId = toast({ action, ...args });
    if (dismissOnUnmount) {
      toastsToDismissOnUnmount.push(toastId);
    }
  };

  // return notify;

  return {
    ...notify,
    toast: toastFn,
  };
}
