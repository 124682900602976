import { HandleableError } from '@/shared/errorHandling/errors';

export default class SiteIdLimitError extends HandleableError {
  public maximum: number;

  constructor(siteIdCount: number, maximum: number) {
    super(`Sites selected exceeds maximum of ${maximum}`);
    this.maximum = maximum;
  }
}
