import { Component, PropType } from 'vue';

const LIST_BOX_ITEM_PROPS = {
  iconLeft: {
    type: Object as PropType<Component>,
    default: undefined,
  },
  iconRight: {
    type: Object as PropType<Component>,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  active: {
    type: Boolean,
    default: false,
  },
  sensitive: {
    type: Boolean,
    default: false,
  },
};

export default LIST_BOX_ITEM_PROPS;
