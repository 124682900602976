import { Temporal } from '@js-temporal/polyfill';

export default class TimeRange {
  private _start: Temporal.PlainTime;

  private _duration: Temporal.Duration;

  public get start() {
    return this._start;
  }

  public get duration() {
    return this._duration;
  }

  public get end() {
    return Temporal.PlainTime.from(this._start.add(this._duration));
  }

  constructor(start: Temporal.PlainTime, duration: Temporal.Duration) {
    this._start = start;
    this._duration = duration;
  }

  public static from(
    item: string | { start: Temporal.PlainTime; end?: Temporal.PlainTime; duration?: Temporal.Duration | string },
  ) {
    if (typeof item === 'string') return this.fromString(item);
    if (item.duration) {
      const duration = Temporal.Duration.from(item.duration);
      return new TimeRange(item.start, duration);
    }
    if (item.end) {
      return new TimeRange(item.start, item.end.since(item.start));
    }
    throw new Error('TimeRange.from requires either a duration or an end');
  }

  private static fromString(item: string) {
    let match = /^(\d{2}:\d{2}:\d{2})\/(\d{2}:\d{2}:\d{2})$/.exec(item);
    if (match) {
      // start/end
      const start = Temporal.PlainTime.from(match[1]);
      const end = Temporal.PlainTime.from(match[2]);
      return new TimeRange(start, end.since(start));
    }

    match = /^\d{4}-\d{2}-\d{2}T(\d{2}:\d{2}:\d{2})\/\d{4}-\d{2}-\d{2}T(\d{2}:\d{2}:\d{2})$/.exec(item);
    if (match) {
      // start/end
      const start = Temporal.PlainTime.from(match[1]);
      const end = Temporal.PlainTime.from(match[2]);
      return new TimeRange(start, end.since(start));
    }

    match = /^(\d{2}:\d{2}:\d{2})\/(P[A-Z0-9]+)$/.exec(item);
    if (match) {
      // start/duration
      const start = Temporal.PlainTime.from(match[1]);
      const duration = Temporal.Duration.from(match[2]);
      return new TimeRange(start, duration);
    }

    throw new Error('Unable to parse TimeRange');
  }

  public toString(): string {
    const startString = this._start.toString();
    const durationString = this._duration.toString();
    return `${startString}/${durationString}`;
  }

  public isValid(): boolean {
    return this._duration.sign !== -1; // make sure end is after start.
  }
}
