// based on https://github.com/fatso83/retry-dynamic-import

type Importer = () => Promise<unknown>;

interface AsyncImportConfig {
  maxRetries: number;
  retryDelay: number;
  importFunction: (path: string) => unknown;
}

const getConfig = (userConfig: Partial<AsyncImportConfig>): AsyncImportConfig => ({
  maxRetries: 5,
  retryDelay: 2000, // ms
  importFunction: (path: string) => import(/* @vite-ignore */ path),
  ...userConfig,
});

const urlRegex = /^(https?:\/\/([\w-\d]+\.)+[\w-\d]+){0,1}(\/?[\w~,;\-./?%&+#=]*)$/;

const errorRegexes = [
  // example: Failed to fetch dynamically imported module: http://localhost:4173/assets/EstimateCreate-b4438b4d.js
  /^Failed to fetch dynamically imported module: (.*)/,

  // example: Unable to preload CSS for /assets/EstimateCreate-bda132f2.css
  /^Unable to preload CSS for (.*)/,
];

const importRegexes = [
  // example: ()=>Et(()=>import("./EstimateCreate-b4438b4d.js"),["assets/EstimateCreate-b4438b4d.js","assets/RouterTabs-71c81309.js"])
  /\("(.+?)"/,
];

const isValidPathOrUrl = (pathOrURL: string): boolean => {
  // attempt to use JavaScript's URL constructor to see if it's a valid fully qualified URL:
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const url = new URL(pathOrURL);
    return true;
  } catch {
    // failed, try the next strategy
  }

  // fall back to trying our regex
  return urlRegex.test(pathOrURL);
};

export const getModulePath = (error: Error, importFunctionSignature: string): string | undefined => {
  logger.info('asyncImport: getModulePath', { errorMessage: error.message, importFunctionSignature });

  // attempt 1: try and get the path from the error
  // this assumes that the exception will contain this specific text with the url of the module
  // if not, the url will not be able to parse and we'll get an error on that
  // eg. "Failed to fetch dynamically imported module: https://example.com/assets/Home.tsx"
  for (let i = 0; i < errorRegexes.length; i += 1) {
    const match = errorRegexes[i].exec(error.message);
    if (match && isValidPathOrUrl(match[1].trim())) return match[1].trim();
  }
  logger.info('asyncImport: Failed parse URL from error message', { error });

  // attempt 2: try and get the path from the import function body
  // Should work in most browsers
  for (let i = 0; i < importRegexes.length; i += 1) {
    const match = importRegexes[i].exec(importFunctionSignature);
    if (match && isValidPathOrUrl(match[1].trim())) return match[1].trim();
  }

  // failed to get a module path
  logger.error('asyncImport: Failed parse URL from error or import function signature', {
    error,
    importFunctionSignature,
  });
  return undefined;
};

const asyncImport =
  (importer: Importer, config: Partial<AsyncImportConfig> = {}) =>
  async () => {
    const importConfig = getConfig(config);
    let retryAttemptNumber = 0;

    const attemptRetry = async (modulePath: string) => {
      retryAttemptNumber += 1;
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          // add a timestamp to the url to force a reload the module (and not use the cached version - cache busting)
          const cacheBustedPath = `${modulePath}?t=${+new Date()}`;

          let result;
          try {
            result = await importConfig.importFunction(cacheBustedPath);
            logger.info(`asyncImport: Succeeded after ${retryAttemptNumber} retries`, { path: cacheBustedPath });
            resolve(result);
          } catch (error) {
            if (retryAttemptNumber < importConfig.maxRetries) {
              try {
                result = await attemptRetry(modulePath);
                resolve(result);
              } catch (retryError) {
                reject(retryError);
              }
              return;
            }
            reject(error);
          }
        }, importConfig.retryDelay);
      });
    };

    try {
      const result = await importer();
      return result;
    } catch (error) {
      logger.info('asyncImport: initial import failed', { error });

      const modulePath = getModulePath(error as Error, importer.toString());

      if (!modulePath) {
        // nothing we can do ...
        throw error;
      }

      try {
        const result = await attemptRetry(modulePath);
        if (result) return result;
      } catch {
        // if all else fails, re-throw the original error
        logger.info(`asyncImport: Failed after ${importConfig.maxRetries} retries`, { error });
        throw error;
      }
    }
    return undefined;
  };

export default asyncImport;
