import { computed, ref } from 'vue';

import useGetAgreedServiceModelForSummary from '@/app/modules/Consignment/behaviours/useGetAgreedServiceModelForSummary';
import useAsyncResource, { AsyncCallbackConfig } from '@/shared/behaviours/useAsyncResource';
import { PlainDateRange } from '@/shared/DateTime';
import { plainDateNow } from '@/shared/DateTime/helpers';
import { plainDateRangeToInterval } from '@/shared/DateTime/mappers';
import plainDateRange, { isValidPlainDateRange } from '@/shared/DateTime/PlainDateRange';
import { ConsignmentSummary } from '@/shared/models';
import ConsignmentResultSet from '@/shared/models/Consignment/ConsignmentResultSet';
import { mapPaginatedParamsToQuery, PaginatedParams } from '@/shared/models/helpers/PaginatedParams';
import { consignmentService } from '@/shared/services/sender';
import { ConsignmentListParams } from '@/shared/services/sender/ConsignmentClient';

import { useSiteId } from '@App/context';

import { PalletTransferType } from '../types/ConsignmentPallets';

export const sortAliases = {
  date: ['dispatchDate'],
  references: ['references'],
  consignment: ['consignmentNo'],
  carrier: ['carrier.name', 'agreedService.name'],
  from: ['sender.name', 'sender.address.locality', 'sender.address.subdivision', 'sender.address.postcode'],
  to: ['receiver.name', 'receiver.address.locality', 'receiver.address.subdivision', 'receiver.address.postcode'],
  eta: ['estimatedTimeOfArrival'],
  items: ['itemQuantity'],
  volume: ['volume'],
  weight: ['weight'],
} as const;

export interface ConsignmentSearchUserParams extends PaginatedParams {
  organisationId?: ConsignmentSummary['organisation']['id'];
  issues?: boolean;
  transfer?: boolean;
  dispatchDate?: PlainDateRange | 'all';
  serviceId?: ConsignmentSummary['agreedService']['id'] | 'all';
  type?: ConsignmentSummary['type'][];
  sites?: ConsignmentSummary['site']['id'][];
  deliveryTimeSlot?: boolean;
  hasDangerousGoods?: boolean;
  transferPolicy?: ConsignmentSummary['transferPolicy'];
  receiver?: string;
  eta?: PlainDateRange | 'all';
  query?: string;
  palletsTransferType?: PalletTransferType;
  sort?: keyof typeof sortAliases;
  isFullyDelivered?: boolean;
  // overload search param for consignments with past eta and isFullyDelivered=true
  overdue?: boolean;
}

const aliasToSortFields = (sortKey?: string) => {
  if (!sortKey) return undefined;

  const isDescending = sortKey.startsWith('-');
  const fieldName = isDescending ? sortKey.substring(1) : sortKey;

  const sortFields = sortAliases[fieldName as keyof typeof sortAliases];
  if (!sortFields) return `${sortKey}`;

  const sortQuery = sortFields.map(field => `${isDescending ? '-' : ''}${field}`);
  sortQuery.push('-updated');
  return sortQuery.join(',');
};

const mapFilterParams = ({
  dispatchDate,
  serviceId,
  type,
  deliveryTimeSlot,
  hasDangerousGoods,
  sites,
  query,
  sort,
  organisationId,
  transfer,
  issues,
  receiver,
  transferPolicy,
  palletsTransferType,
  eta,
  overdue,
}: ConsignmentSearchUserParams): ConsignmentListParams => {
  const filters: ConsignmentListParams['filters'] = {
    'organisation.id': organisationId,
    transfer,
    issues,
    deliveryTimeSlot,
    hasDangerousGoods,
    'site.id': sites,
    'receiver.name': receiver,
    type,
  };

  if (dispatchDate && dispatchDate !== 'all') {
    filters.dispatchDate = dispatchDate.toString();
  }

  if (eta && isValidPlainDateRange(eta)) {
    const interval = plainDateRangeToInterval(eta);
    filters.eta = [interval.start, interval.end];
  }

  if (overdue) {
    const pastEta: plainDateRange = [plainDateNow().subtract({ months: 6 }), plainDateNow().subtract({ days: 1 })];
    const interval = plainDateRangeToInterval(pastEta);
    filters.isFullyDelivered = false;
    filters.eta = [interval.start, interval.end];
  }

  if (serviceId && serviceId !== 'all') filters['agreedService.id'] = serviceId.split(',');

  if (serviceId && serviceId === 'all') filters['agreedService.id'] = undefined;
  if (transferPolicy) filters.transferPolicy = [transferPolicy];
  if (palletsTransferType && palletsTransferType !== 'unknown') filters['pallets.transferType'] = palletsTransferType;

  return {
    sort: aliasToSortFields(sort),
    search: query,
    filters,
  };
};

export function useConsignmentSearch() {
  const siteId = useSiteId();
  const exportURL = ref<string>();

  const fetchResults = async (config: AsyncCallbackConfig, params: ConsignmentSearchUserParams) => {
    const isValidParams = () => {
      const validAgreedService = params.serviceId === 'all' || !!params.serviceId?.length;
      const validSiteId = !!(params.sites?.length || siteId.value);
      return validAgreedService && validSiteId;
    };
    // Skip network call and return an empty result if params are invalid
    if (!isValidParams()) {
      return ConsignmentResultSet.create();
    }

    const mappedParams = {
      ...mapPaginatedParamsToQuery(params),
      ...mapFilterParams({
        ...params, // user filters
        sites: siteId.value ? [siteId.value] : params.sites, // implicit filters
      }),
    };

    const results = await consignmentService.searchConsignments(mappedParams, {
      signal: config.abortController.signal,
    });

    // Building the export URL for the last search
    exportURL.value = consignmentService.listConsignmentsUrlBuilder(mappedParams, 'csv')?.toString() || '';

    return results;
  };

  const { state, isLoading, fetch, reset } = useAsyncResource(fetchResults);

  const getAgreedServiceModelForSummary = useGetAgreedServiceModelForSummary();

  const consignments = computed(
    () =>
      state.value?.consignments.map(conSummary => ({
        ...conSummary,
        agreedServiceModel: getAgreedServiceModelForSummary(conSummary),
      })) || [],
  );

  return {
    consignments,
    noResults: computed(() => consignments.value?.length === 0),
    total: computed(() => state.value?.total || 0),
    numPages: computed(() => state.value?.numPages || 0),
    exportURL,
    isLoading,
    fetch,
    reset,
  };
}
