import { DictValues } from './types/utility';

export const FORM_TYPES = {
  CREATE: 'CREATE',
  VIEW_EDIT: 'VIEW_EDIT',
} as const;

export const FORM_SECTION_MODES = {
  display: 'display',
  editDisplay: 'edit-display',
  edit: 'edit',
} as const;

export type FormSectionMode = DictValues<typeof FORM_SECTION_MODES>;

export const THEME = {
  PRIMARY: 'primary',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
} as const;

export type Theme = DictValues<typeof THEME>;
