/**
 * AuthStore - mutation methods
 * @see https://vuex.vuejs.org/guide/mutations.html
 */

import { AuthError } from '@/shared/services/auth/errors';

import { createDefaultState } from '@Auth/store/state';

import { InternalTypes as Auth } from './types';

const AuthMutations = {
  [Auth.mutations.SET_REDIRECT](state, redirectPath) {
    state.redirectPath = redirectPath;
  },

  [Auth.mutations.CLEAR_REDIRECT](state) {
    state.redirectPath = createDefaultState().redirectPath;
  },

  [Auth.mutations.SET_USER](state, user) {
    state.user.username = user.username;
    state.user.id = user.attributes['custom:geppetto:userId'];

    if (!state.user.id) {
      throw new AuthError('User has no UUID in Cognito');
    }
  },

  [Auth.mutations.CLEAR_USER](state) {
    state.user = createDefaultState().user;
  },

  [Auth.mutations.SET_UNCONFIRMED_USER](state, user) {
    state.unconfirmedUser = user;
  },

  [Auth.mutations.CLEAR_UNCONFIRMED_USER](state) {
    state.unconfirmedUser = createDefaultState().unconfirmedUser;
  },

  [Auth.mutations.SET_RESET_PASSWORD_USERNAME](state, username) {
    state.resetPasswordUsername = username;
  },

  [Auth.mutations.CLEAR_RESET_PASSWORD_USERNAME](state) {
    state.resetPasswordUsername = null;
  },

  [Auth.mutations.SET_VERIFICATION_CODE_DETAILS](state, { destination, attributeName }) {
    state.verificationCodeDetails = { destination, attributeName };
  },

  [Auth.mutations.FORGOT_PASSWORD_COMPLETE](state) {
    state.isPasswordResetComplete = true;
  },

  [Auth.mutations.RESET_FORGOT_PASSWORD](state) {
    const defaults = createDefaultState();
    state.verificationCodeDetails = defaults.verificationCodeDetails;
    state.isPasswordResetComplete = defaults.isPasswordResetComplete;
  },

  [Auth.mutations.SET_MFA_VERIFICATION_REQUIRED](state, required) {
    state.MFAVerificationRequired = required;
  },
};

export default AuthMutations;
