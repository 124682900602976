import omit from 'lodash/omit';
import { shake } from 'radash';

import { Interval } from '@/shared/DateTime';
import { instantFromDateAndTime } from '@/shared/DateTime/mappers';
import { type NewConsignment, quoteFactory, type QuoteSet } from '@/shared/models';
import { isVerifiedAddress } from '@/shared/models/Consignment/ConsignmentAddressee';
import ConsignmentFormData from '@/shared/models/Consignment/ConsignmentFormData';
import ConsignmentFormSupplementalData from '@/shared/models/Consignment/ConsignmentFormSupplementalData';
import { removeEmptyEvaluations } from '@/shared/models/Quote/helpers';
import MappingError from '@/shared/services/errors/MappingError';
import { components, operations } from '@/shared/services/schema/geppetto-sender-app/quote-sets.schema';

import { Temporal } from '@js-temporal/polyfill';

type CreateQuoteSetRequest = operations['createQuoteSet']['requestBody']['content']['application/json'];
export const consignmentToCreateQuoteSetRequest = (
  consignment: NewConsignment | ConsignmentFormData,
  supplementalData?: ConsignmentFormSupplementalData,
): CreateQuoteSetRequest => {
  const siteId = 'siteId' in consignment ? consignment.siteId : supplementalData?.siteId;
  const consignmentId = 'id' in consignment ? consignment.id : supplementalData?.consignmentId;
  const senderAddressId =
    'addressId' in consignment.sender
      ? consignment.sender.addressId
      : 'location' in consignment.sender
        ? consignment.sender.location.addressId
        : undefined;
  const receiverAddressId =
    'addressId' in consignment.receiver
      ? consignment.receiver.addressId
      : 'location' in consignment.receiver
        ? consignment.receiver.location.addressId
        : undefined;

  if (!siteId) throw new MappingError('siteId is required');
  if (!senderAddressId) throw new MappingError('sender addressId is required');
  if (!receiverAddressId) throw new MappingError('receiver addressId is required');

  const isSenderResidential =
    'addressId' in consignment.receiver
      ? consignment.receiver.residential
      : 'location' in consignment.receiver
        ? consignment.receiver.location.residential
        : undefined;

  const createRequest: CreateQuoteSetRequest = {
    data: {
      dispatchDate: consignment.dispatchDate!.toString(),
      lineItems: consignment.lineItems.map(lineItem => {
        if (!lineItem.description) throw new MappingError('packaging is required');
        if (!lineItem.height) throw new MappingError('height is required');
        if (!lineItem.length) throw new MappingError('length is required');
        if (!lineItem.packagingType) throw new MappingError('packagingType is required');
        if (!lineItem.weight) throw new MappingError('weight is required');
        if (!lineItem.width) throw new MappingError('width is required');

        return {
          description: lineItem.description,
          height: Math.round(lineItem.height),
          itemReference: lineItem.reference,
          length: Math.round(lineItem.length),
          packagingType: lineItem.packagingType,
          quantity: Math.round(lineItem.quantity),
          weight: Math.round(lineItem.weight),
          width: Math.round(lineItem.width),
        };
      }),
      receiver: {
        addressId: receiverAddressId,
        residential: isSenderResidential,
      },
      sender: { addressId: senderAddressId },
      siteId,
    },
  };

  if (consignment.lineItems.some(lineItem => lineItem.dangerousGoods && lineItem.dangerousGoods.length > 0)) {
    createRequest.data.hasDangerousGoods = true;
  }

  const receiverDeliveryTimeSlot =
    'receiverDeliveryTimeSlot' in consignment
      ? consignment.receiverDeliveryTimeSlot
      : consignment.receiver.deliveryTimeSlot;
  if (receiverDeliveryTimeSlot?.requiresDTS) {
    let receiverAddress;
    if ('address' in consignment.receiver) {
      if (!isVerifiedAddress(consignment.receiver.address))
        throw new MappingError('receiver.address is not a valid address');

      receiverAddress = consignment.receiver.address;
    } else {
      receiverAddress = consignment.receiver.location.address;
    }
    if (!receiverDeliveryTimeSlot?.slot?.dateRange?.length) {
      throw new MappingError('DeliveryTimeSlot DateRange is required', consignmentId);
    }
    if (!receiverDeliveryTimeSlot?.slot?.timeRange?.start)
      throw new MappingError('DeliveryTimeSlot.timeRange.start Date[0] is required');
    if (!receiverDeliveryTimeSlot?.slot?.timeRange?.end)
      throw new MappingError('DeliveryTimeSlot.timeRange.end Date[0] is required');
    if (!receiverAddress?.timeZone) throw new MappingError('receiver.address.timezone is required');

    createRequest.data.receiver.deliveryTimeSlot = {
      window: Interval.from({
        start: instantFromDateAndTime(
          receiverDeliveryTimeSlot?.slot?.dateRange[0],
          receiverDeliveryTimeSlot?.slot?.timeRange.start,
          receiverAddress.timeZone,
        ),
        end: instantFromDateAndTime(
          receiverDeliveryTimeSlot?.slot?.dateRange[0],
          receiverDeliveryTimeSlot?.slot?.timeRange?.end,
          receiverAddress.timeZone,
        ),
      }).toString(),
      recurrences: receiverDeliveryTimeSlot?.slot?.dateRange[1]
        ? receiverDeliveryTimeSlot?.slot?.dateRange[0].until(receiverDeliveryTimeSlot.slot?.dateRange[1]).days
        : 0,
    };
  }

  return createRequest;
};

export const clientQuoteSetToQuoteSet = (
  quoteSet: components['schemas']['QuoteSet'],
  consignment: NewConsignment | ConsignmentFormData,
): QuoteSet => ({
  id: quoteSet.id,
  quotes: quoteSet.quotes.map(quote => {
    const evaluation = {
      ...omit(quote.evaluation, ['invalidDeliveryTimeSlotStartDate']),
      ...(quote.evaluation?.invalidDeliveryTimeSlotStartDate?.earliestPermittedDate
        ? {
            invalidDeliveryTimeSlotStartDate: {
              earliestPermittedDate: Temporal.PlainDate.from(
                quote.evaluation.invalidDeliveryTimeSlotStartDate.earliestPermittedDate,
              ),
              dateRange: ('receiverDeliveryTimeSlot' in consignment
                ? consignment.receiverDeliveryTimeSlot
                : consignment.receiver.deliveryTimeSlot
              )?.slot?.dateRange,
            },
          }
        : {}),
    };
    return quoteFactory.create({
      id: quote.id,
      agreedServiceId: quote.agreedServiceId,
      carrierId: quote.carrierId,
      carrierServiceId: quote.carrierServiceId,
      quoteSetId: quote.quoteSetId,
      // TODO: get rid of '' fallback for quote.etaDate when BE is done with refactoring - GEPPES-2695
      // even tho currently (while GEPPES-2695 is n progress) schema defines etaDate as optional in reality it's always present
      eta: quote.transitGuideDays === 25 ? '' : (quote.etaDate ?? ''), // GEPPES-2420 - Duke fallback transit guide
      chargesBreakdown: quote.chargesBreakdown,
      transitGuideDays: quote.transitGuideDays,
      currency: quote.currency,
      freight: quote.freight,
      fees: quote.charges,
      net: quote.net,
      tax: quote.tax,
      total: quote.total,
      selectable: quote.isSelectable,
      recommended: quote.isSelectable && !Object.keys(removeEmptyEvaluations(evaluation)).length,
      evaluation: shake(quote.evaluation),

      createdAt: quote.createdAt,
    });
  }),
  createdAt: quoteSet.createdAt,
});
