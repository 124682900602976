interface Locality {
  locality: string; // 'Port Melbourne'
  postcode: string; // '3000'
  subdivision: string; // 'VIC'
  countryId: string; // 'AU'
}

export interface LocalityWithTimeZone extends Locality {
  timeZone: string; // 'Australia/Melbourne'
}

export const localityFactory = {
  create: ({ countryId, subdivision, locality, postcode }: Locality): Locality => ({
    locality,
    subdivision,
    countryId,
    postcode,
  }),
  createNew: (): Locality => ({
    locality: '',
    subdivision: '',
    countryId: '',
    postcode: '',
  }),
  createWithTimeZone: ({
    countryId,
    subdivision,
    locality,
    postcode,
    timeZone,
  }: LocalityWithTimeZone): LocalityWithTimeZone => ({
    locality,
    subdivision,
    countryId,
    postcode,
    timeZone,
  }),
};

export default Locality;
