<template>
  <div class="modal-background" @click="quickCloseModal"></div>
  <div class="drawer-card" :class="{ 'is-active': modal.isActive }" :data-testref="dataTestref" v-bind="$attrs">
    <div class="drawer-card-head" :class="{ 'has-drop-shadow': headerDropShadow }">
      <div v-sensitive class="drawer-card-title">{{ title }}</div>
      <ButtonClose data-testref="drawer-close" @click="closeModal" />
    </div>
    <ErrorBoundary v-bind="errorBoundary" class="error-boundary">
      <section ref="contentRef" class="drawer-card-body">
        <slot>
          <!-- Drawer content -->
        </slot>
      </section>
      <footer class="drawer-card-foot hidden-if-empty">
        <slot name="footer"></slot>
      </footer>
    </ErrorBoundary>
  </div>
</template>

<script setup lang="ts">
  import { computedEager, useEventListener, useScroll } from '@vueuse/core';
  import { ref } from 'vue';

  import ButtonClose from '@/shared/components/ButtonClose.vue';
  import { ErrorBoundary, ErrorBoundaryConfig } from '@/shared/errorHandling';
  import { useModal } from '@/shared/modals/modalManager';
  import createSensitiveDirective from '@/shared/sensitive/createSensitiveDirective';

  const modal = useModal();

  const contentRef = ref<HTMLElement>();

  const props = withDefaults(
    defineProps<{
      title?: string;
      sensitive?: boolean;
      /* closes on keypress esc or click-outside */
      quickClose?: boolean;
      close?: (closeFn: () => void) => void;
      dataTestref?: string;
      errorBoundary?: ErrorBoundaryConfig;
    }>(),
    {
      title: '',
      sensitive: false,
      quickClose: true,
      close: undefined,
      dataTestref: undefined,
      errorBoundary: () => ({ name: 'error:drawer' }),
    },
  );

  defineOptions({
    inheritAttrs: false,
  });

  const closeModal = () => modal.isActive && (props.close ? props.close(modal.close) : modal.close());
  const quickCloseModal = () => props.quickClose && closeModal();

  useEventListener('keyup', e => {
    if (props.quickClose) {
      if (e.code === 'Escape') quickCloseModal();
    }
  });

  const { y: yScroll } = useScroll(contentRef);
  const headerDropShadow = computedEager(() => yScroll.value > 0);

  const vSensitive = createSensitiveDirective(() => props.sensitive);
</script>

<style lang="scss" scoped>
  .drawer-card-head.has-drop-shadow {
    transition-duration: 0.5s;
    box-shadow: $panel-shadow;
  }

  .error-boundary {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
  }

  .error-boundary :deep(> .banner-message) {
    margin: 24px 24px 0 24px;
  }

  .modal-background {
    // default bulma modal-background style stacks when multiple modals are open
    background-color: transparent;

    // only add a background colour for the modal-background before the single active modal
    // this prevents multiple modal-backgrounds stacking, causing progressively darker backgrounds
    &:has(+ .drawer-card.is-active) {
      background-color: rgba(10, 10, 10, 0.6);
    }
  }
</style>
