import { useMagicKeys } from '@vueuse/core';
import { watch } from 'vue';

import { modalManager, useModalManager } from '@/shared/modals/modalManager';

import DebugPane from '@App/modules/Debug/components/DebugPane.vue';

export default function useDebugPane() {
  const drawer = useModalManager();

  // user must hold Shift+G+E+P
  const { Shift_G_E_P } = useMagicKeys();

  watch(Shift_G_E_P, triggered => {
    if (triggered && !modalManager.getCurrent()) {
      drawer.open(DebugPane);
    }
  });
}
