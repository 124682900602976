import { createAddressee, createDeliveryInstructions } from '@/shared/models';
import { createContactFromAddressObject } from '@/shared/models/Contact';
import { locationFromAddressObject } from '@/shared/models/Location';

import { InternalTypes as Consignment, ConsignmentQuotesInternalTypes as ConsignmentQuotes } from './types';

const ConsignmentGetters = {
  [Consignment.getters.getConsignment]: state => state.consignment,

  // todo: should this be moved into a mapper? Where would that mapper live?
  [Consignment.getters.getConsignmentSenderAddressee]: state => {
    if (!state.consignment?.sender) {
      return null;
    }

    const addressDetails = state.address.addresses[state.consignment.sender.addressId] || {};
    const location = locationFromAddressObject({
      name: state.consignment.sender.name,
      ...addressDetails,
    });

    const contact = createContactFromAddressObject(state.consignment.sender);

    return createAddressee({
      location,
      contact,
    });
  },

  // todo: should this be moved into a mapper? Where would that mapper live?
  [Consignment.getters.getConsignmentReceiverAddressee]: state => {
    if (!state.consignment?.receiver) {
      return null;
    }

    const addressDetails = state.address.addresses[state.consignment.receiver.addressId] || {};
    const location = locationFromAddressObject({
      name: state.consignment.receiver.name,
      ...addressDetails,

      line2: state.consignment.receiver.line2,
    });

    const contact = createContactFromAddressObject(state.consignment.receiver);

    return createAddressee({
      location,
      contact,
      deliveryInstructions: createDeliveryInstructions({
        authorityToLeave: state.consignment.receiver.authorityToLeave,
        specialInstructions: state.consignment.receiver.specialInstructions,
      }),
    });
  },

  [Consignment.getters.getConsignmentQuoteDetails]: (state, getters) => {
    if (!state.consignment?.quoteId) {
      return null;
    }
    return getters[ConsignmentQuotes.getters.getQuote](state.consignment.quoteId);
  },
  [Consignment.getters.getConsignmentQuotes]: (state, getters) => getters[ConsignmentQuotes.getters.getQuotes] || [],

  [Consignment.getters.getConsignmentEnquiries]: state => state.enquiries,

  [Consignment.getters.getConsignmentVersion]: state => state.meta.version,
};

export default ConsignmentGetters;
