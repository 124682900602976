import { PaginatedItems } from '@/shared/models/helpers/PaginatedItems';
import { components } from '@/shared/services/schema/geppetto-sender-app/shared.schema';

export function mapPageMetaToPaginatedItemsMeta(
  pageMeta: components['schemas']['Page'],
): Omit<PaginatedItems<never>, 'items'> {
  return {
    total: pageMeta.totalItems,
    offset: pageMeta.startIndex,
    pageSize: pageMeta.itemsPerPage,
    numPages: Math.ceil(pageMeta.totalItems / pageMeta.itemsPerPage),
  };
}
