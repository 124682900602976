import { Temporal } from '@js-temporal/polyfill';

import { creditReportingFiles, privacyFiles, termsFiles } from './load-documents';

export function documentsByDate(files) {
  // make all legal documents take effect on the listed date *in Melbourne*
  // since that is where efm is headquartered. this is not a legal requirement
  // but a common sense decision as our dates must be anchored to a timezone.
  return Object.keys(files).reduce((docs, filename) => {
    const fileDate = Temporal.PlainDate.from(filename.match(/\d{4}-\d{2}-\d{2}/)[0]);

    // key the files by the date/time they should take effect for the user
    // which should be midnight melbourne time on the date in the filename
    const dateKey = fileDate.toZonedDateTime('Australia/Melbourne').toString();
    return {
      ...docs,
      [dateKey]: files[filename],
    };
  }, {});
}

export function latestCurrentDate(dates) {
  const today = Temporal.Now.zonedDateTimeISO('Australia/Melbourne');
  return (
    dates
      // convert all strings to ZDTs to make calcs easier
      .map(dateString => Temporal.ZonedDateTime.from(dateString))
      // filter out dates in the future
      .filter(date => Temporal.ZonedDateTime.compare(date, today) < 1)
      // sort by date
      .sort((a, b) => Temporal.ZonedDateTime.compare(a, b))
      // take the last one
      .pop()
      // back to string
      .toString()
  );
}

// only export the Credit Reporting Policy document that should be displayed
const creditReportingPolicies = documentsByDate(creditReportingFiles);
export const creditReportingLatestDate = latestCurrentDate(Object.keys(creditReportingPolicies));
export const creditReportingPolicyDocument = creditReportingPolicies[creditReportingLatestDate];

// only export the Privacy Policy document that should be displayed
const privacyPolicies = documentsByDate(privacyFiles);
export const privacyPolicyLatestDate = latestCurrentDate(Object.keys(privacyPolicies));
export const privacyPolicyDocument = privacyPolicies[privacyPolicyLatestDate];

// only export the Terms document that should be displayed
const terms = documentsByDate(termsFiles);
export const termsLatestDate = latestCurrentDate(Object.keys(terms));
export const termsDocument = terms[termsLatestDate];

// if any of the legal documents is updated, the user will have to accept all three again and register a new accepted date
export const latestLegalDate = latestCurrentDate([creditReportingLatestDate, privacyPolicyLatestDate, termsLatestDate]);

// Complain loudly if documents can't be found or no documents are covering
// the current date. We should always have a document with a date equal or
// prior to today, this will ensure they don't get moved or removed by accident.
if (!creditReportingLatestDate || !creditReportingPolicyDocument) {
  throw new Error('Could not determine a valid Credit Reporting Policy document for the current date');
}
if (!privacyPolicyLatestDate || !privacyPolicyDocument) {
  throw new Error('Could not determine a valid Privacy Policy document for the current date');
}
if (!termsLatestDate || !termsDocument) {
  throw new Error('Could not determine a valid Terms of Use document for the current date');
}
