import FullStory from '@/shared/FullStory';
import instrumentation from '@/shared/instrumentation';

import userMonitor from '@App/instrumentation/userMonitor';

import shippingItemInstrumentationTypes from './types';

const createOrUpdatePreset = (action, { preset, actionContext, fromExistingPresetId }) => {
  userMonitor.event(action, {
    shipping_item_preset: {
      id: preset.id,
      // Facet: @context.shipping_item_preset.with_length
      with_length: !!preset.length,
      // Facet: @context.shipping_item_preset.with_width
      with_width: !!preset.width,
      // Facet: @context.shipping_item_preset.with_height
      with_height: !!preset.height,
      // Facet: @context.shipping_item_preset.with_weight
      with_weight: !!preset.weight,
      // Facet: @context.shipping_item_preset.from_existing_preset_id
      from_existing_preset_id: !!fromExistingPresetId,
    },
    // Facet: @context.action_context
    action_context: actionContext,
  });

  FullStory.event(action, {
    shippingItemPresetId: preset.id,
    actionContext,
    fromExistingPresetId,
  });
};

instrumentation.on(shippingItemInstrumentationTypes.PRESET_CREATED, payload => {
  createOrUpdatePreset('shipping item preset created', payload);
});

instrumentation.on(shippingItemInstrumentationTypes.PRESET_UPDATED, payload => {
  createOrUpdatePreset('shipping item preset updated', payload);
});

instrumentation.on(shippingItemInstrumentationTypes.PRESET_DELETED, ({ preset, actionContext }) => {
  userMonitor.event('shipping item preset deleted', {
    shipping_item_preset: {
      id: preset.id,
    },
    // Facet: @context.action_context
    action_context: actionContext,
  });

  FullStory.event('shipping item preset deleted', {
    shippingItemPresetId: preset.id,
    actionContext,
  });
});

instrumentation.on(shippingItemInstrumentationTypes.VALIDATION_ERROR, ({ error }) => {
  userMonitor.event('shipping item preset validation error', {
    error: {
      // Facet: @context.error.code
      code: error.apiError?.code,
      // Facet: @context.error.field
      field: error.apiError?.source?.pointer?.replace?.('/data/', ''),
    },
  });

  FullStory.event('shipping item preset validation error', {});
});

instrumentation.before(shippingItemInstrumentationTypes.PRESET_BULK_DELETE, payload => payload);
instrumentation.on(shippingItemInstrumentationTypes.PRESET_BULK_DELETE, ({ presetIds, duration }) => {
  userMonitor.event('shipping item presets bulk deleted', {
    duration,
    presets_deleted: presetIds.length, // Measure: @context.presets_deleted
  });

  FullStory.event(
    'shipping item presets bulk deleted',
    {
      duration,
      presetsDeleted: presetIds.length,
    },
    {
      duration: 'int',
      presetsDeleted: 'int',
    },
  );
});

instrumentation.before(shippingItemInstrumentationTypes.PRESET_IMPORT, payload => payload);
instrumentation.on(
  shippingItemInstrumentationTypes.PRESET_IMPORT,
  ({ imported, duration, existing, presets, validationErrors }) => {
    userMonitor.event('shipping item presets imported', {
      total: presets.length, // Measure: @context.total
      imported, // Measure: @context.imported
      existing, // Measure: @context.existing
      validation_errors: validationErrors, // Measure: @context.validation_errors
      duration,
    });

    FullStory.event(
      'shipping item presets imported',
      {
        total: presets.length,
        imported,
        existing,
        validationErrors,
        duration,
      },
      {
        total: 'int',
        imported: 'int',
        existing: 'int',
        validationErrors: 'int',
        duration: 'int',
      },
    );
  },
);

instrumentation.before(shippingItemInstrumentationTypes.PRESET_EXPORT, payload => payload);
instrumentation.on(shippingItemInstrumentationTypes.PRESET_EXPORT, ({ duration, presets, filter }) => {
  userMonitor.event('shipping item presets exported', {
    duration,
    presets_exported: presets.length, // Measure: @context.presets_exported
    filter_applied: !!filter, // Facet: @context.filter_applied
  });

  FullStory.event(
    'shipping item presets exported',
    {
      duration,
      presetsExported: presets.length,
      filterApplied: !!filter,
    },
    {
      duration: 'int',
      presetsExported: 'int',
    },
  );
});
