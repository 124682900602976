import { Temporal } from '@js-temporal/polyfill';

import { Destination, NewConsignment, Quote, quoteFactory, QuoteSet, quoteSetFactory } from '@/shared/models';
import { removeEmptyEvaluations } from '@/shared/models/Quote/helpers';
import { GeppettoJSONApiResponse } from '@/shared/services/api-client';
import { PickupServiceDetails } from '@/shared/services/duke/types/PickupService';
import { operations } from '@/shared/services/schema/geppetto-duke/pickup-service.schema';

import { DukeApiQuote, DukeApiQuoteSet, DukeApiQuoteSetRequest } from './types/DukeApiQuote';

type DukeDestination = { name: string; subdivision: string; postcode: string; country: string };

export const QuoteMapper = {
  fromApi: ({
    id,
    carrierServiceId,
    chargesBreakdown,
    createdAt,
    agreedServiceId,
    etaDate,
    carrierId,
    currency,
    charges,
    net,
    quoteSetId,
    tax,
    freight,
    total,
    transitGuideDays,
    evaluation,
  }: DukeApiQuote): Quote => {
    const mappedEvaluation: Quote['evaluation'] = { ...evaluation };

    const mapDestination = ({ postcode, subdivision, name, country }: DukeDestination): Destination => ({
      locality: name,
      postcode,
      subdivision,
      countryId: country,
    });

    if (evaluation?.unavailablePricing) {
      const { from, to } = evaluation.unavailablePricing as { from: DukeDestination; to: DukeDestination };
      mappedEvaluation.unavailablePricing = {
        from: mapDestination(from),
        to: mapDestination(to),
      };
    }
    if (evaluation?.unsupportedFromLocality) {
      const from = evaluation.unsupportedFromLocality as DukeDestination;
      mappedEvaluation.unsupportedFromLocality = mapDestination(from);
    }
    if (evaluation?.unsupportedToLocality) {
      const to = evaluation.unsupportedToLocality as DukeDestination;
      mappedEvaluation.unsupportedToLocality = mapDestination(to);
    }

    // Temporally deriving this field form evaluation until the endpoint gets migrated to sender-app
    const selectable = evaluation
      ? Object.keys(evaluation).length === 0 ||
        (Object.keys(evaluation).length === 1 && 'unavailablePricing' in evaluation)
      : true;

    return quoteFactory.create({
      id,
      carrierServiceId,
      chargesBreakdown,
      createdAt,
      agreedServiceId,
      eta: transitGuideDays === 25 ? '' : etaDate, // GEPPES-2420 - Duke fallback transit guide
      carrierId,
      currency,
      fees: charges,
      net,
      quoteSetId,
      tax,
      freight,
      total,
      transitGuideDays,
      evaluation: mappedEvaluation,
      selectable,
      recommended: selectable && !Object.keys(removeEmptyEvaluations(mappedEvaluation)).length,
    });
  },
};

export const QuoteSetMapper = {
  fromApi: ({ data: { id, attributes } }: GeppettoJSONApiResponse<DukeApiQuoteSet>): QuoteSet =>
    quoteSetFactory.create({
      id,
      createdAt: attributes.createdAt,
      quotes: attributes.quotes.map(QuoteMapper.fromApi),
    }),
};

export const QuoteSetRequestMapper = {
  toApi: (consignment: NewConsignment): DukeApiQuoteSetRequest => ({
    cargo: consignment.lineItems.map(item => ({
      itemReference: item.reference!,
      description: item.description!,
      packagingType: item.packagingType!,
      quantity: Math.round(item.quantity),
      length: Math.round(item.length!),
      width: Math.round(item.width!),
      height: Math.round(item.height!),
      weight: Math.round(item.weight!),
    })),
    fromAddressId: consignment.sender.addressId!,
    siteId: consignment.siteId!,
    toAddressId: consignment.receiver.addressId!,
    dispatchDate: consignment.dispatchDate!,
  }),
};

type PickupServiceResponse = operations['getPickupService']['responses']['200']['content']['application/json']['data'];
export const mapClientPickupServiceToPickupService = (response: PickupServiceResponse): PickupServiceDetails => ({
  pickupBookingCutoff: Temporal.Instant.from(response.pickupBookingCutoff),
});

export default {
  QuoteMapper,
};
