import DGFormData from '@DangerousGoods/models/DGFormData';
import DGFormDataDeclarable from '@DangerousGoods/models/DGFormDataDeclarable';
import DGFormDataNotifiable from '@DangerousGoods/models/DGFormDataNotifiable';

import DangerousGoods, { DeclarableDangerousGoods, NotifiableDangerousGoods } from './DangerousGoods';

export function isDeclarable(dangerousGoods: DangerousGoods): dangerousGoods is DeclarableDangerousGoods {
  return !dangerousGoods.notifiable;
}

export function isNotifiable(dangerousGoods: DangerousGoods): dangerousGoods is NotifiableDangerousGoods {
  return dangerousGoods.notifiable;
}

export function isDeclarableDGForm(dangerousGoods: DGFormData): dangerousGoods is DGFormDataDeclarable {
  return dangerousGoods.dgType === 'declarable';
}

export function isNotifiableDGForm(dangerousGoods: DGFormData): dangerousGoods is DGFormDataNotifiable {
  return dangerousGoods.dgType === 'notifiable';
}
