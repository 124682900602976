import { addListenersTo, callHandlerIfOutside, parseEventTypes, removeListenersFrom } from './utils';

const EVENTS = ['click', 'mouseup', 'focus', 'keyup'];
const DEFAULT_EVENTS = ['click'];
const VISITED_EVENTS = ['click', 'mouseup', 'focus', 'keyup', 'touchend'];

export default {
  beforeMount(el, { arg: eventTypes, value: config }) {
    const options = typeof config === 'function' ? { handler: config } : config;

    el.__onLeaveEventTypes__ = options.eventTypes || parseEventTypes(eventTypes, EVENTS) || DEFAULT_EVENTS;

    // when the user interacts inside the element, setup the "outside" event listeners
    // to be invoked when the interact outside the element
    el.__onLeaveVisitedHandler__ = () => {
      addListenersTo(document, el.__onLeaveEventTypes__, el.__onLeaveOutsideHandler__, { capture: true });
    };

    // when any event fires outside the target, we know the user has "left"
    const callUserHandlerOnce = event => {
      // invoke the user handler
      options.handler(event);
      // remove all other "outside" handlers so we don't fire the user handler more than once
      removeListenersFrom(document, el.__onLeaveEventTypes__, el.__onLeaveOutsideHandler__);
    };
    el.__onLeaveOutsideHandler__ = callHandlerIfOutside.bind(null, { el, ...options, handler: callUserHandlerOnce });

    addListenersTo(el, VISITED_EVENTS, el.__onLeaveVisitedHandler__);
  },
  unmounted(el) {
    removeListenersFrom(document, el.__onLeaveEventTypes__, el.__onLeaveOutsideHandler__);
    removeListenersFrom(el, VISITED_EVENTS, el.__onLeaveVisitedHandler__);
  },
};
