const mutations = {
  LOAD: 'LOAD',
  END: 'END',
};

export default {
  namespaced: true,
  state: {
    activeLoaders: [],
  },
  getters: {
    isLoading: state => loaderMessage => state.activeLoaders.indexOf(loaderMessage) > -1,
    anyLoading: state => state.activeLoaders.length > 0,
  },
  actions: {
    load: ({ commit }, loaderMessage) => commit(mutations.LOAD, loaderMessage),
    end: ({ commit }, loaderMessage) => commit(mutations.END, loaderMessage),
  },
  mutations: {
    [mutations.LOAD](state, loaderMessage) {
      state.activeLoaders.push(loaderMessage);
    },
    [mutations.END](state, loaderMessage) {
      state.activeLoaders.splice(state.activeLoaders.indexOf(loaderMessage), 1);
    },
  },
};
