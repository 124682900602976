import APIClient from '@/shared/services/api-client';
import AutoprintClient from '@/shared/services/sender/AutoprintClient';
import ConsignmentClient from '@/shared/services/sender/ConsignmentClient';

import ConsolidationClient from './ConsolidationClient';
import EstimateClient from './EstimateClient';
import ManifestClient from './ManifestClient';
import OrderClient from './OrderClient';
import PickupClient from './PickupClient';
import QuoteClient from './QuoteClient';
import TrackingClient from './TrackingClient';

export { default as ConsignmentAlreadyManifestedError } from '@/shared/services/sender/errors/ConsignmentAlreadyManifestedError';
export { default as ConsignmentAlreadyUpdatedError } from '@/shared/services/sender/errors/ConsignmentAlreadyUpdatedError';

if (
  !import.meta.env.GEPPETTO_SERVICE_SENDER_URL ||
  !import.meta.env.GEPPETTO_MAX_SITE_ID_PARAMS ||
  !import.meta.env.GEPPETTO_DEFAULT_RESOURCES_PER_PAGE
) {
  throw new Error('GEPPETTO .env not correctly configured');
}

const baseUrl = import.meta.env.GEPPETTO_SERVICE_SENDER_URL;
const maxSiteIdParams = Number(import.meta.env.GEPPETTO_MAX_SITE_ID_PARAMS);
const resourcesPerPage = Number(import.meta.env.GEPPETTO_DEFAULT_RESOURCES_PER_PAGE);

const apiClient = new APIClient(baseUrl);

const consignmentService = new ConsignmentClient(apiClient, {
  maxSiteIdParams,
  baseUrl,
});
const estimateService = new EstimateClient(apiClient, {
  maxSiteIdParams,
  resourcesPerPage,
});
const trackingService = new TrackingClient({
  baseUrl,
});
const pickupService = new PickupClient(apiClient, {
  maxSiteIdParams,
  resourcesPerPage,
});
const quoteService = new QuoteClient(apiClient);
const consolidationService = new ConsolidationClient(apiClient);
const orderService = new OrderClient(apiClient);
const manifestService = new ManifestClient(apiClient, {
  maxSiteIdParams,
  baseUrl,
});

const autoprintService = new AutoprintClient(apiClient);

/*
 * keeping backwards compatibility with old SenderService
 */
type SenderService = ConsignmentClient & { tracking: TrackingClient; pickup: PickupClient };
const senderService: SenderService = consignmentService as SenderService;
senderService.pickup = pickupService;
senderService.tracking = trackingService;

/*
 * keeping backwards compatibility with old SenderService
 */
export const listConsignmentUrlBuilder = consignmentService.listConsignmentsUrlBuilder.bind(consignmentService);

export {
  senderService as default,
  // services
  autoprintService,
  consignmentService,
  consolidationService,
  estimateService,
  manifestService,
  pickupService,
  quoteService,
  trackingService,
  orderService,
};
