import { asyncImport } from '@App/router/helpers';
import StatefulRedirect from '@App/router/StatefulRedirect';
import App from '@App/store/types';

import ForgotPassword from './views/ForgotPassword.vue';
import MFAVerify from './views/MFAVerify.vue';
import ResetPassword from './views/ResetPassword.vue';
import UserLogin from './views/UserLogin.vue';

const AuthRoutes = [
  {
    path: '/login',
    name: 'auth.login',
    component: UserLogin,
    meta: {
      requiresAuthentication: false,
      title: 'Login',
      enableFullStory: true,
    },
  },
  {
    path: '/complete-new-password',
    name: 'auth.complete-new-password',
    component: asyncImport(() => import('./views/CompleteNewPassword.vue')),
    meta: {
      requiresAuthentication: false,
      title: 'Select a new password',
      enableFullStory: true,
    },
  },
  {
    // this URL is linked externally from transactional emails. if it is changed, ensure a
    // redirect is put in place from this path.
    path: '/forgot-password',
    name: 'auth.forgot-password',
    component: ForgotPassword,
    meta: {
      title: 'Forgot Password',
      enableFullStory: true,
    },
  },
  {
    path: '/reset-password',
    name: 'auth.reset-password',
    component: ResetPassword,
    meta: {
      title: 'Reset Password',
      enableFullStory: true,
    },
  },
  {
    path: '/mfa',
    name: 'auth.verify-mfa',
    component: MFAVerify,
    meta: {
      title: 'MFA',
      enableFullStory: true,
    },
  },
  {
    path: '/accept-terms-of-use',
    name: 'auth.accept-terms',
    component: asyncImport(() => import('./views/AcceptTermsAndPrivacy.vue')),
    meta: {
      requiresAuthentication: true,
      title: 'Terms of Use',
      enableFullStory: true,
    },
  },
  {
    path: '/logout',
    name: 'auth.logout',
    component: StatefulRedirect,
    props: {
      redirectTo: async ({ store }) => {
        await store.dispatch(App.actions.logout);
        return { name: 'auth.login' };
      },
    },
    meta: {
      requiresAuthentication: false,
      title: 'Logout',
      enableFullStory: true,
    },
  },
];

export default AuthRoutes;
