<template>
  <div :class="{ 'is-hidden': !!browserCheck }">
    <LockedBanner id="width-warning" theme="warning" :icon="IconDisplay">
      For the optimal experience use a widescreen display.
    </LockedBanner>
    <LockedBanner v-if="!isOnline" theme="danger" :icon="IconDanger" sticky>
      Unable to connect. Please check your internet connection.
    </LockedBanner>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
  import { useNetwork } from '@vueuse/core';
  import { onBeforeMount, ref } from 'vue';

  import config, { session } from '@/config';
  import BrowserCheckModal from '@/shared/components/BrowserCheckModal.vue';
  import LockedBanner from '@/shared/components/LockedBanner.vue';
  import IconDanger from '@/shared/icons/svgs/danger.svg';
  import IconDisplay from '@/shared/icons/svgs/monitor.svg';
  import instrumentation from '@/shared/instrumentation';

  import { useModalManager } from '@/shared/modals/modalManager';

  import appFeatures from '@App/config/appFeatures';
  import { useFeature } from '@App/config/features';
  import appInstrumentationTypes from '@App/instrumentation/types';

  const { userAgent } = window.navigator;
  const feature = useFeature();
  const modalManager = useModalManager();

  // stolen from @datadog/browser-core
  const isChromium =
    ('chrome' in window && !!window.chrome) ||
    /HeadlessChrome/.test(userAgent) ||
    (session.isSynthetic && /Chrome\/\d/.test(userAgent)); // synthetics Chrome which doesn't have window.chrome
  const isEdge = userAgent.toLowerCase().includes('edg/');

  const isChrome = isChromium && !isEdge;

  // get chrome version
  const versionMatch = /Chrome\/(\d+)/.exec(userAgent);
  const chromeVersion = isChrome && versionMatch ? Number(versionMatch[1]) : 0;

  // note: we can't use `useRoute` in these checks as this component is mounted outside the RouterView
  const checkBrowser = () => {
    // Don't show for E2E tests
    if (session.isCypress) return '';

    // Don't show for labels / connotes
    if (/^\/document/.test(document.location.pathname)) return '';

    // Browser type check:
    if (!isChrome) return 'type';

    // Chrome version check
    if (chromeVersion < config.minChromeVersion) return 'version';

    // for testing
    if (feature.enabled(appFeatures.browserCheck).value) {
      return String(feature.config(appFeatures.browserCheck).value) as 'type' | 'version';
    }

    // All checks passed
    return '';
  };

  const browserCheck = ref<ReturnType<typeof checkBrowser>>('');

  onBeforeMount(() => {
    browserCheck.value = checkBrowser();

    if (browserCheck.value !== '') {
      modalManager.open(BrowserCheckModal, {
        prompt: browserCheck.value,
        onAccept: () => {
          instrumentation.event(appInstrumentationTypes.BROWSER_CHECK_DISMISSED, {
            reason: browserCheck.value,
          });
          browserCheck.value = '';
        },
      });

      instrumentation.event(appInstrumentationTypes.BROWSER_CHECK_SEEN, {
        reason: browserCheck.value,
      });
    }
  });

  const { isOnline } = useNetwork();
</script>

<style lang="scss">
  /* scoped styles don't apply well to LockedBanner due to Teleport */
  #width-warning {
    @media screen and (min-width: 1250px) {
      display: none;
    }
  }
</style>
