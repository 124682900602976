export const createDefaultState = () => ({
  user: {
    username: undefined,
    id: undefined,
  },
  redirectPath: null,
  unconfirmedUser: null,
  resetPasswordUsername: null,

  // MFA
  MFAVerificationRequired: false,

  // forgot password
  verificationCodeDetails: null,
  isPasswordResetComplete: false,
});
