<template>
  <div class="is-flex search-result" data-testref="global-search-consignment">
    <div class="is-flex-grow-0 is-flex-shrink-0 search-result-icon">
      <MovementFlowIcon :movement-flow="consignment.type" />
    </div>
    <div class="is-flex-grow-1">
      <div
        v-if="(consignment as SanitisedConsignmentSummary)._status === 'failed'"
        class="has-text-warning is-text-tag mb-2 has-text-right"
      >
        <SvgIcon :svg="ExclamationTriangleIcon" /> These details may be out of date.
      </div>
      <div class="is-flex is-justify-content-space-between pb-2">
        <div class="is-text-h2"><TextHighlight :text="consignment.consignmentNo" :highlight="searchStr" /></div>
        <div class="search-result-status">
          <ConsignmentStatus :consignment="consignment" :loading="consignmentIsLoading" />
        </div>
      </div>
      <div class="is-flex">
        <div
          v-if="consignment.type !== CONSIGNMENT_TYPES.TRANSFER"
          class="search-result-address-type is-flex-grow-0 is-flex-shrink-0"
        >
          {{ consignment.type === CONSIGNMENT_TYPES.INBOUND ? 'From' : 'To' }}
        </div>
        <template v-if="isHydratedConsignment(consignment)">
          <ResultAddressee
            v-if="consignment.type !== CONSIGNMENT_TYPES.OUTBOUND"
            :addressee="consignment.sender"
            :search-str="searchStr"
            :loading="consignmentIsLoading"
          />
          <div
            v-if="consignment.type === CONSIGNMENT_TYPES.TRANSFER"
            class="search-result-address-arrow is-align-self-center"
          >
            <SvgIcon :svg="ArrowRightIcon" />
          </div>
          <ResultAddressee
            v-if="consignment.type !== CONSIGNMENT_TYPES.INBOUND"
            :addressee="consignment.receiver"
            :search-str="searchStr"
            :loading="consignmentIsLoading"
          />
        </template>
      </div>
      <div class="is-flex search-result-details is-text-subhead2 pt-2">
        <div v-if="siteName">
          <span class="search-result-detail">Created at</span>
          <span>{{ siteName }}</span>
        </div>
        <div v-if="consignment.dispatchDate">
          <span class="search-result-detail">Dispatch date</span>
          <span>{{ formatDate(consignment.dispatchDate) }}</span>
        </div>
        <div v-if="consignment.carrier?.name">
          <span class="search-result-detail">Carrier</span>
          <span>{{ consignment.carrier.name }}</span>
        </div>
        <div v-if="consignment.references?.length">
          <span class="search-result-detail">Reference</span>
          <div class="search-result-references">
            <BaseTag v-for="ref in consignment.references" :key="ref" size="compact">
              <TextHighlight :text="ref" :highlight="searchStr" />
            </BaseTag>
          </div>
        </div>
      </div>
      <div
        v-if="isHydratedConsignment(consignment) && consignment.previousConsignmentNumbers?.length"
        class="search-result-details is-text-subhead2 pt-2"
      >
        <div class="search-result-previous-consignment-number">
          <span class="search-result-detail">Previously</span>
          <span
            ><MovementFlowIcon :movement-flow="consignment.type" /><TextHighlight
              :text="lastConnote(consignment)"
              :highlight="searchStr"
          /></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue';

  import BaseTag from '@/shared/components/BaseTag.vue';
  import { formatDate } from '@/shared/DateTime/formatters';
  import MovementFlowIcon from '@/shared/icons/MovementFlowIcon.vue';
  import SvgIcon from '@/shared/icons/SvgIcon.vue';
  import ArrowRightIcon from '@/shared/icons/svgs/arrow-right.svg';
  import ExclamationTriangleIcon from '@/shared/icons/svgs/warning.svg';
  import { ConsignmentSummary } from '@/shared/models';
  import { CONSIGNMENT_TYPES } from '@/shared/models/Consignment/Consignment';

  import TextHighlight from '@/shared/TextHighlight/TextHighlight.vue';

  import { useOrg } from '@App/context';
  import ConsignmentStatus from '@Consignment/components/ConsignmentStatus.vue';

  import { SanitisedConsignmentSummary } from '../behaviours/useRecentlyViewedConsignments';

  import ResultAddressee from './ResultAddressee.vue';

  const org = useOrg();

  const props = defineProps<{
    searchStr?: string;
    consignment: ConsignmentSummary | SanitisedConsignmentSummary;
    requiresUpdate?: boolean;
  }>();

  const consignmentIsLoading = computed(() => (props.consignment as SanitisedConsignmentSummary)._status === 'loading');

  const siteName = computed(() => org.value.sites[props.consignment.site.id]?.name);
  const lastConnote = (c: ConsignmentSummary) => c.previousConsignmentNumbers?.[0];

  function isHydratedConsignment(c: ConsignmentSummary | SanitisedConsignmentSummary): c is ConsignmentSummary {
    return !!('sender' in c);
  }
</script>

<style scoped lang="scss">
  .search-result {
    border-radius: 4px;
    padding: 12px;
    border: 1px solid transparent;
    transition:
      border-color 150ms ease,
      background-color 150ms ease;
    color: var(--copy-100);

    &:hover,
    .is-hovered & {
      text-decoration: none;
      background-color: var(--chrome-10);
      border-color: var(--chrome-50);
      .search-result-icon {
        color: var(--copy-100);
      }
    }
  }
  .search-result-icon {
    width: 32px;
    color: var(--copy-70);
    svg {
      width: 14px;
      height: 14px;
    }
  }
  .search-result-details {
    display: flex;
    flex-wrap: wrap;
    color: var(--copy-100);
    > div {
      display: flex;
      &:not(:last-child) {
        padding-right: 12px;
        margin-right: 12px;
        border-right: 1px solid var(--chrome-100);
      }
    }
    .search-result-detail {
      color: var(--copy-70);
      margin-right: 4px;
      white-space: nowrap;
    }
  }

  .search-result-address-type {
    width: 66px;
  }

  .search-result-address-arrow {
    color: var(--copy-30);
    padding: 0 24px;
    display: block;
  }

  .search-result-previous-consignment-number {
    svg {
      width: 11px;
      height: 11px;
      margin: 0 4px;
    }
  }

  // nabbed from _tables.scss
  :deep(.placeholder) {
    animation: fadeIn 0.5s ease-in;
    span {
      display: block;
      width: 100%;
      height: 1.4em; // [1]
      background-color: $chrome-50;
      animation: tableCellLoading 0.5s linear infinite alternate;
    }
  }
</style>
