export type PaginatedParams = {
  page?: number | string;
  pageSize?: number;
};

export function mapPaginatedParamsToQuery({ page, pageSize }: PaginatedParams) {
  const limit = Number(pageSize);
  return {
    limit,
    offset: (Number(page || 1) - 1) * limit,
  };
}
