/**
 * AppStore - action methods
 * @see https://vuex.vuejs.org/guide/actions.html
 */

import InvalidOrganisationError from '@/shared/services/org/errors/InvalidOrganisationError';

import Auth from '@Auth/store/types';
import Org from '@Org/store/types';

import { InternalTypes as App } from './types';

const AppActions = {
  [App.actions.confirmSession]: async ({ dispatch }) => dispatch(Auth.actions.confirmSession),

  [App.actions.checkExistingSession]: async ({ dispatch }) => {
    // Force a check on the current session
    await dispatch(Auth.actions.checkExistingSession);
    // This should update Auth.getters.user if the user's auth state has changed
  },

  [App.actions.fetchUserAccess]: async ({ getters, dispatch }) => {
    await dispatch(Org.actions.fetchUser);

    // No valid sites
    if (Object.keys(getters[Org.getters.sites]).length === 0) {
      throw new InvalidOrganisationError('User is not configured to access any sites');
    }
  },

  /**
   * If not already present in the state, or if explicitly requested with refresh, load as much detail as
   * possible on a specific site and store it in the state.
   */
  [App.actions.logout]: async ({ dispatch }) => {
    await Promise.all([
      // Ensure sub-modules clear their data
      dispatch(Org.actions.clearOrgData),
      dispatch(Auth.actions.logout),
      dispatch(App.actions.siteChanged, false),
    ]);
  },

  [App.actions.siteChanged]: ({ commit }, siteChanged) => {
    commit(App.mutations.SET_USER_CHANGED_SITE, { siteChanged });
  },
};

export default AppActions;
