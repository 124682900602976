/**
 * Meant to be used a base class when defining custom Errors in the app.
 */
export default class AppError extends Error {
  public name = 'AppError';

  /**
   * Create or wrap an Error that should be resolvable within the app.
   */
  constructor(message?: string, options?: Parameters<ErrorConstructor>[1]) {
    let userMessage = message;
    if (options?.cause instanceof Error && options.cause.message) userMessage = message;

    super(userMessage || 'Something went wrong', options);
  }
}
