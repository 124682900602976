export const DGInstrumentationTypes = {
  // DG Drawer
  DG_ADDED: 'dg added',
  DG_ABANDONED: 'dg abandoned',
  DG_GLOSSARY: 'dg glossary',
  DG_ADVANCED_FILTER: 'dg advanced filter',
  DG_CONSIGNMENT_EDIT: 'dg consignment edit',
  DG_VIEW_ICON: 'dg view icon',
  DG_PRESET_BULK_DELETE: 'dg preset bulk delete',
  DG_PRESET_IMPORT: 'dg preset import',
  DG_PRESET_EXPORT: 'dg preset export',
};

export default DGInstrumentationTypes;
