<script setup lang="ts">
  import { Component, computed } from 'vue';

  import SvgIcon from '@/shared/icons/SvgIcon.vue';

  const props = withDefaults(
    defineProps<{
      theme?: 'info' | 'danger' | 'warning';
      icon?: Component;
      sticky?: boolean;
    }>(),
    {
      theme: 'info',
      icon: undefined,
      sticky: false, // should 'danger' always be sticky?
    },
  );

  defineOptions({
    inheritAttrs: false,
  });

  if (!document.querySelector('#sticky-container')) {
    logger.warn('LockedBanner component requires a root-level #sticky-container element');
  }

  // TODO LockedBanner should support an action/button
  // TODO LockedBanner should support dismiss (conditionally)

  // position: sticky is ideal for this use case, but doesn't work if the parent container
  // isn't sticky. as a simple fix, a #sticky-container with position: sticky is a good way
  // to position these elements at the top of the page, not position them over other elements
  // when scrolled to the top, but sit over other elements when scrolled down.
  const teleportTo = computed(() => (props.sticky ? '#sticky-container' : '#modal-container'));
</script>

<template>
  <Teleport :to="teleportTo">
    <div class="locked-banner" :class="{ [`is-${theme}`]: true }" v-bind="$attrs">
      <SvgIcon v-if="icon" :svg="icon" size="small" />
      <slot name="default"></slot>
    </div>
  </Teleport>
</template>

<style scoped lang="scss">
  .locked-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;

    @each $name in 'info', 'danger', 'warning' {
      &.is-#{$name} {
        background-color: var(--#{$name}-100);
        color: white;
      }
    }
  }
</style>

<style lang="scss">
  #sticky-container {
    position: sticky;
    top: 0;
    z-index: 10;
  }
</style>
