import Locality from '@/shared/models/Locality';

export enum ADDRESS_FROM {
  estimate = 'estimate',
  suggestion = 'suggestion',
}

export interface AddressLike extends Locality {
  line1: string;
  line2?: string;
}

export interface Address extends AddressLike {
  id?: string;
  timeZone: string;
  from?: ADDRESS_FROM;
  gnafId?: string;
}

const newAddress = (): Address => ({
  line1: '',
  locality: '',
  postcode: '',
  subdivision: '',
  countryId: '',
  timeZone: '',
});

export const createAddress = (
  {
    id,
    line1,
    line2,
    locality,
    postcode,
    subdivision,
    countryId,
    timeZone,
    from,
    gnafId,
  }: Address | undefined = newAddress(),
): Address => ({
  id,
  line1,
  line2,
  locality,
  postcode,
  subdivision,
  countryId,
  timeZone,
  from,
  gnafId,
});

export default Address;
