<script setup lang="ts">
  import { computed } from 'vue';

  const props = withDefaults(
    defineProps<{
      fixed?: boolean;
    }>(),
    {
      fixed: false,
    },
  );

  const containerClasses = computed(() => ({
    [`is-${props.fixed ? 'fixed' : 'fluid'}`]: true,
  }));
</script>

<template>
  <div class="container" :class="containerClasses">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
  .container {
    padding-left: 0;
    padding-right: 0;

    // warning will appear at 1250, allow for 24px of padding
    min-width: 1202px;

    &.is-fixed {
      max-width: 1344px;
    }
  }
</style>
