export default class InvalidConsignmentSiteError extends Error {
  constructor(consignment, options) {
    super('Invalid consignment for this site.', options);

    this.consignment = consignment;

    // Maintains proper stack trace for where our error was thrown
    // Only works in v8 (node & Chrome)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}
