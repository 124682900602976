import DukeService from '@/shared/services/duke';

const types = {
  actions: {
    fetchQuoteById: 'fetchQuoteById',
    fetchQuoteSetByQuoteId: 'fetchQuoteSetByQuoteId',
    reset: 'reset',
    setQuote: 'setQuote',
    setQuoteSet: 'setQuoteSet',
  },
  getters: {
    getQuoteSet: 'getQuoteSet',
    getQuotes: 'getQuotes',
    getQuote: 'getQuote',
  },
  mutations: {
    RESET_QUOTES: 'RESET_QUOTES',
    RESET_QUOTE_SET: 'RESET_QUOTE_SET',
    SET_QUOTE_SET: 'SET_QUOTE_SET',
    ADD_QUOTE: 'ADD_QUOTE',
  },
};

const createDefaultState = () => ({
  quoteSet: {
    id: null,
    quoteIds: [],
  },
  quotes: {},
});

const getters = {
  [types.getters.getQuoteSet]: state => state.quoteSet,
  // TODO We might have other quotes loaded directly. We should make sure quotes here are coming from quoteSet
  [types.getters.getQuotes]: state => Object.values(state.quotes),
  [types.getters.getQuote]: (_, _getters) => quoteId =>
    _getters[types.getters.getQuotes].find(quote => quote.id === quoteId),
};

const actions = {
  [types.actions.fetchQuoteById]: async ({ commit }, { id }) => {
    const quote = await DukeService.fetchQuoteById(id);

    commit(types.mutations.ADD_QUOTE, quote);
  },

  [types.actions.setQuote]: ({ commit }, quote) => {
    commit(types.mutations.ADD_QUOTE, quote);
  },
  [types.actions.setQuoteSet]: ({ commit }, quoteSet) => {
    commit(types.mutations.SET_QUOTE_SET, quoteSet);
  },

  [types.actions.fetchQuoteSetByQuoteId]: async ({ commit }, { id }) => {
    try {
      const quote = await DukeService.fetchQuoteById(id);
      const quoteSet = await DukeService.fetchQuoteSetById(quote.quoteSetId);

      commit(types.mutations.SET_QUOTE_SET, quoteSet);
    } catch (error) {
      logger.error('Failed fetching quotes from quoteId', { error });
      // TODO handle error
      // commit(quoteTypes.mutations.SET_QUOTE_SET, { quoteSet, quotes });
    }
  },

  [types.actions.reset]: ({ commit }) => {
    commit(types.mutations.RESET_QUOTE_SET);
    commit(types.mutations.RESET_QUOTES);
  },
};

const mutations = {
  [types.mutations.ADD_QUOTE]: (state, quote) => {
    state.quotes[quote.id] = quote;
  },
  [types.mutations.RESET_QUOTES]: state => {
    state.quotes = {};
  },
  [types.mutations.RESET_QUOTE_SET]: state => {
    state.quoteSet.id = null;
    state.quoteSet.quoteIds = [];
  },
  [types.mutations.SET_QUOTE_SET]: (state, quoteSet) => {
    state.quoteSet.id = quoteSet.id;
    state.quoteSet.quoteIds = quoteSet.quotes.map(quote => quote.id);
    state.quotes = quoteSet.quotes.reduce((allQuotes, quote) => ({ ...allQuotes, [quote.id]: quote }), {});
  },
};

const QuotesStore = {
  namespaced: true,
  state: createDefaultState,
  getters,
  actions,
  mutations,
};

export { getters, actions, mutations, types as QuotesTypes };

export default QuotesStore;
