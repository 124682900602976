<template>
  <AppLayout theme="dark">
    <ResetPasswordWizard :initial-username="resetPasswordUsername">
      <template #instructions>
        <p>An administrator has requested a password reset</p>
        <p>An email has been sent with further instructions.</p>
      </template>
      <template #success>
        <p>Your password has been reset, you may now log in using the link below.</p>
      </template>
    </ResetPasswordWizard>
  </AppLayout>
</template>

<script>
  import { mapGetters } from 'vuex';

  import AppLayout from '@App/layout/views/AppLayout.vue';
  import Auth from '@Auth/store/types';

  import ResetPasswordWizard from '../components/ResetPasswordWizard.vue';

  export default {
    name: 'ResetPassword',
    components: {
      AppLayout,
      ResetPasswordWizard,
    },
    computed: {
      ...mapGetters({
        resetPasswordUsername: Auth.getters.getResetPasswordUsername,
      }),
    },
    created() {
      if (!this.resetPasswordUsername) {
        // redirect back to login
        this.$router.push({ name: 'auth.login' });
      }
    },
  };
</script>
