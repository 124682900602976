import { getConfig } from '@/shared/init';

import ErrorMonitoring from './ErrorMonitoring';

const geppettoConfig = getConfig();

if (!geppettoConfig.rollbar) {
  throw new Error('error handling config must be provided to initGeppettoShared');
}

const errorMonitoring = new ErrorMonitoring({
  enabled: !!geppettoConfig.rollbar.enabled,
  accessToken: geppettoConfig.rollbar.accessToken,
  environment: geppettoConfig.environment,
  version: geppettoConfig.version,
  ignore3rdPartyErrors: geppettoConfig.environment === 'prod',
  ignoreErrorsCausedBy: geppettoConfig.errorMonitoring?.ignoreErrorsCausedBy,
});

export default errorMonitoring;
