import PollyTimeoutError from './PollyTimeoutError';

interface PollyConfig<T> {
  timeout?: null | number;
  timeStart?: number;
  backoff?: number;
  // set as null for infinite retries
  retries?: null | number;
  onErrorRetryCondition?: (error: Error) => boolean;
  retryCondition?: (response: T) => boolean;
}

async function polly<T = unknown>(
  asyncCall: () => Promise<T>,
  {
    timeout = 30000,
    timeStart = Date.now(),
    backoff = 150,
    retries = null,
    onErrorRetryCondition,
    retryCondition = (response: T) =>
      typeof response === 'object' && response !== null && 'status' in response
        ? [201, 202, 204].includes(response.status as number)
        : false,
  }: PollyConfig<T> = {} as PollyConfig<T>,
): Promise<T> {
  let response: T;
  let errorRetry = false;
  try {
    response = await asyncCall();
  } catch (error) {
    if (!(error instanceof Error)) throw error;
    errorRetry = !!onErrorRetryCondition && onErrorRetryCondition(error);
    if (!errorRetry) throw error;
  }

  return new Promise<T>((res, rej) => {
    if (response && !retryCondition(response)) res(response);

    if ((retries === null || retries > 0) && (retryCondition(response) || errorRetry)) {
      setTimeout(() => {
        const timeElapsed = Date.now() - timeStart;

        if (timeout === null || timeElapsed < timeout) {
          logger.debug(
            '[Polly] service call retry',
            { retries, timeStart, timeElapsed, timeout, errorRetry },
            { response },
          );
          res(
            polly<T>(asyncCall, {
              timeout,
              retryCondition,
              onErrorRetryCondition,
              timeStart,
              backoff: Math.min(backoff * 2, 5000), // cap interval at 5s to avoid 30+s delays
              retries: retries !== null ? retries - 1 : null,
            }),
          );
        } else {
          rej(new PollyTimeoutError<T>('Timeout', response));
        }
      }, backoff);
    } else {
      rej(new PollyTimeoutError<T>('MaxRetries', response));
    }
  });
}

export { polly as default, PollyTimeoutError };
