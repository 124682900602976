// Cache helper for storing locally values against a key

export interface LocalCache<T> {
  get: (key: string, resolve: () => T) => T;
  getAsync: (key: string, resolve: () => Promise<T>) => Promise<T>;
}

function cacheFactory<T>(): LocalCache<T> {
  const _cache: Map<string, T | Promise<T>> = new Map();

  return {
    get(key: string, resolve: () => T): T {
      const value = _cache.get(key);
      if (value !== undefined) return value as T;

      const resolvedValue = resolve();
      _cache.set(key, resolvedValue);
      return resolvedValue;
    },

    async getAsync(key: string, resolve: () => Promise<T>): Promise<T> {
      const value = _cache.get(key);
      if (value !== undefined) return value;
      const promise = resolve();
      _cache.set(key, promise);
      try {
        return await promise;
      } catch (e) {
        // remove pending promises if resolve failed
        _cache.delete(key);
        throw e;
      }
    },
  };
}

const localCache = {
  create<T>() {
    return cacheFactory<T>();
  },
};

export default localCache;
