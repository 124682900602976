import cloneDeep from 'lodash/cloneDeep';

import InputFocusMixin from '@/shared/mixins/InputFocusMixin';
import InputValidateOnMixin from '@/shared/mixins/InputValidateOnMixin';
import InputValidationMixin from '@/shared/validation/SingleValidationMixin';

export const DEFAULT_INPUT_PROPS = {
  icon: { type: String, default: null },
  label: { type: String, default: null },
  subLabel: { type: String, default: null },
  hint: { type: String, default: null },
  infoHelp: { type: String, default: null },
  loading: { type: Boolean, default: false },
  optional: { type: Boolean, default: false },
};

export default {
  mixins: [InputValidationMixin, InputFocusMixin, InputValidateOnMixin],
  props: {
    ...cloneDeep(DEFAULT_INPUT_PROPS),
  },
};
