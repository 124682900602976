import { asyncImport } from '@App/router/helpers';

const PageRoutes = [
  {
    path: '/privacy-policy',
    component: asyncImport(() => import('@Legal/views/PrivacyPolicyPage.vue')),
    name: 'page.privacy-policy',
    meta: {
      requiresAuthentication: false,
      title: 'Privacy Policy',
      enableFullStory: true,
    },
  },
  {
    path: '/terms-of-use',
    component: asyncImport(() => import('@Legal/views/TermsOfUsePage.vue')),
    name: 'page.terms-of-use',
    meta: {
      requiresAuthentication: false,
      title: 'Terms of Use',
      enableFullStory: true,
    },
  },
];

export default PageRoutes;
