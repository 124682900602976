import { ValidationResult } from 'veritas';

type ExtractedMessages = Record<string, string>;

export default function messageExtractor(validationObj: ValidationResult) {
  if (!validationObj.$errors) return {};

  const errors = validationObj.$errors
    // display only direct error messages
    .filter(error => error.$propertyPath === validationObj.$path)
    .reduce((current, error) => ({ ...current, [error.$validator]: error }), {});
  return Object.keys(errors)
    .filter(validator => validationObj[validator])
    .reduce((acc, validationKey) => {
      const error = errors[validationKey];
      acc[validationKey] = error.$message || 'invalid field';
      return acc;
    }, {} as ExtractedMessages);
}
