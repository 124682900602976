import { Temporal } from '@js-temporal/polyfill';

import Destination from '../Destination';

export interface Evaluation {
  unsupportedPickupLocation: Destination | false;
  unsupportedDestinations: Destination[];
  /** @deprecated */
  unsupportedPackagingType: boolean;
  unsupportedPackagingTypes: string[];
  afterBookingCutoff: boolean;
  afterPickupCutoff: boolean;
  unavailablePricing: { from: Destination; to: Destination }[];
  integrationNotConfigured: boolean;
  dangerousGoods?: {
    carrierAuthorityDenied?: boolean;
    notAllowedByCarrier?: boolean;
  };
}

interface PickupServiceOption {
  agreedServiceId: UUID;
  serviceId?: UUID;
  carrierId?: UUID;
  pickupBookingCutoff?: Temporal.Instant;
  pickupCutoff?: Temporal.Instant;
  pickupWindowMinimum?: Temporal.Duration;
  evaluation: Evaluation;
  selectable: boolean;
  recommended: boolean;
}

export const createPickupServiceOption = ({
  agreedServiceId,
  serviceId,
  carrierId,
  pickupBookingCutoff,
  pickupCutoff,
  pickupWindowMinimum,
  evaluation,
  selectable,
  recommended,
}: PickupServiceOption): PickupServiceOption => ({
  agreedServiceId,
  serviceId,
  carrierId,
  pickupBookingCutoff,
  pickupCutoff,
  pickupWindowMinimum,
  evaluation,
  selectable,
  recommended,
});

export default PickupServiceOption;
