import { PrintClientStatus, PrintConfig } from '@/shared/models';

function getAutoprintDefaultSelection(printConfig?: PrintConfig, printClientStatus?: PrintClientStatus) {
  const clientRunning = printClientStatus === PrintClientStatus.running;
  if (!clientRunning || !printConfig?.enabled) {
    return {
      labels: false,
      connote: false,
    };
  }

  return {
    labels: !!printConfig.defaultLabelPrinter.id && printConfig.defaultLabelPrinter.enabled,
    connote: !!printConfig.defaultConnotePrinter.id && printConfig.defaultConnotePrinter.enabled,
  };
}
export default getAutoprintDefaultSelection;
