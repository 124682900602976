import { Temporal } from '@js-temporal/polyfill';

import { temporalInstantFromDate, temporalInstantToDate } from '@/shared/DateTime/mappers';

export const INTERVAL_REGEX = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?)\/(P[0-9A-Z]+)$/;

export default class Interval {
  private _start: Temporal.Instant;

  private _duration: Temporal.Duration;

  public get start(): Temporal.Instant {
    return this._start;
  }

  public get duration(): Temporal.Duration {
    return this._duration;
  }

  public get end(): Temporal.Instant {
    return new Temporal.Instant(this._start.add(this._duration).epochNanoseconds);
  }

  constructor(start: Temporal.Instant, duration: Temporal.Duration) {
    this._start = start;
    this._duration = duration;
  }

  public static from(
    item:
      | string
      | {
          start: Date | Temporal.Instant | string;
          duration?: Temporal.Duration | string;
          end?: Date | Temporal.Instant | string;
        },
  ): Interval {
    let start: Temporal.Instant;
    if (typeof item === 'string') return this.fromString(item);
    if (item.start instanceof Date) start = temporalInstantFromDate(item.start);
    else start = Temporal.Instant.from(item.start);
    if (item.duration) {
      const duration = Temporal.Duration.from(item.duration);
      return new Interval(start, duration);
    }
    if (item.end) {
      let end: Temporal.Instant;
      if (item.end instanceof Date) end = temporalInstantFromDate(item.end);
      else end = Temporal.Instant.from(item.end);
      const duration = end.since(start);
      return new Interval(start, duration);
    }
    throw new Error('PlainTimeRange.from requires either an end or a duration');
  }

  // format is:
  // 2022-02-01T03:04:05Z/PT1H
  // 2022-02-01T03:04:05+00:00/PT5H
  private static fromString(item: string): Interval {
    const match = INTERVAL_REGEX.exec(item);
    if (!match) throw new Error(`Invalid timeRange input string: ${item}`);

    const start = Temporal.Instant.from(match[1]);
    const duration = Temporal.Duration.from(match[2]);
    return new Interval(start, duration);
  }

  public static now(duration: string | Temporal.Duration): Interval {
    return this.from({ start: Temporal.Now.instant(), duration });
  }

  public toString(): string {
    const startString = this._start.toString();
    const durationString = this._duration.toString();
    return `${startString}/${durationString}`;
  }

  public isValid(): boolean {
    return this._duration.sign !== -1; // make sure end is after start.
  }

  public static day(date: Date | Temporal.PlainDate | Temporal.Instant | string): Interval {
    let startDate: Date;
    if (date instanceof Date || typeof date === 'string') startDate = new Date(date);
    else if (date instanceof Temporal.Instant) startDate = temporalInstantToDate(date);
    else throw new Error(`Unable to get day from ${date}`);

    // round to start of day
    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const start = temporalInstantFromDate(startDate);

    // round to end of day
    const endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59, 59, 999);
    const end = temporalInstantFromDate(endDate);

    return Interval.from({ start, end });
  }

  public static today(): Interval {
    return Interval.day(new Date());
  }
}
