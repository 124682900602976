/**
 * Carrier selection (aka DUKE) backend api methods
 */
import { Quote, QuoteSet } from '@/shared/models';
import APIClient, { ApiClientRequestConfig } from '@/shared/services/api-client';
import { GeppettoJSONApiResponse } from '@/shared/services/api-client/types';
import { QuoteMapper, QuoteSetMapper } from '@/shared/services/duke/mappers';
import { DukeApiQuote, DukeApiQuoteSet } from '@/shared/services/duke/types/DukeApiQuote';

if (!import.meta.env.GEPPETTO_SERVICE_DUKE_URL) {
  throw new Error('GEPPETTO .env not correctly configured');
}

const baseUrl = import.meta.env.GEPPETTO_SERVICE_DUKE_URL;

class DukeService {
  private _api!: APIClient;

  constructor() {
    this._api = new APIClient(baseUrl);
  }

  async fetchQuoteSetById(quoteSetId: UUID, config?: ApiClientRequestConfig): Promise<QuoteSet> {
    const quoteSetResponse = await this._api.get<GeppettoJSONApiResponse<DukeApiQuoteSet>>(
      `/quote-sets/${quoteSetId}`,
      config,
    );
    return QuoteSetMapper.fromApi(quoteSetResponse.data);
  }

  async fetchQuoteById(quoteId: UUID, config?: ApiClientRequestConfig): Promise<Quote> {
    const { data } = await this._api.get<GeppettoJSONApiResponse<DukeApiQuote>>(`/quotes/${quoteId}`, config);
    const quote = data.data;
    return QuoteMapper.fromApi(quote.attributes);
  }
}

export default new DukeService();
