import errorStore from './errorStore';
import { errorIsDisplayed } from './helpers';

/**
 * Adds an error to the specified error scope to be displayed, if it hasn't been displayed
 * already.
 * @param error the error to display
 * @param scopeName {string} the name of the error scope where the error should be displayed
 * @param checkDisplayed {boolean} whether or not to display the error if it has already been displayed
 */
export default function displayError(error: Error, scopeName: string, checkDisplayed: boolean = true) {
  if (checkDisplayed && errorIsDisplayed(error)) {
    return;
  }

  errorStore.addError(scopeName, error);
  error._displayed = true;
}
