/**
 * AppStore - mutation methods
 * @see https://vuex.vuejs.org/guide/mutations.html
 */
import { InternalTypes as App } from './types';

const AppMutations = {
  [App.mutations.SET_USER_CHANGED_SITE](state, { siteChanged }) {
    state.userHasChangedSite = siteChanged;
  },
};

export default AppMutations;
