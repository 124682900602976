import { mapValues, shake } from 'radash';

const isEmptyParam = (paramValue: unknown) =>
  paramValue === undefined ||
  paramValue === null ||
  paramValue === '' ||
  (Array.isArray(paramValue) && paramValue.length === 0);

export const formatParams = (params: Record<string, unknown>) => {
  const paramsWithValues: Record<string, unknown> = shake(params, value => isEmptyParam(value));
  return mapValues(paramsWithValues, val => (Array.isArray(val) ? val.join(',') : val));
};
