import { type ResourcesConfig } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

import { AmplifyAuthStorage } from './AmplifyAuthStorage';
import { CognitoAuthProvider } from './cognito.provider';

if (!import.meta.env.COGNITO_USER_POOL_ID || !import.meta.env.COGNITO_CLIENT_ID) {
  throw new Error('GEPPETTO .env not correctly configured');
}

const authConfig: ResourcesConfig['Auth'] = {
  Cognito: {
    userPoolId: import.meta.env.COGNITO_USER_POOL_ID,
    userPoolClientId: import.meta.env.COGNITO_CLIENT_ID,
  },
};

// auth provider must be instantiated before the storage provider
const AuthService = new CognitoAuthProvider(authConfig);

cognitoUserPoolsTokenProvider.setKeyValueStorage(
  new AmplifyAuthStorage({
    authDomain: import.meta.env.AUTH_DOMAIN || '',
    cookieName: import.meta.env.AUTH_COOKIE_NAME || 'authorization',
  }),
);

export default AuthService;
