import { useStore } from 'vuex';

import actionSubscriber from './actionSubscriber';
import module from './storeModule';

export const getDefaultOptions = options => ({ moduleName: 'vuex-async-loader', ...options });

const install = (Vue, userOptions) => {
  const options = getDefaultOptions(userOptions);

  if (!options || !options.store) {
    throw new Error('Please initialise plugin with a Vuex store.');
  }

  options.store.registerModule(options.moduleName, module);
  options.store.subscribeAction(actionSubscriber(options));
};

export const createLoader = id =>
  function loader() {
    if (this.$store.hasModule('vuex-async-loader')) {
      return this.$store.getters['vuex-async-loader/isLoading'](id);
    }

    return false;
  };

export const createNamespacedLoader = (namespace, id) => createLoader(`${namespace}/${id}`);

export function useAsyncLoader(namespace = '') {
  const store = useStore();
  const asyncCreateLoader = id => {
    const loaderName = namespace ? `${namespace}/${id}` : id;

    return store.hasModule('vuex-async-loader') ? store.getters['vuex-async-loader/isLoading'](loaderName) : false;
  };

  return { createLoader: asyncCreateLoader };
}

const vuexAsyncLoading = {
  install,
};

export default vuexAsyncLoading;
