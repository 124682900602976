<template>
  <SvgIcon :svg="icon" />
</template>
<script setup lang="ts">
  import { computed } from 'vue';

  import SvgIcon from '@/shared/icons/SvgIcon.vue';
  import TransferIcon from '@/shared/icons/svgs/data-transfer-horizontal.svg';
  import InboundIcon from '@/shared/icons/svgs/inbound.svg';
  import OutboundIcon from '@/shared/icons/svgs/outbound.svg';
  import { MovementFlowType } from '@/shared/models';

  const props = defineProps<{
    movementFlow: MovementFlowType;
  }>();

  const icon = computed(() => {
    if (props.movementFlow === 'outbound') return OutboundIcon;
    if (props.movementFlow === 'inbound') return InboundIcon;
    if (props.movementFlow === 'transfer') return TransferIcon;
    return undefined;
  });
</script>
