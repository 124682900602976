import { asyncImport, withContextPrefix } from '@App/router/helpers';

const OrderRoutes = [
  ...withContextPrefix(
    {
      path: 'order',
      meta: {
        requiresAuthentication: true,
      },
    },
    [
      {
        path: 'manage',
        name: 'order.manage',
        component: asyncImport(() => import('@Order/views/OrderManage.vue')),
        meta: {
          title: 'Orders',
          enableFullStory: true,
          context: {
            allowed: ['site', 'org'],
          },
        },
      },
    ],
  ),
];

export default OrderRoutes;
