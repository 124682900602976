<template>
  <PageContainer fixed>
    <ErrorBoundary v-bind="errorBoundary">
      <template #fallback="{ criticalError }">
        <ErrorFallback :error="criticalError" :error-pages="errorPages" />
      </template>

      <div v-if="action === 'confirm'">
        <div v-if="isActioningPickup">
          <CarrierPickupStatusCard :status-icon="IconStatusSubmitting" title="Confirming pickup request" />
        </div>
        <div v-else-if="pickupActionSuccess">
          <CarrierPickupConfirm :token="token" />
        </div>
        <div v-else>
          <CarrierPickupStatusCard :status-icon="IconStatusFailed" title="Response change failed">
            <template #footer>
              <CarrierPickupChangeResponseFooter />
            </template>
          </CarrierPickupStatusCard>
        </div>
      </div>

      <div v-else-if="action === 'reject'">
        <div v-if="isActioningPickup">
          <CarrierPickupStatusCard :status-icon="IconStatusSubmitting" title="Rejecting pickup request" />
        </div>
        <div v-else-if="pickupActionSuccess">
          <CarrierPickupReject :token="token" />
        </div>
        <div v-else>
          <CarrierPickupStatusCard :status-icon="IconStatusFailed" title="Response change failed">
            <template #footer>
              <CarrierPickupChangeResponseFooter />
            </template>
          </CarrierPickupStatusCard>
        </div>
      </div>
    </ErrorBoundary>
  </PageContainer>
</template>

<script setup>
  import { h, onMounted, ref } from 'vue';

  import ErrorFallback from '@/app/ErrorFallback.vue';
  import PageContainer from '@/shared/components/layout/PageContainer.vue';
  import { ErrorBoundary, useErrorBoundary } from '@/shared/errorHandling';
  import SvgIcon from '@/shared/icons/SvgIcon.vue';
  import IconFailed from '@/shared/icons/svgs/failed.svg';
  import IconSubmitting from '@/shared/icons/svgs/submitting.svg';

  import criticalBoundary from '@App/errorHandling/criticalBoundary';

  import CarrierPickupClient from '../apiClient/carrierPickupClient';
  import InvalidOrExpiredCarrierPickupTokenError from '../apiClient/errors/InvalidOrExpiredCarrierPickupTokenError';
  import CarrierPickupChangeResponseFooter from '../components/CarrierPickupChangeResponseFooter.vue';
  import CarrierPickupStatusCard from '../components/CarrierPickupStatusCard.vue';
  import { PICKUP_ACTIONS } from '../consts';
  import { PICKUP_ACTION_FAIL_REASONS } from '../models';

  import CarrierPickupConfirm from './CarrierPickupConfirm.vue';
  import CarrierPickupReject from './CarrierPickupReject.vue';
  import InvalidTokenErrorPage from './InvalidTokenErrorPage.vue';

  const pickupActionSuccess = ref(false);
  const isActioningPickup = ref(false);

  const props = defineProps({
    token: { type: String, required: true },
    action: { type: String, required: true, validator: action => Object.values(PICKUP_ACTIONS).includes(action) },
  });

  const IconStatusFailed = h(SvgIcon, { style: 'color: var(--danger-100);', svg: IconFailed });
  const IconStatusSubmitting = h(SvgIcon, { style: 'color: var(--info-100);', svg: IconSubmitting });

  async function confirm(token, reference) {
    const response = await CarrierPickupClient.confirm({ token, confirmationReference: reference });

    pickupActionSuccess.value = response.success || response.failReason !== PICKUP_ACTION_FAIL_REASONS.conflict;
  }

  async function reject(token, reason) {
    const response = await CarrierPickupClient.reject({ token, rejectReason: reason });

    pickupActionSuccess.value = response.success || response.failReason !== PICKUP_ACTION_FAIL_REASONS.conflict;
  }

  const errorPages = [
    {
      check: error => error.constructor === InvalidOrExpiredCarrierPickupTokenError,
      component: () => InvalidTokenErrorPage,
    },
  ];

  const { errorBoundary } = useErrorBoundary({
    name: 'error:carrier-pickup',
    onErrorCaptured: ({ error }, { markAsHandled, markAsCritical }) => {
      if (error instanceof InvalidOrExpiredCarrierPickupTokenError) {
        markAsHandled(error);
      }

      const recoverableErrors = [];
      if (criticalBoundary.shouldSetCritical(error, recoverableErrors)) {
        markAsCritical(error);
      }
    },
  });

  onMounted(async () => {
    // action request
    try {
      isActioningPickup.value = true;
      if (props.action === 'confirm') {
        await confirm(props.token);
      } else if (props.action === 'reject') {
        await reject(props.token);
      } else {
        throw new Error('invalid action');
      }
    } finally {
      isActioningPickup.value = false;
    }
  });
</script>

<style lang="scss" scoped>
  .card-footer {
    color: var(--copy-70);
  }
</style>
