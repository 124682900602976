import { ApiClientError } from '@/shared/services/api-client';

export default class MainResourceError extends ApiClientError {
  constructor(error, resourceType = 'Resource') {
    super(error);
    this.message = 'There was a problem loading a critical resource';
    this.resourceType = resourceType;

    // used for errorHandling. This error should always trigger a critical error
    this._critical = true;
  }
}
