import instrumentation from '@/shared/instrumentation';
import userMonitor from '@App/instrumentation/userMonitor';

export const formEvents = {
  formSectionTransition: 'form section transitioned',
};

type FormSectionTransitionEventParams = {
  title: string;
  inputType?: 'keypress' | 'click' | 'other';
  transition: 'edit' | 'display';
};

instrumentation.on(
  formEvents.formSectionTransition,
  ({ title, inputType = 'other', transition }: FormSectionTransitionEventParams) => {
    // Not currently interested in logging display transitions
    if (transition !== 'edit') return;

    userMonitor.event('form section transitioned', {
      interaction: {
        // @interaction.section (Facet)
        section: title.toLowerCase(),
        // @interaction.method (Facet)
        method: inputType,
        // @interaction.transition (Facet)
        transition,
      },
    });
  },
);
