import DeliveryTimeSlot, { deliveryTimeSlotFactory, NewDeliveryTimeSlot } from '@/shared/models/DeliveryTimeSlot';

import { components } from '../../services/schema/geppetto-sender-app/consignments.schema';

import { createAddress } from '../Address';
import DeliveryInstructions from '../DeliveryInstructions';
import Location from '../Location';

export type ConsignmentAddress = components['schemas']['Address'] | components['schemas']['UnverifiedAddress'];

export type ConsignmentAddressee = Omit<Location, 'address'> & {
  address: ConsignmentAddress;
  contactEmail?: string;
  contactName?: string;
  contactPhone?: string;
  addressBookContactId?: UUID;
  addressBookEntryId?: UUID;
};

export type ConsignmentSender = ConsignmentAddressee;

export interface ConsignmentReceiver extends ConsignmentAddressee, DeliveryInstructions {
  deliveryTimeSlot?: DeliveryTimeSlot;
  deliveryReferences: string[];
  preventConsolidation: boolean;
}

export type NewConsignmentReceiver = ConsignmentAddressee &
  DeliveryInstructions & {
    deliveryTimeSlot?: NewDeliveryTimeSlot;
    preventConsolidation: boolean;
  };

export function isVerifiedAddress(address: Partial<ConsignmentAddress>): address is components['schemas']['Address'] {
  return !!(address as components['schemas']['Address'])?.id;
}

export function newConsignmentAddressee(isDestination: boolean): ConsignmentSender | NewConsignmentReceiver {
  return {
    name: '',
    line2: '',
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    residential: false,
    address: createAddress(),
    ...(isDestination ? { authorityToLeave: false, specialInstructions: '' } : {}),
  };
}

export const consignmentAddresseeFactory = {
  createSender: ({
    contactEmail,
    contactName,
    contactPhone,
    address,
    addressId,
    name,
    residential,
  }: ConsignmentSender): ConsignmentSender => ({
    addressId,
    address,
    name,
    residential,
    contactName,
    contactEmail,
    contactPhone,
  }),

  createReceiver: ({
    contactEmail,
    contactName,
    contactPhone,
    authorityToLeave,
    deliveryTimeSlot,
    deliveryReferences,
    preventConsolidation,
    address,
    addressId,
    name,
    residential,
  }: ConsignmentReceiver): ConsignmentReceiver => ({
    address,
    addressId,
    name,
    residential,
    contactName,
    contactEmail,
    contactPhone,
    authorityToLeave,
    deliveryTimeSlot,
    deliveryReferences,
    preventConsolidation,
  }),
  createNewReceiver: () => ({
    authorityToLeave: false,
    // TODO remove when consignmentAddress is typed. let this override authorityToLeave for now.
    ...newConsignmentAddressee(true),
    preventConsolidation: false,
    deliveryTimeSlot: deliveryTimeSlotFactory.createNew(),
  }),
  createNewSender: () => newConsignmentAddressee(false),
};
