<script setup lang="ts">
  import { computed } from 'vue';

  import { basicControl, BasicControlProps } from '@/shared/components/controls/helpers/controlBehaviour';

  // Since we use v-bind="$attr" on the checkbox, we get the benefit of Vue's native checkbox behaviour, including:
  // true-value (for single checkboxes to emit non-boolean values)
  // false-value
  // @see https://v3.vuejs.org/guide/forms.html#checkbox-2

  defineOptions({
    inheritAttrs: false,
  });

  const props = defineProps<
    BasicControlProps & {
      disabled?: boolean;
      verticalAlign?: 'top' | 'center' | 'bottom';
      modelValue?: unknown;
      value?: unknown;
    }
  >();

  const emit = defineEmits<{
    'update:modelValue': [unknown];
    click: [Event];
  }>();

  const { inputEl, focus, select, vSensitive } = basicControl(props);

  const computedValue = computed({
    get() {
      return props.modelValue;
    },
    set(value) {
      emit('update:modelValue', value);
    },
  });

  defineExpose({
    focus,
    select,
  });
</script>

<template>
  <label
    class="radio"
    :class="{
      [`vertical-align-${verticalAlign || 'center'}`]: true,
    }"
    :data-testref="dataTestref"
    @click="emit('click', $event)"
  >
    <input
      ref="inputEl"
      v-model="computedValue"
      v-sensitive
      :disabled="disabled"
      :value="value"
      type="radio"
      v-bind="$attrs"
    />
    <span
      v-sensitive
      class="check"
      :class="{
        'is-danger': hasError,
      }"
    ></span>
    <span v-if="!!$slots.default" v-sensitive class="control-label">
      <slot></slot>
    </span>
  </label>
</template>

<style lang="scss" scoped>
  $radio-active-background-color: $primary !default;
  $radio-size: 1.25em !default;

  .radio {
    @extend %unselectable;
    outline: none;
    display: inline-flex;
    position: relative;

    &.vertical-align-top {
      align-items: flex-start;
    }
    &.vertical-align-center {
      align-items: center;
    }
    &.vertical-align-bottom {
      align-items: flex-end;
    }

    &:not(.button) {
      margin-right: 0.5em;
      & + .radio:last-child {
        margin-right: 0;
      }
    }
    // reset Bulma
    & + .radio {
      margin-left: 0;
    }
    input[type='radio'] {
      position: absolute;
      left: 0;
      opacity: 0;
      outline: none;
      z-index: -1;
      + .check {
        display: flex;
        flex-shrink: 0;
        position: relative;
        cursor: pointer;
        width: $radio-size;
        height: $radio-size;
        transition: background $speed-slow $easing;
        border-radius: 50%;
        border: 2px solid var(--chrome-100);
        &:before {
          content: '';
          display: flex;
          position: absolute;
          left: 50%;
          margin-left: calc(-#{$radio-size}/ 2);
          bottom: 50%;
          margin-bottom: calc(-#{$radio-size}/ 2);
          width: $radio-size;
          height: $radio-size;
          transition: transform $speed-slow $easing;
          border-radius: 50%;
          transform: scale(0);
          background-color: var(--brand-100);
        }
        @each $name, $pair in $colors {
          $color: nth($pair, 1);
          &.is-#{$name} {
            border-color: $color;
            &:before {
              background: $color;
            }
          }
        }
      }
      &:checked + .check {
        border-color: var(--brand-100);
        @each $name, $pair in $colors {
          $color: nth($pair, 1);
          &.is-#{$name} {
            border-color: $color;
          }
        }
        &:before {
          transform: scale(0.5);
        }
      }
      &:focus {
        + .check {
          box-shadow: 0 0 0.5em rgba($copy-50, 0.5);
        }
        &:checked + .check {
          box-shadow: 0 0 0.5em rgba($radio-active-background-color, 0.8);
          @each $name, $pair in $colors {
            $color: nth($pair, 1);
            &.is-#{$name} {
              box-shadow: 0 0 0.5em rgba($color, 0.8);
            }
          }
        }
      }
    }
    .control-label {
      padding-left: $control-padding-horizontal;
    }
    &.button {
      display: flex;
    }
    &:has(> input[type='radio']:disabled) {
      color: var(--copy-30);
      input[type='radio'] + .check {
        border-color: var(--chrome-20);
        background-color: var(--chrome-10);
        &::before {
          background-color: var(--copy-30);
        }
      }
    }
    &:hover {
      input[type='radio']:not(:disabled) + .check {
        border-color: $radio-active-background-color;
        border-color: var(--brand-100);
        @each $name, $pair in $colors {
          $color: nth($pair, 1);
          &.is-#{$name} {
            border-color: $color;
          }
        }
      }
    }
  }
</style>
