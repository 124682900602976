import type { App, Component, InjectionKey } from 'vue';

import { h } from 'vue';
import { useRouter } from 'vue-router';

import { UnhandleableError } from './errors';
import { isUnhandleableError } from './helpers';

export type ErrorDisplayHandler = {
  check: (error: Error) => boolean;
  component: (error: Error) => Component;
};

export type ErrorHandlingConfig = {
  errorDisplayHandlers: ErrorDisplayHandler[];
  fallbackComponent: Component;
};

export const contextInjectionKeyConfig = Symbol('errorHandling:config') as InjectionKey<ErrorHandlingConfig>;
export const contextInjectionKeyDisplayHandlers = Symbol('errorHandling:displayHandlers') as InjectionKey<
  ErrorDisplayHandler[]
>;

const unhandleableErrorDisplayHandler: ErrorDisplayHandler = {
  check: error => error instanceof UnhandleableError || isUnhandleableError(error),
  component: error => ({
    setup() {
      const router = useRouter();
      return () =>
        h('p', [
          isUnhandleableError(error) || !error.message ? 'Something went wrong. ' : `${error.message}. `,
          h('a', { onClick: () => (router ? router.go(0) : document.location.reload()) }, 'Try refreshing the page'),
        ]);
    },
  }),
};
const defaultErrorDisplayHandler: ErrorDisplayHandler = {
  check: () => true, // left at the end, this will match all unmatched errors
  component: error => ({
    setup() {
      logger.warn('errorHandling could not find a display handler', { error });
      return () => h('p', 'Something went wrong with the app. Our engineering team has been notified.');
    },
  }),
};

const defaultFallbackComponent: Component = {
  setup() {
    return () => h('div', 'Something went wrong');
  },
};

export default function configureErrorHandling(app: App, appConfig: Partial<ErrorHandlingConfig> = {}) {
  const { errorDisplayHandlers = [], fallbackComponent = defaultFallbackComponent } = appConfig;

  const config: ErrorHandlingConfig = {
    errorDisplayHandlers: [
      ...errorDisplayHandlers,
      unhandleableErrorDisplayHandler, // default handling for syntax errors, etc
      defaultErrorDisplayHandler, // catch all if we can't find a better handler
    ],
    fallbackComponent,
  };

  app.provide(contextInjectionKeyConfig, config);
}
