interface DeliveryInstructions {
  specialInstructions?: string;
  authorityToLeave: boolean;
}

const newDeliveryInstructions = (): DeliveryInstructions => ({
  specialInstructions: '',
  authorityToLeave: false,
});

export const createDeliveryInstructions = (
  { specialInstructions, authorityToLeave }: DeliveryInstructions | undefined = newDeliveryInstructions(),
): DeliveryInstructions => ({
  specialInstructions,
  authorityToLeave,
});

export const createDeliveryInstructionsFromAddressObject = createDeliveryInstructions;

export default DeliveryInstructions;
