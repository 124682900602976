export enum PrintClientStatus {
  running = 'running',
  stopped = 'stopped',
  noActiveClient = 'noActiveClient',
  notInstalled = 'notInstalled',
  unavailable = 'unavailable',
}

export interface PrintClientDetails {
  status: PrintClientStatus;
  version?: string;
  machineName?: string;
}
