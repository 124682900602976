import uniqBy from 'lodash/uniqBy';

import instrumentation from '@/shared/instrumentation';

import userMonitor from '@App/instrumentation/userMonitor';

import Org from '@Org/store/types';

instrumentation.on(Org.actions.fetchUser, ({ store }) => {
  const user = store.getters[Org.getters.user];
  userMonitor.setUserWithMetaData({
    id: user.id,
    sitesAvailable: user.sites.length,
    orgsAvailable: uniqBy(user.sites, 'organisation.id').length,
  });
});
