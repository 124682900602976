<script>
  import { computed, h, toRefs } from 'vue';

  export default {
    props: {
      text: { type: String, default: '' },
    },
    setup(props) {
      const { text } = toRefs(props);

      // split into non-empty lines
      const lines = computed(() => text.value.split('\n').filter(t => t));
      return () => lines.value.map(line => h('p', { innerHTML: line }));
    },
  };
</script>
