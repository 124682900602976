import { Temporal } from '@js-temporal/polyfill';

import { DateRange } from '@/shared/DateTime';

import Destination from '../Destination';

interface ChargesBreakdown {
  name: string;
  cost: Cents;
}

/**
 * @example {
 *   "Carton": 25,
 *   "Pallet": 120
 * }
 */
export type PackagingTypeConstraints = {
  [key: string]: number;
};

interface Quote {
  id: UUID;
  agreedServiceId: string;
  carrierId: string;
  carrierServiceId: string;
  createdAt: string; // DateTime
  eta: string; // Date
  evaluation: {
    unsupportedPackagingTypes?: string[];
    unavailablePricing?: { from: Destination; to: Destination };
    unsupportedFromLocality?: Destination;
    unsupportedToLocality?: Destination;
    unsupportedDeliveryTimeSlots?: boolean;
    invalidDeliveryTimeSlotStartDate?: { earliestPermittedDate: Temporal.PlainDate; dateRange?: DateRange };
    dangerousGoods?: {
      carrierAuthorityDenied?: boolean;
      notAllowedByCarrier?: boolean;
    };
    failedConstraints?: {
      consignmentMaxItemCount?: number;
      consignmentMaxDeadWeight?: number;
      consignmentMaxVolume?: number;
      itemMaxLength?: number;
      itemMaxWidth?: number;
      itemMaxHeight?: number;
      itemMaxDeadWeight?: number;
      itemMaxVolume?: number;
      packagingTypeMaxLength?: PackagingTypeConstraints;
      packagingTypeMaxWidth?: PackagingTypeConstraints;
      packagingTypeMaxHeight?: PackagingTypeConstraints;
      packagingTypeMaxDeadWeight?: PackagingTypeConstraints;
      packagingTypeMaxVolume?: PackagingTypeConstraints;
    };
  };
  quoteSetId: string;
  selectable: boolean;
  recommended: boolean;
  transitGuideDays: number;

  currency?: string;
  chargesBreakdown?: ChargesBreakdown[];
  fees?: Cents;
  freight?: Cents;
  net?: Cents;
  tax?: Cents;
  total?: Cents;
}

const create = ({
  id,
  agreedServiceId,
  carrierId,
  carrierServiceId,
  chargesBreakdown,
  createdAt,
  currency,
  eta,
  fees,
  freight,
  net,
  tax,
  quoteSetId,
  total,
  transitGuideDays,
  evaluation,
  selectable,
  recommended,
}: Quote): Quote => ({
  id,
  agreedServiceId,
  carrierId,
  carrierServiceId,
  chargesBreakdown,
  createdAt,
  currency,
  eta,
  fees,
  freight,
  net,
  tax,
  quoteSetId,
  total,
  transitGuideDays,
  evaluation,
  selectable,
  recommended,
});

export const quoteFactory = {
  create,
};

export default Quote;
