import { ModelWithKey, modelWithKeyFactory, withKey } from '@/shared/models/helpers/ModelWithKey';

/* Minimal line item used for freight input */

interface BaseLineItem {
  description?: string;
  shippingItemPresetId?: UUID;
  packagingType: string;
  width: Millimeters;
  height: Millimeters;
  length: Millimeters;
  weight: Grams;
  quantity: number;
}
type BaseNewLineItem = Partial<BaseLineItem>;

export type LineItem = ModelWithKey<BaseLineItem>;

export type NewLineItem = ModelWithKey<BaseNewLineItem>;

const create = (item: BaseLineItem): LineItem => withKey(item);

const createNew = ({
  description,
  packagingType,
  width,
  height,
  length,
  weight,
  quantity,
}: Partial<BaseNewLineItem> = {}): BaseNewLineItem => ({
  description,
  packagingType,
  width,
  height,
  length,
  weight,
  quantity,
});

const createNewWithDefaults = (lineItem: Partial<BaseNewLineItem> = {}): BaseNewLineItem =>
  createNew({
    ...lineItem,
    quantity: lineItem.quantity || 1,
  });

export const lineItemFactory = {
  create,
  createNew: modelWithKeyFactory(createNew),
  createNewWithDefaults: modelWithKeyFactory(createNewWithDefaults),
};

export const isEmptyItem = (item: NewLineItem) =>
  item.packagingType === undefined &&
  item.description === undefined &&
  item.height === undefined &&
  item.width === undefined &&
  item.length === undefined &&
  item.weight === undefined;
