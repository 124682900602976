import RollbarClient from 'rollbar';
import { type App } from 'vue';

import { errorIsCausedBy } from '@/shared/errorHandling/helpers';
import Rollbar from 'rollbar';

interface ErrorMonitoringConfig {
  enabled: RollbarClient.Configuration['enabled'];
  accessToken: RollbarClient.Configuration['accessToken'];
  environment: string;
  version: string;
  ignore3rdPartyErrors?: boolean;
  ignoreErrorsCausedBy?: ErrorType[];
}

type ErrorEventTransformer = Exclude<RollbarClient.Configuration['transform'], undefined>;
type ErrorEventContextCallback = (
  ...args: Parameters<ErrorEventTransformer>
) => Partial<Parameters<ErrorEventTransformer>[0]>;

class ErrorMonitoring {
  private rollbarClient: RollbarClient;
  private onErrorCallbacks: ErrorEventContextCallback[] = [];
  private rollbarContext: RollbarClient.Dictionary = {};

  constructor({
    enabled,
    accessToken,
    environment,
    version,
    ignore3rdPartyErrors = true,
    ignoreErrorsCausedBy,
  }: ErrorMonitoringConfig) {
    // @see https://docs.rollbar.com/docs/rollbarjs-configuration-reference
    this.rollbarClient = new RollbarClient({
      enabled,
      accessToken,
      captureUncaught: true,
      captureUnhandledRejections: true,
      autoInstrument: {
        log: false, // console.log telemetry causes an infinite loop w/ errorHandling
      },
      scrubTelemetryInputs: true,
      filterTelemetry: ErrorMonitoring.#filterTelemetry,
      payload: {
        environment,
        client: {
          javascript: {
            source_map_enabled: true,
            code_version: version,
          },
        },
      },
    });

    this.rollbarClient.configure({
      transform: this.#beforeSend.bind(this),
    });

    if (ignoreErrorsCausedBy) {
      this.rollbarClient.configure({
        checkIgnore: (isUncaught, args) => {
          const [error] = args;

          if (ignoreErrorsCausedBy.some(ignoreError => errorIsCausedBy(error, ignoreError))) return true;

          return false; // do not ignore errors by default
        },
      });
    }

    if (ignore3rdPartyErrors) {
      const oldOnError = window.onerror;
      window.onerror = function ignore3rdPartyOnError(event, source, lineno, colno, error) {
        // TODO this can be removed when we have confidence in the approach
        logger.info('ErrorMonitoring onerror', { source, lineno, colno });
        // GEPPES-2789 if these conditions are met, this is likely caused by a 3rd party library so let's not report it.
        if ((!source && !lineno && !colno) || (source && !source.startsWith(window.location.origin))) {
          logger.info('external script error', { error, source });
          return true;
        }
        if (typeof oldOnError === 'function') return oldOnError(event, source, lineno, colno, error);
      };
    }
  }

  #beforeSend(...args: Parameters<ErrorEventTransformer>) {
    const [data] = args;
    Object.assign(data, this.rollbarContext, this.#getErrorContext(...args));
  }

  #getErrorContext(...args: Parameters<ErrorEventTransformer>) {
    return this.onErrorCallbacks.reduce((acc, callback) => ({ ...acc, ...callback(...args) }), {});
  }

  // filter out telemetry that doesn't help us diagnose errors, or that contains sensitive information
  static #filterTelemetry(event: RollbarClient.TelemetryEvent) {
    if (event.type === 'network') {
      // filter out network requests to 3rd party monitoring services, since these happen frequently
      // and don't typically have any value helping us identify the cause of errors
      const ignoreDomains = [
        'browser-intake-datadoghq.com', // Datadog logs & RUM intake
        'fullstory.com', // FullStory recording
      ];
      if (ignoreDomains.some(domain => event.body.url && (event.body.url as string).includes(domain))) {
        return true;
      }
    }
    return false; // do not filter by default
  }

  addContextOnError(callback: ErrorEventContextCallback) {
    this.onErrorCallbacks.push(callback);
  }

  setUser(userId?: UUID) {
    this.rollbarClient.configure({
      payload: {
        person: userId ? { id: userId } : undefined,
      },
    });
  }

  addMetadata(key: string, value: unknown) {
    this.rollbarContext[key] = value;
  }

  clearMetadata(key: string) {
    delete this.rollbarContext[key];
  }

  install(app: App) {
    // in production builds, vue will not rethrow captured errors, so Rollbar captureUncaught
    // will not work for errors in the vue application. this ensures visibility of these errors.
    // @see https://docs.rollbar.com/docs/vue-js
    app.config.errorHandler = (error, vm, info) => {
      this.rollbarClient.error(error as Error, { info });
    };
  }

  critical(...args: Rollbar.LogArgument[]) {
    this.rollbarClient.critical(...args);
  }
  error(...args: Rollbar.LogArgument[]) {
    this.rollbarClient.error(...args);
  }
  warning(...args: Rollbar.LogArgument[]) {
    this.rollbarClient.warning(...args);
  }
  info(...args: Rollbar.LogArgument[]) {
    this.rollbarClient.info(...args);
  }
  debug(...args: Rollbar.LogArgument[]) {
    this.rollbarClient.debug(...args);
  }
}

export default ErrorMonitoring;
