import { QuotesTypes } from '@App/store/modules/QuotesStore';
import { createTypeHelpers } from '@App/store/store-helpers';

import { name } from '../module';

/**
 * Explicitly named getters, mutations and actions. Marked internal because
 * these will be used for definitions in the store, but most external code
 * will access these through the namespace, ie:
 *   '${MODULE_NAME}/CURRENT_ORGANISATION'
 */
const InternalTypes = {
  getters: {
    getConsignment: 'getConsignment',
    getConsignmentVersion: 'getConsignmentVersion',
    getConsignmentSenderAddressee: 'getConsignmentSenderAddressee',
    getConsignmentReceiverAddressee: 'getConsignmentReceiverAddressee',
    getConsignmentQuoteDetails: 'getConsignmentQuoteDetails',
    getConsignmentQuotes: 'getConsignmentQuotes',
    getConsignmentEnquiries: 'getConsignmentEnquiries',
  },

  mutations: {
    SET_CONSIGNMENT: 'SET_CONSIGNMENT',
    SET_CONSIGNMENT_VERSION: 'SET_CONSIGNMENT_VERSION',
    SET_VALIDATION_ERRORS: 'SET_VALIDATION_ERRORS',
    SET_CONSIGNMENT_ENQUIRIES: 'SET_CONSIGNMENT_ENQUIRIES',
  },

  actions: {
    reset: 'reset',
    fetchConsignment: 'fetchConsignment',
    fetchConsignmentVersion: 'fetchConsignmentVersion',
    deleteConsignment: 'deleteConsignment',
    fetchConsignmentEnquiries: 'fetchConsignmentEnquiries',
    lodgeEnquiry: 'lodgeEnquiry',
  },
};

/**
 * /**
 * Derive namespaced versions of each constant that can be used in views and
 * components directly, ie:
 *   this.$store.dispatch(Org.actions.fetchUser)
 *     which is the same as:
 *   this.$store.dispatch('org/fetchUser')
 *
 * @typedef {InternalTypes}
 */
const ModuleTypes = createTypeHelpers(name, InternalTypes);

const ConsignmentQuotesInternalTypes = createTypeHelpers('quotes', QuotesTypes);
const ConsignmentQuotes = createTypeHelpers(name, ConsignmentQuotesInternalTypes);

export { ModuleTypes as default, InternalTypes, ConsignmentQuotes, ConsignmentQuotesInternalTypes };
