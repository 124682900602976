const appInstrumentationTypes = {
  ADVANCED_LISTING_FILTER_OPEN: 'advanced listing filter open',
  CONTEXT_CHANGED: 'context changed',

  BROWSER_CHECK_SEEN: 'browser check seen',
  BROWSER_CHECK_DISMISSED: 'browser check dismissed',

  USER_GUIDE_OPENED: 'user guide opened',
  USER_PREFERENCES_OPENED: 'user preferences opened',
  USER_PREFERENCES_SITE_DEFAULT_SET: 'site default set',
  USER_PREFERENCES_PAGE_DEFAULT_SET: 'page default set',

  TRACKED_WINDOW_BLOCKED: 'tracked window blocked',

  BACKEND_VALIDATIONS: 'backend validations',
} as const;

export default appInstrumentationTypes;
