import { ConsignmentSummary } from '@/shared/models';

import { useAgreedServices } from '@App/context';

const useGetAgreedServiceModelForSummary = () => {
  const { services } = useAgreedServices();

  const getAgreedServiceModelForSummary = (consignmentSummary: ConsignmentSummary) => {
    const service = services.value.find(s => s.id === consignmentSummary.agreedService.id);
    return {
      name: consignmentSummary.agreedService.name,
      carrierServiceProviderType: service?.carrierServiceProviderType || 'carrier',
      carrier: {
        name: consignmentSummary.carrier.name,
      },
    };
  };

  return getAgreedServiceModelForSummary;
};

export default useGetAgreedServiceModelForSummary;
