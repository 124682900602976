import { DateRange, Interval, TimeRange } from '@/shared/DateTime';

export default interface DeliveryTimeSlot {
  window: Interval;
  recurrences: number;
}

/**
 * Used for capturing the delivery time slot information in a form
 */
export interface NewDeliveryTimeSlot {
  requiresDTS: boolean;
  slot?: {
    dateRange?: DateRange;
    timeRange?: TimeRange;
    references?: string[];
  };
}

export const deliveryTimeSlotFactory = {
  create: ({ window, recurrences }: DeliveryTimeSlot) => ({
    window,
    recurrences,
  }),
  createNew: ({ slot, requiresDTS = false }: Partial<NewDeliveryTimeSlot> | undefined = {}): NewDeliveryTimeSlot => ({
    slot: slot ?? undefined,
    requiresDTS: requiresDTS || false,
  }),
};
