import { ConsignmentAddressee, ConsignmentConsolidationSuggestion, NewConsignment } from '@/shared/models';
import { ConsignmentConsolidationItemSummary } from '@/shared/models/Consignment/ConsignmentConsolidationSuggestion';
import MappingError from '@/shared/services/errors/MappingError';
import { components, operations } from '@/shared/services/schema/geppetto-sender-app/consignments.schema';

const ConsignmentAddresseeToConsolidationAddress = (addressee: ConsignmentAddressee) => ({
  name: addressee.name,
  address: {
    ...addressee.address!,
    line2: addressee.line2,
  },
});

type CreateConsolidationRequest =
  operations['getConsolidationCandidates']['requestBody']['content']['application/json'];

export const consignmentToConsolidationRequest = (consignment: NewConsignment): CreateConsolidationRequest => {
  if (!consignment.siteId) throw new MappingError('siteId is required');
  if (!consignment.dispatchDate) throw new MappingError('dispatchDate is required');
  if (!consignment.sender) throw new MappingError('sender is required');
  if (!consignment.receiver) throw new MappingError('receiver is required');

  return {
    data: {
      siteId: consignment.siteId,
      dispatchDate: consignment.dispatchDate,
      sender: ConsignmentAddresseeToConsolidationAddress(consignment.sender),
      receiver: ConsignmentAddresseeToConsolidationAddress(consignment.receiver),
    },
  };
};

function candidateItemSummaryToConsignmentConsolidationItemSummary(
  summary: components['schemas']['ConsolidationCandidatesResult']['data'][number]['itemSummaries'][number],
): ConsignmentConsolidationItemSummary {
  return {
    packagingType: summary.packagingType,
    width: summary.width,
    length: summary.length,
    height: summary.height,
    weight: summary.weight,
    quantity: summary.quantity,
  };
}

export const clientConsolidationCandidatesToConsolidationSuggestions = (
  candidates: components['schemas']['ConsolidationCandidatesResult'],
): ConsignmentConsolidationSuggestion[] =>
  candidates.data.map(candidate => ({
    id: candidate.id,
    consignmentNo: candidate.consignmentNo,
    isSenderPaying: candidate.isSenderPaying,
    dispatchDate: candidate.dispatchDate,
    service: candidate.service,
    items: candidate.itemSummaries.map(candidateItemSummaryToConsignmentConsolidationItemSummary),
    eta: candidate.estimatedTimeOfArrival,
    cost: candidate.cost,
    hasDangerousGoods: candidate.hasDangerousGoods,
  }));
