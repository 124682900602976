import { Temporal } from '@js-temporal/polyfill';

import { checkPickupEvaluation } from '@/app/modules/Pickup/helpers/pickupService';
import { Interval } from '@/shared/DateTime';
import { toISODate } from '@/shared/DateTime/formatters';
import { instantFromDateAndTime } from '@/shared/DateTime/mappers';
import {
  createAddress,
  createFreight,
  createLocation,
  Freight,
  Pickup,
  PickupAddressee,
  PickupType,
} from '@/shared/models';
import { destinationFactory } from '@/shared/models/Destination';
import { PaginatedItems } from '@/shared/models/helpers/PaginatedItems';
import { mapPageMetaToPaginatedItemsMeta } from '@/shared/models/mappers/mapPageMetaToPaginatedItems';
import FreightInput from '@/shared/models/Pickup/FreightInput';
import { PickupConsignment } from '@/shared/models/Pickup/PickupConsignment';
import PickupFormData from '@/shared/models/Pickup/PickupFormData';
import PickupRequest, { createPickupRequest } from '@/shared/models/Pickup/PickupRequest';
import PickupService from '@/shared/models/Pickup/PickupService';
import PickupServiceOption, { createPickupServiceOption, Evaluation } from '@/shared/models/Pickup/PickupServiceOption';

import PickupSummary from '@/shared/models/Pickup/PickupSummary';
import { ApiClientResponse, GeppettoApiPaginatedResponse, ValidationError } from '@/shared/services/api-client';
import MappingError from '@/shared/services/errors/MappingError';
import { components } from '@/shared/services/schema/geppetto-sender-app/pickups.schema';
import {
  mapDangerousGoodsFromAPI,
  mapDangerousGoodsToAPI,
} from '@/shared/services/sender/mappers/dangerousGoodsMappers';

import { freightInputToFreight } from '@Pickup/mappers';

function mapPickupTypeToPickupTypeEnum(type: components['schemas']['Pickup']['type']): PickupType {
  if (type === 'outbound') return PickupType.Outbound;
  if (type === 'inbound') return PickupType.Inbound;
  if (type === 'transfer') return PickupType.Transfer;
  throw new MappingError(`unexpected pickupType value: ${type}`);
}

function mapPickupSearchResponseItem(response: components['schemas']['PickupSummary']): PickupSummary {
  return {
    ...response,
    pickupType: mapPickupTypeToPickupTypeEnum(response.type),
    createdAt: Temporal.Instant.from(response.createdAt),
    updatedAt: Temporal.Instant.from(response.updatedAt),
    requestedAt: response.requestedAt ? Temporal.Instant.from(response.requestedAt) : undefined,
    pickupDate: Temporal.PlainDate.from(response.pickupDate),
    pickupWindow: response.pickupWindow ? Interval.from(response.pickupWindow) : undefined,
    freight: response.freight ? response.freight.map(mapPickupGetFreight) : [],
    requests: response.requests ? response.requests.map(mapPickupGetRequest) : [],
    service: response.service ? mapPickupService(response.service) : undefined,
    sender: mapPickupGetSenderToAddressee(response.sender),
  };
}

export function mapPickupSearchResponse(
  response: ApiClientResponse<GeppettoApiPaginatedResponse<components['schemas']['PickupSummary']>>,
): PaginatedItems<PickupSummary> {
  const { data } = response;
  return {
    items: data.data.map(mapPickupSearchResponseItem),
    ...mapPageMetaToPaginatedItemsMeta(data.meta.page),
  };
}

function mapPickupGetSenderToAddressee(sender: components['schemas']['Sender']): PickupAddressee {
  return {
    location: createLocation({
      name: sender.name,
      addressId: sender.address.id,
      address: createAddress(sender.address),
      residential: sender.residential || false,
    }),
    contact: {
      id: sender.addressBookContactId,
      name: sender.contact.name,
      phone: sender.contact.phone,
      email: sender.contact.email,
    },
    addressBookEntryId: sender.addressBookEntryId,
  };
}

function mapPickupGetRequest(response: components['schemas']['Request']): PickupRequest {
  return createPickupRequest({
    id: response.id,
    updatedAt: Temporal.Instant.from(response.updatedAt),
    confirmedAt: response.confirmation?.confirmedAt
      ? Temporal.Instant.from(`${response.confirmation?.confirmedAt}`)
      : undefined,
    carrierReference: response.confirmation?.carrierReference,
    rejectedAt: response.rejection?.rejectedAt ? Temporal.Instant.from(`${response.rejection?.rejectedAt}`) : undefined,
    rejectionReason: response.rejection?.reason,
  });
}

function mapPickupGetFreight(response: components['schemas']['Freight']): Freight {
  return createFreight({
    destination: destinationFactory.create(response.destination),
    description: response.description,
    height: response.height,
    length: response.length,
    packagingType: response.packagingType,
    quantity: response.quantity,
    weight: response.weight,
    width: response.width,
    requestId: response.requestId,
    shippingItemPresetId: response.shippingItemPresetId,
    dangerousGoods: response.dangerousGoods ? response.dangerousGoods.map(mapDangerousGoodsFromAPI) : [],
  });
}

function mapEvaluation(evaluation: components['schemas']['ServiceEvaluation']): Evaluation {
  return {
    unsupportedPickupLocation: evaluation?.unsupportedPickupLocation || false,
    unsupportedDestinations: evaluation?.unsupportedDestinations?.map(i => destinationFactory.create(i)) || [],
    unsupportedPackagingType: evaluation?.unsupportedPackagingType || false,
    afterBookingCutoff: evaluation?.afterBookingCutoff || false,
    afterPickupCutoff: evaluation?.afterPickupCutoff || false,
    unavailablePricing: evaluation?.unavailablePricing || [],
    unsupportedPackagingTypes: evaluation?.unsupportedPackagingTypes || [],
    integrationNotConfigured: evaluation?.integrationNotConfigured || false,
    dangerousGoods: evaluation?.dangerousGoods || undefined,
  };
}

export function mapPickupServiceOption(response: components['schemas']['ServiceOption']): PickupServiceOption {
  const evaluation = mapEvaluation(response.evaluation);
  return createPickupServiceOption({
    agreedServiceId: response.agreedServiceId,
    serviceId: response.carrierServiceId,
    carrierId: response.carrierId,
    pickupBookingCutoff: response.pickupBookingCutoff ? Temporal.Instant.from(response.pickupBookingCutoff) : undefined,
    pickupCutoff: response.pickupCutoff ? Temporal.Instant.from(response.pickupCutoff) : undefined,
    pickupWindowMinimum: response.pickupWindowMinimum
      ? Temporal.Duration.from(`${response.pickupWindowMinimum}`)
      : undefined,
    selectable: response.isSelectable || false,
    recommended: !!response.isSelectable && checkPickupEvaluation(evaluation),
    evaluation,
  });
}

export function mapPickupConsignment(consignment: components['schemas']['PickupConsignment']): PickupConsignment {
  return {
    id: consignment.id,
    type: consignment.type,
    siteId: consignment.siteId,
    dispatchDate: consignment.dispatchDate,
    consignmentNo: consignment.consignmentNo,
    references: consignment.references || [],
    lineItems: consignment.lineItems.map(lineItem => ({
      ...lineItem,
      dangerousGoods: lineItem.dangerousGoods ? lineItem.dangerousGoods.map(mapDangerousGoodsFromAPI) : undefined,
      quantity: lineItem.itemIds.length,
    })),
    receiver: consignment.receiver,
  };
}

export function mapPickupConsignmentUpdated(
  consignment: components['schemas']['PickupConsignmentUpdated'],
): PickupConsignment {
  return {
    ...mapPickupConsignment(consignment),
    transferPolicy: consignment.transferPolicy,
    manifest: consignment.manifest
      ? {
          ...consignment.manifest,
          addedAt: Temporal.Instant.from(consignment.manifest.addedAt),
        }
      : undefined,
  };
}

export function mapPickupService(response: components['schemas']['SelectedService']): PickupService {
  return {
    agreedServiceId: response.agreedServiceId,
    serviceId: response.carrierServiceId,
    carrierId: response.carrierId,
    pickupBookingCutoff: response.pickupBookingCutoff ? Temporal.Instant.from(response.pickupBookingCutoff) : undefined,
    pickupCutoff: response.pickupCutoff ? Temporal.Instant.from(response.pickupCutoff) : undefined,
    pickupWindowMinimum: response.pickupWindowMinimum
      ? Temporal.Duration.from(`${response.pickupWindowMinimum}`)
      : undefined,
    serviceName: response.name,
    carrierName: response.carrierName,
    evaluation: mapEvaluation(response.evaluation),
  };
}

export function mapPickupGetResponse(response: components['schemas']['Pickup']): Pickup {
  return {
    id: response.id,
    siteId: response.siteId,
    pickupType: mapPickupTypeToPickupTypeEnum(response.type),
    createdAt: Temporal.Instant.from(response.createdAt),
    updatedAt: Temporal.Instant.from(response.updatedAt),
    pickupDate: Temporal.PlainDate.from(response.pickupDate),
    pickupWindow: response.pickupWindow ? Interval.from(response.pickupWindow) : undefined,
    pickupCloseTime: response.pickupCloseTime ? Temporal.Instant.from(response.pickupCloseTime) : undefined,
    pickupInstructions: response.pickupInstructions,
    reference: response.reference,
    freight: response.freight ? response.freight.map(mapPickupGetFreight) : [],
    freightProfile: {
      consignments: response.freightProfile?.consignments?.map(mapPickupConsignment) || [],
      freight: response.freightProfile?.freight?.map(mapPickupGetFreight) || [],
    },
    requests: response.requests ? response.requests.map(mapPickupGetRequest) : [],
    serviceOptions: response.services ? response.services.map(mapPickupServiceOption) : undefined,
    service: response.service ? mapPickupService(response.service) : undefined,
    sender: mapPickupGetSenderToAddressee(response.sender),
    dangerousGoodsDeclaration: response.dangerousGoodsDeclaration,
  };
}

const mapPickupFreightToPickupCreateRequestFreight = (
  freightInput: FreightInput[],
): components['schemas']['Freight'][] =>
  freightInputToFreight(freightInput).map(item => ({
    destination: {
      locality: item.destination.locality,
      postcode: item.destination.postcode,
      subdivision: item.destination.subdivision,
      countryId: item.destination.countryId,
    },
    description: item.description,
    packagingType: item.packagingType!,
    length: Math.round(item.length!),
    width: Math.round(item.width!),
    height: Math.round(item.height!),
    weight: Math.round(item.weight!),
    quantity: Math.round(item.quantity!),
    dangerousGoods: item.dangerousGoods ? item.dangerousGoods.map(mapDangerousGoodsToAPI) : undefined,
  }));

export const mapPickupFormDataToPickupSaveRequest = (pickup: PickupFormData): components['schemas']['NewPickup'] => {
  if (!pickup.sender) throw new MappingError('sender is required');
  if (!(pickup.freight?.length || pickup.preFillConsignmentIds?.length)) {
    throw new MappingError('Invalid pickup with no freight');
  }

  const { timeZone } = pickup.sender!.location.address!;
  if (!timeZone) throw new Error('Unable to get sender timeZone');

  let pickupWindow;
  if (pickup.siteAccess.pickupWindow) {
    pickupWindow = Interval.from({
      start: instantFromDateAndTime(pickup.pickupDate!, pickup.siteAccess.pickupWindow?.start, timeZone),
      end: instantFromDateAndTime(pickup.pickupDate!, pickup.siteAccess.pickupWindow?.end, timeZone),
    });
  }

  return {
    data: {
      siteId: pickup.siteId,
      type: pickup.pickupType,
      pickupDate: pickup.pickupDate ? toISODate(pickup.pickupDate) : undefined,
      freightProfile: {
        consignments: pickup.preFillConsignmentIds,
        freight: mapPickupFreightToPickupCreateRequestFreight(pickup.freight),
      },
      sender: {
        name: pickup.sender.location.name,
        address: {
          id: pickup.sender.location.addressId!,
          line2: pickup.sender.location.line2 || '',
        },
        residential: pickup.sender.location.residential,
        contact: {
          name: pickup.sender.contact.name,
          phone: pickup.sender.contact.phone,
          email: pickup.sender.contact.email!,
        },
        addressBookEntryId: pickup.sender.addressBookEntryId,
        addressBookContactId: pickup.sender.contact.id,
      },
      pickupWindow: pickupWindow?.toString(),
      pickupCloseTime: pickup.siteAccess.closingTime
        ? instantFromDateAndTime(
            pickup.pickupDate!,
            pickup.siteAccess.closingTime as Temporal.PlainTime,
            timeZone,
          ).toString()
        : undefined,
      pickupInstructions: pickup.siteAccess.pickupInstructions,
    },
  };
};

export const mapCreatePickupServerErrors = (sourceError: ValidationError, pickup: PickupFormData): ValidationError => {
  const error = new ValidationError(sourceError);

  error.apiErrors = sourceError.apiErrors?.map(e => {
    if (!e.source) return e; // no idea how this could happen, but source is optional.

    const match = /\/data\/freightProfile\/freight\/(\d+)(?:\/dangerousGoods\/(\d+))?/.exec(e.source.pointer);
    if (!match) return e;

    const freightInput = pickup.freight;
    const freight = freightInputToFreight(freightInput)[Number(match[1])];
    if (!freight) return e;

    e.source.pointer = e.source.pointer.replace(`freight/${match[1]}`, `freight/key:${freight._key}`);

    if (match[2]) {
      // remap dangerous goods index
      const dg = freight.dangerousGoods?.[Number(match[2])];
      if (!dg) return e;

      e.source.pointer = e.source.pointer.replace(`dangerousGoods/${match[2]}`, `dangerousGoods/key:${dg._key}`);
    }

    return e;
  });

  error.apiError = error.apiErrors?.[0];

  return error;
};
