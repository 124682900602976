import { Plugin } from 'vue';
import { RouteLocationNamedRaw, RouteLocationNormalized, RouteLocationPathRaw, Router } from 'vue-router';

export const isForcedNavigation = ({ query }: RouteLocationNormalized) => !!query?.force_nav;

export const forceNavigation = ({ query, ...to }: RouteLocationPathRaw | RouteLocationNamedRaw, reason?: string) => ({
  ...to,
  query: {
    ...query,
    force_nav: reason || 'true',
  },
});

export const plugin: Plugin = {
  install: (app, { router }: { router: Router }) => {
    if (!router) throw new Error('forceNavigation plugin must be installed after vue-router');

    router.afterEach(async to => {
      if (isForcedNavigation(to)) {
        // remove ?force_nav from the query so it isn't present on subsequent navigation
        const { force_nav: _, ...query } = to.query;
        await router.replace({ ...to, query });
      }
    });
  },
};
