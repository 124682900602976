import { computed, reactive } from 'vue';
import { setValidations } from './core';

const mixin = {
  computed: {},

  beforeCreate() {
    const options = this.$options;
    if (!options.validations) return;
    if (options.computed && options.computed.$v) return;

    const dirtyFields = reactive(new Set());

    options.computed._validations = options.validations;
    const validationResults = computed(() => setValidations({
        validations: this._validations,
        state: this,
        dirtyFields,
      }),
    );

    Object.defineProperty(this, '$v', {
      enumerable: true,
      configurable: true,
      get: () => (this._validations) ? validationResults.value : undefined,
    });
  },
};

export default {
  install(app) {
    app.mixin(mixin);
  },
};
