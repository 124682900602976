import uniqueId from 'lodash/uniqueId';

function install(app) {
  app.mixin({
    beforeCreate() {
      Object.defineProperty(this, 'cid', {
        value: uniqueId(),
      });
    },
  });
}

export default {
  install,
};
