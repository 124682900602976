<template>
  <ListBoxDropDown v-bind="listBoxDropDownProps" ref="listBoxDropDownRef" @keydown="onKeyDown" @focusout="onFocusOut">
    <template #trigger>
      <div ref="triggerRef" @click="toggleOpen">
        <slot name="trigger"></slot>
      </div>
    </template>
    <template #drop-down>
      <ListBox v-bind="listBoxProps" ref="listBoxRef">
        <slot name="menu" :reset-highlight="() => highlightItem(0)"> </slot>
      </ListBox>
    </template>
  </ListBoxDropDown>
</template>

<script setup>
  import clamp from 'lodash/clamp';
  import { computed, onBeforeUnmount, provide, ref } from 'vue';

  import { ListBox, ListBoxDropDown } from './list-box';

  const props = defineProps({
    disabled: {
      type: Boolean,
      default: false,
    },
    /* these are passed to the ListBoxDropDown */
    left: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    /* these are passed to the ListBox */
    scrollable: {
      type: Boolean,
      default: false,
    },
    maxScrollHeight: {
      type: String,
      default: '340px',
    },
  });

  const emit = defineEmits(['open', 'close']);

  const listBoxDropDownRef = ref(null);

  const triggerRef = ref(null);

  const listBoxRef = ref(null);

  const isOpen = defineModel('isOpen', { type: Boolean, default: false });

  const listBoxDropDownProps = computed(() => ({
    open: isOpen.value,
    left: props.left,
    top: props.top,
  }));

  const listBoxProps = computed(() => ({
    scrollable: props.scrollable,
    maxScrollHeight: props.maxScrollHeight,
  }));

  let handleWindowClick = () => {}; // redefined below to prevent a no-use-before-define eslint error

  const openDropDown = () => {
    isOpen.value = true;
    window.addEventListener('click', handleWindowClick);
    /**
     * Todo: better solution to this.
     * The Problem: we want the responsiveness of showing the menu on mousedown, however if we fire the `open`
     * event straight away and use that to focus something (search box) the mouseUp global event takes focus
     * away again.  nextTick is not enough time to fix this, but a 10ms delay does.
     */
    setTimeout(() => {
      emit('open');
    }, 10);
  };

  const closeDropDown = () => {
    isOpen.value = false;
    window.removeEventListener('click', handleWindowClick);
    highlightItem(-1);
    emit('close');
  };

  const toggleOpen = () => {
    if (!isOpen.value && !props.disabled) {
      openDropDown();
    } else {
      closeDropDown();
    }
  };

  handleWindowClick = event => {
    // i know this needs a more descriptive name, but I couldn't think of one
    if (isOpen.value && !event.composedPath().includes(triggerRef.value)) {
      // check path for our special data-do-not-close tag, if it exists then don't hide
      // this is used for the search box, and checkbox items.
      if (event.composedPath().some(element => element.hasAttribute?.('data-do-not-close'))) {
        return;
      }
      closeDropDown();
    }
  };

  onBeforeUnmount(() => {
    window.removeEventListener('click', handleWindowClick);
  });

  /** Keyboard navigation * */
  const highlightedItemIndex = ref(-1);

  const getHighlightableItems = () => {
    const listBox = listBoxRef.value.$el;
    return listBox.querySelectorAll('[data-is-interactive]');
  };

  const highlightItem = newIndex => {
    const highlightableItems = getHighlightableItems();
    const index = clamp(newIndex, -1, highlightableItems.length - 1);
    highlightedItemIndex.value = index;
    // remove previous focus classes
    highlightableItems.forEach(item => item.classList.remove('focused'));
    if (index > -1) {
      highlightableItems[index].classList.add('focused');
      highlightableItems[index].scrollIntoView({ block: 'nearest', inline: 'nearest' });
    } else {
      // scroll to top
      const listBox = listBoxRef.value.$el.querySelector('.list-box-inner');
      if (listBox) listBox.scrollTo({ top: 0 });
    }
  };

  const getCurrentHighightedItem = () => {
    if (highlightedItemIndex.value > -1) return highlightedItemIndex.value;
    // todo: see if any items are natively highlighted and set the index to that.
    return -1;
  };

  const onKeyDown = e => {
    if (!isOpen.value) return;
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      highlightItem(getCurrentHighightedItem() + 1);
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      highlightItem(getCurrentHighightedItem() - 1);
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (highlightedItemIndex.value > -1) {
        const highlightableItems = getHighlightableItems();
        highlightableItems[getCurrentHighightedItem()]?.click();
      }
    } else if (e.key === 'Escape') {
      e.preventDefault();
      closeDropDown();
    }
  };

  const onFocusOut = event => {
    if (event.relatedTarget && !listBoxDropDownRef.value.$el.contains(event.relatedTarget)) {
      // tabbed out of the list box
      closeDropDown();
    }
  };

  provide('disableTab', true);
</script>
