import { App } from 'vue';
import { RouteLocation, Router } from 'vue-router';

type RouterOpenParams = Parameters<Router['open']>;
type OnOpenBlockedFn = (route: RouteLocation, routeDef: RouterOpenParams[0], data: RouterOpenParams[1]) => void;

export interface RouterOpenPlugin {
  install: (app: App | null, options: { router: Router; onOpenBlocked?: OnOpenBlockedFn }) => void;
}

const RouterOpen: RouterOpenPlugin = {
  install(app, options) {
    const { router, onOpenBlocked } = options;

    if (!router) return;

    router.open = (routeDef, data) => {
      if (data) {
        localStorage.setItem('RouterOpenState', JSON.stringify(data));
      }
      const route = router.resolve(routeDef);
      const newWindow = window.open(route.href, route.href);

      if (!newWindow && onOpenBlocked) {
        onOpenBlocked(route, routeDef, data);
      }
    };
  },
};

export const routerOpenProps = () => {
  const contextState = localStorage.getItem('RouterOpenState');
  localStorage.removeItem('RouterOpenState');
  if (!contextState) return null;
  try {
    const jsonState = JSON.parse(contextState);
    return jsonState;
  } catch {
    logger.error('Unable to parse contextState in routerOpenProps', contextState);
    return null;
  }
};

export default RouterOpen;
