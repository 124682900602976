import { organisationGqlFields } from '@/shared/models/Organisation';

import Site, { createSite, siteGqlFields } from './Site';

interface OrgUser {
  id: string;
  username: string;
  fullName: string;
  phone: string;
  email: string;
  acceptedTermsAt?: Date;
  sites: Site[];
}

const newOrgUser = (): OrgUser => ({
  id: '',
  username: '',
  fullName: '',
  phone: '',
  email: '',
  sites: [],
});

export const createOrgUser = (
  { id, username, fullName, phone, email, acceptedTermsAt, sites = [] }: OrgUser | undefined = newOrgUser(),
): OrgUser => ({
  id,
  username,
  fullName,
  phone,
  email,
  acceptedTermsAt,
  sites: sites.map(createSite),
});

export const orgUserGqlFields = [
  'uuid',
  'username',
  'fullName',
  'phone',
  'email',
  'acceptedTermsAt',
  {
    sites: [...siteGqlFields, { organisation: organisationGqlFields }],
  },
];

export default OrgUser;
