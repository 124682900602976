import merge from 'lodash/merge';

export { default as asyncImport } from './asyncImport';

/**
 * Create routes with a common config and path prefix to avoid having parent routes with empty component.
 * @see https://github.com/vuejs/vue-router/issues/745
 *
 * @param common <object> Config that should be applied to all routes.
 * @param routes <Array<RouteConfig>> Array of routes to apply the common config to.
 * @return <Array<RouteConfig>>
 */
export const withPrefix = (common, routes) =>
  routes.map(route =>
    merge({}, common, route, {
      // Don't prefix routes with absolute paths (starting with /)
      path: route.path.indexOf('/') !== 0 ? `${common.path}/${route.path}` : route.path,
    }),
  );

export const contextPrefix = '/:contextType(site|org)/:contextId';

/**
 * As above, but adds a context prefix to the route
 * @see https://github.com/vuejs/vue-router/issues/745
 *
 * @param common <object> Config that should be applied to all routes.
 * @param routes <Array<RouteConfig>> Array of routes to apply the common config to.
 * @return <Array<RouteConfig>>
 */
export const withContextPrefix = (common, routes) =>
  routes.map(route => ({
    ...common,
    ...route,
    // Don't prefix routes with absolute paths (starting with /)
    path: route.path.indexOf('/') !== 0 ? `${contextPrefix}/${common.path}/${route.path}` : route.path,
  }));

export const locationFromRoute = ({ name, params = {}, query = {} }, excludeParams = [], excludeQueryArgs = []) => {
  // Copy and filter out keys from params and query
  // These are likely to be modified when the calling function mutates the returned Location,
  // and we want to avoid mutating global $route objects.
  const filteredParams = Object.keys(params)
    .filter(key => !excludeParams.includes(key))
    .reduce((result, key) => ({ ...result, [key]: params[key] }), {});
  const filteredQuery = Object.keys(query)
    .filter(key => !excludeQueryArgs.includes(key))
    .reduce((result, key) => ({ ...result, [key]: query[key] }), {});

  // No need to copy name since it's just a string
  return {
    name,
    params: filteredParams,
    query: filteredQuery,
  };
};
