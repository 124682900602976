import { ApiClientError } from '@/shared/services/api-client';

export default class ResourceError extends ApiClientError {
  public resourceType: string;

  constructor(error: ApiClientError, resourceType = 'Resource', message = 'There was a problem loading a resource') {
    super(error);
    this.message = message;
    this.resourceType = resourceType;
  }
}
