import { Temporal, toTemporalInstant } from '@js-temporal/polyfill';

import { Interval, PlainDateRange } from '@/shared/DateTime/index';

declare global {
  interface Date {
    toTemporalInstant: typeof toTemporalInstant;
  }
}

Date.prototype.toTemporalInstant = toTemporalInstant;

export const temporalZonedDateTimeFromInstant = (instant: Temporal.Instant, timeZone?: Temporal.TimeZoneLike) =>
  instant.toZonedDateTimeISO(timeZone || Temporal.Now.timeZoneId());

export const temporalInstantFromDate = (date: Date) => {
  if (!(date instanceof Date)) throw new Error('temporalInstantFromDate called on not a Date');
  return date.toTemporalInstant();
};
export const temporalInstantToDate = (instant: Temporal.Instant) => new Date(instant.epochMilliseconds);

export const temporalZonedDateTimeFromDate = (date: Date, timeZone?: Temporal.TimeZoneLike) => {
  if (!(date instanceof Date)) throw new Error('temporalZonedDateTimeFromDate called on not a Date');
  const instant = date.toTemporalInstant();
  return temporalZonedDateTimeFromInstant(instant, timeZone);
};

export const temporalPlainDateFromDate = (date: Date, timeZone?: Temporal.TimeZoneLike) => {
  if (!(date instanceof Date)) throw new Error('temporalPlainDateFromDate called on not a Date');
  const zonedDateTime = temporalZonedDateTimeFromDate(date, timeZone);
  return zonedDateTime.toPlainDate();
};

export const temporalPlainDateToDate = (plainDate: Temporal.PlainDate) =>
  new Date(plainDate.year, plainDate.month - 1, plainDate.day);

export const temporalPlainTimeFromDate = (date: Date, timeZone?: Temporal.TimeZoneLike) => {
  if (!(date instanceof Date)) throw new Error('temporalPlainTimeFromDate called on not a Date');
  const zonedDateTime = temporalZonedDateTimeFromDate(date, timeZone);
  return zonedDateTime.toPlainTime();
};

export const temporalInstantToPlainTime = (instant: Temporal.Instant, timeZone?: Temporal.TimeZoneLike) => {
  const zonedDateTime = temporalZonedDateTimeFromInstant(instant, timeZone);
  return zonedDateTime.toPlainTime();
};

export const temporalInstantToPlainDate = (instant: Temporal.Instant, timeZone?: Temporal.TimeZoneLike) => {
  const zonedDateTime = temporalZonedDateTimeFromInstant(instant, timeZone);
  return zonedDateTime.toPlainDate();
};

export type CanBecomeDate = Temporal.Instant | Temporal.PlainDate;
export const temporalToDate = (item: CanBecomeDate) => {
  if (item instanceof Temporal.Instant) return temporalInstantToDate(item);
  if (item instanceof Temporal.PlainDate) return temporalPlainDateToDate(item);
  throw new Error(`${item} cannot be converted to Date`);
};

export function dateFromISODateString(dateString: string): Date {
  const match = /^(\d{4})-(\d{2})-(\d{2})$/.exec(dateString);
  if (!match) throw new Error(`Unable to parse date string: ${dateString}`);
  const [, hours, months, days] = match;
  return new Date(Number(hours), Number(months) - 1, Number(days));
}

export function instantFromDateAndTime(
  date: Temporal.PlainDate,
  time: Temporal.PlainTime,
  timeZone: string,
): Temporal.Instant {
  const tz = new Temporal.TimeZone(timeZone);
  const plainDateTime = new Temporal.PlainDateTime(
    date.year,
    date.month,
    date.day,
    time.hour,
    time.minute,
    time.second,
  );
  return tz.getInstantFor(plainDateTime) as Temporal.Instant;
}

export function temporalPlainDateRangeFromString(source?: string) {
  if (!source) return undefined;
  const dates = source.split(',');
  if (dates.length === 1) return Temporal.PlainDate.from(dates[0]);
  return [Temporal.PlainDate.from(dates[0]), Temporal.PlainDate.from(dates[1])];
}

export function temporalPlainDateRangeToString(source?: PlainDateRange) {
  if (!source) return undefined;
  if (!Array.isArray(source)) return source.toString();
  if (source[0].toString() === source[1].toString()) return source[0].toString();
  return `${source[0].toString()},${source[1].toString()}`;
}

export function temporalPlainDateRangeToStringFull(source?: PlainDateRange) {
  if (!source) return undefined;
  const interval = plainDateRangeToInterval(source);
  return `${interval.start.toString()},${interval.end.toString()}`;
}

export function plainDateRangeToInterval(dateRange: PlainDateRange, timeZone?: Temporal.TimeZoneLike): Interval {
  const startDate = Array.isArray(dateRange) ? dateRange[0] : dateRange;
  const endDate =
    Array.isArray(dateRange) && dateRange.length > 1 && dateRange[1] instanceof Temporal.PlainDate
      ? dateRange[1]
      : startDate;

  const tz = timeZone || Temporal.Now.timeZoneId();
  const midnight = new Temporal.PlainTime();
  const startInstant = startDate.toZonedDateTime({ timeZone: tz, plainTime: midnight }).toInstant();

  const oneSecondToMidnight = new Temporal.PlainTime(23, 59, 59);
  const endInstant = endDate.toZonedDateTime({ timeZone: tz, plainTime: oneSecondToMidnight }).toInstant();

  return Interval.from({ start: startInstant, end: endInstant });
}
