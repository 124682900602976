import { errorIsDisplayed } from '@/shared/errorHandling/helpers';

const criticalBoundary = {
  /**
   * @param error
   * @param recoverableErrors exceptions to the critical boundary. These will not set
   * the boundary as critical and will be handled by the error boundary component
   */
  shouldSetCritical: (error: Error, recoverableErrors: ErrorType[] = []) => {
    if (recoverableErrors.some(errorType => error instanceof errorType)) {
      return false;
    }

    return !errorIsDisplayed(error);
  },
};

export default criticalBoundary;
