<template>
  <DialogContent
    :type="type ?? 'success'"
    :title="title ?? message"
    :text="title ? message : undefined"
    compact
    :sensitive="sensitive"
  >
    <template v-if="action" #end>
      <button type="button" class="button toast-action" :onclick="callbackWithClose">
        {{ action.text }}
      </button>
    </template>
  </DialogContent>
</template>

<script setup lang="ts">
  import { ToastOptions } from '..';

  import DialogContent from './DialogContent.vue';

  const props = withDefaults(defineProps<ToastOptions & { onCloseToast?: () => void }>(), {
    text: undefined,
    title: undefined,
    compact: false,
    sensitive: false,
    onCloseToast: undefined,
  });

  const callbackWithClose = () => {
    props.action?.callback();
    props.onCloseToast?.();
  };
</script>

<style scoped lang="scss">
  .dialog-container {
    align-items: center;
    flex-grow: 1;
    padding-inline: 0;
  }

  :deep(.dialog-start) {
    display: flex;
    align-self: center;
    flex-grow: 0;
  }

  :deep(.dialog-title) {
    @include H3-default;
  }
</style>
