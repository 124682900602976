import Quote, { quoteFactory } from './Quote';

interface QuoteSet {
  id: string;
  createdAt: string; // dateTime
  quotes: Quote[];
}

export const create = ({ id, createdAt, quotes }: QuoteSet) => ({
  id,
  createdAt,
  quotes: quotes.map(quoteFactory.create),
});

export const quoteSetFactory = {
  create,
};

export default QuoteSet;
