import axios, { AxiosError, CanceledError, CreateAxiosDefaults, Method } from 'axios';

import NetworkError from '@/shared/services/api-client/errors/NetworkError';
import NotAuthenticatedError from '@/shared/services/api-client/errors/NotAuthenticatedError';
import { formatParams } from '@/shared/services/api-client/helpers/params';
import authService from '@/shared/services/auth';

import {
  ApiClientError,
  CancelledError,
  DuplicateError,
  NotFoundError,
  PermissionDeniedError,
  PreconditionFailedClientError,
  ValidationError,
} from './errors';
import { ApiClientRequestConfig, ApiClientResponse, GeppettoApiResponse } from './types';

type ApiClientErrors =
  | ValidationError
  | ApiClientError
  | PreconditionFailedClientError
  | NotAuthenticatedError
  | PermissionDeniedError
  | NotFoundError
  | DuplicateError;

export type APIClientConfig = CreateAxiosDefaults & {
  // Optional function for handling errors that may occur during API requests.
  onError?: (error: ApiClientErrors) => void;
};

class APIClient {
  private _apiClient;

  private _serviceSlug;

  private _baseURL;

  private _onError;

  constructor(baseURL: string, { onError, ...config }: APIClientConfig = {}) {
    this._baseURL = baseURL;
    this._onError = onError;
    this._apiClient = axios.create({
      baseURL,
      ...config,
    });

    // Extract a small string to identify the service that we can use to segment analytics
    // For a proxied API (ie /api/sender) this will be 'sender'
    // For an external API (ie https://service.domain/api/path) this will be 'service.domain'
    this._serviceSlug = baseURL.split('/')[2];
  }

  getUri(urlPath: string, config?: ApiClientRequestConfig) {
    return this._apiClient.getUri({
      baseURL: this._baseURL,
      ...config,
      ...(config?.params ? { params: formatParams(config.params) } : {}),
      url: urlPath,
    });
  }

  async request<T>(method: Method, urlPath: string, config?: ApiClientRequestConfig): Promise<ApiClientResponse<T>> {
    const requestStart = Date.now();

    // GEPPES-1669 workaround for frequent 401 responses from back-end
    // TODO: solve this better by refreshing token several minutes BEFORE expiry
    try {
      await authService.currentSession();
    } catch {
      // do nothing, if token cannot be refreshed the request will receive a 401 - which is appropriate
    }

    let response;
    try {
      response = await this._apiClient.request<T>({
        ...config,
        ...(config?.params ? { params: formatParams(config.params) } : {}),
        method,
        url: urlPath,
      });

      return response;
    } catch (error) {
      if (error instanceof CanceledError) {
        // CancelledError has no response or apiErrors so can't be an APIClientError
        throw new CancelledError(undefined, { cause: error });
      }

      const apiError = APIClient._wrappedError(error as AxiosError<GeppettoApiResponse<never>>);

      if (!apiError.response || APIClient._errorStatusIsExceptional(apiError.status)) {
        logger.error('service request failure', {
          error,
          http: {
            url: `${this._apiClient.defaults.baseURL}${urlPath}`,
            method,
            referrer: window.location.href,
          },
        });
      }

      this._onError?.(apiError);

      throw apiError;
    } finally {
      // Always log service requests, even if they fail, to record real response times from the client
      if (response) {
        const url = `${this._apiClient.defaults.baseURL}${urlPath}`;
        // Convert ids to placeholders... (must be extended to add new param types)
        const endpoint = APIClient._getDeparameterisedPath(url)
          // ...and remove /api/service prefix
          .replace(`/api/${this._serviceSlug}`, '');

        logger.info(
          'service request',
          {
            duration: Date.now() - requestStart,
            http: {
              url,
              method,
              referrer: window.location.href,
              status_code: response.status,
            },
            backend_request: {
              // @backend_request.service (Facet)
              service: this._serviceSlug,
              // @backend_request.endpoint (Facet)
              endpoint: `${method.toUpperCase()} ${endpoint}`,
            },
          },
          { response },
        );
      }
    }
  }

  async query<T>(resource: string, config: ApiClientRequestConfig) {
    return this.request<T>('get', resource, config);
  }

  async head<T>(resource: string, config?: ApiClientRequestConfig) {
    return this.request<T>('head', resource, config);
  }

  async get<T>(resource: string, config?: ApiClientRequestConfig) {
    return this.request<T>('get', resource, config);
  }

  async post<T, P = unknown>(resource: string, data: P, config?: ApiClientRequestConfig) {
    return this.request<T>('post', resource, {
      ...config,
      data,
    });
  }

  async put<T, P = unknown>(resource: string, data: P, config?: ApiClientRequestConfig) {
    return this.request<T>('put', `${resource}`, {
      ...config,
      data,
    });
  }

  async delete<T>(resource: string, config?: ApiClientRequestConfig) {
    return this.request<T>('delete', resource, config);
  }

  async html(resource: string, identifier?: string, config?: ApiClientRequestConfig) {
    return this.get(`${resource}${identifier ? `/${identifier}` : ''}`, {
      headers: { Accept: 'text/html' },
      ...config,
    });
  }

  static _wrappedError<T extends GeppettoApiResponse<never>>(error: AxiosError<T>) {
    // if there is no response, the request failed due to a network error
    if (!error.response || (error instanceof AxiosError && error.code === 'ERR_NETWORK')) {
      return new NetworkError(error);
    }

    switch (error.response?.status) {
      case 422:
        if (error.response?.data?.errors) {
          return new ValidationError(error);
        }
        return new ApiClientError(error);
      case 412:
        return new PreconditionFailedClientError(error);
      case 401:
        return new NotAuthenticatedError(error);
      case 403:
        return new PermissionDeniedError(error);
      case 404:
        return new NotFoundError(error);
      case 409:
        return new DuplicateError(error);
      default:
        return new ApiClientError(error);
    }
  }

  static _errorStatusIsExceptional(status: number | null) {
    // These are valid errors codes but don't represent a malfunction in the system
    const unexceptionalErrorCodes = [404, 409, 418, 422];
    return !status || (status >= 400 && !unexceptionalErrorCodes.includes(status));
  }

  /**
   * Take a service URL or relative path with parameters and replace parameters with placeholders
   * so that requests to the same endpoint for different resources can be grouped.
   *   i.e., /v0/addresses/0c6a920e-95ae-4b5e-a1d2-5cb6a44380d8 -> /v0/addresses/:id
   * @param url {string} The request path or URL
   * @return {string} The request path with parameters replaced with placeholders.
   * @private
   */
  static _getDeparameterisedPath(url: string) {
    const fullPath = url.includes('http', 0) ? new URL(url).pathname : url;
    // extend this to include new parameter types as needed
    const replacePatterns = [
      // placeholder, pattern
      [':uuid', /[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}/gi],
    ];
    return replacePatterns.reduce(
      (path, [placeholder, pattern]) => path.replace(pattern, placeholder as string),
      fullPath,
    );
  }
}

export default APIClient;

/*
 * This is needed to add mocked responses in storybook. api-client.stubbed will implement this.
 */
export function apiClientDecorator() {
  throw new Error('This should never be used in the app');
}
