import loadExternalScript from '@/shared/loadExternalScript';
import flattenObject from '@/shared/utils/flattenObject';

// survey flow - https://studio.appcues.com/flows/aa7daee7-438a-4a82-99d1-768516bf2f6c/settings
export const FEEDBACK_SURVEY_FLOW_ID = 'aa7daee7-438a-4a82-99d1-768516bf2f6c';

export type AppcuesSDK = {
  track: (eventName: string, context?: object) => void;
  identify: (userId: string, context?: object) => void;
  group: (groupId: string, context?: object) => void;
  reset: () => void;
  show: (flowId: string) => void;
};

declare global {
  interface Window {
    Appcues: AppcuesSDK;
  }
}

type AppcuesConfig = {
  enabled: boolean;
  accountId: string;
};

type AppcuesCall = {
  method: keyof AppcuesSDK;
  args?: never[];
};

const LoggingProxy = new Proxy(
  {},
  {
    get(target, prop: string) {
      return (...args: never[]) => {
        logger.debug(`[Appcues:🙊] ${prop}`, { args });
      };
    },
  },
);

// If appCues fails to load, this client is used in place.
const appCuesClientStub: AppcuesSDK = {
  reset: () => logger.info('Attempted to call AppcuesClient.reset without a valid client'),
  identify: () => logger.info('Attempted to call AppcuesClient.identify without a valid client'),
  track: () => logger.info('Attempted to call AppcuesClient.track without a valid client'),
  group: () => logger.info('Attempted to call AppcuesClient.group without a valid client'),
  show: () => logger.info('Attempted to call AppcuesClient.show without a valid client'),
};

export default class AppcuesClient {
  private readonly enabled;
  private readonly accountId;

  private preloadQueue: AppcuesCall[] = [];

  private client: AppcuesSDK;

  private userHasIdentified = false;

  constructor({ enabled, accountId }: AppcuesConfig) {
    this.enabled = enabled;
    this.accountId = accountId;

    const { preloadQueue } = this;
    this.client = !this.enabled
      ? (LoggingProxy as AppcuesSDK)
      : (new Proxy(
          {},
          {
            get(target, prop: keyof AppcuesSDK) {
              // create a proxy that records every method call in a queue for later
              return (...args: never[]) => {
                preloadQueue.push({ method: prop, args });
              };
            },
          },
        ) as AppcuesSDK); // force cast as we know it can intercept any method call
  }

  public async load() {
    if (!this.enabled) return;
    await this.loadAppcues(this.accountId);
  }

  private async loadAppcues(accountId: string) {
    try {
      await loadExternalScript({ src: `//fast.appcues.com/${accountId}.js` });
    } catch (error) {
      logger.info('Error loading Appcues', { error });
      this.client = appCuesClientStub;
      return;
    }

    // GEPPES-2789 in some cases, loadExternalScript may succeed but parsing the script fails...
    if (!window.Appcues) {
      logger.info('Error loading Appcues');
      this.client = appCuesClientStub;
      return;
    }

    // Swap the caching client out for the real client attached by the loaded script
    this.client = window.Appcues;

    try {
      // play queued events back, sending to the real client this time
      this.preloadQueue.forEach(({ method, args }) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.client[method]?.(...args);
      });
      this.preloadQueue = [];
    } catch (error) {
      logger.error('Error sending queued Appcues event', { error });
    }
  }

  reset() {
    this.userHasIdentified = false;
    // prevent sending any track or group data that might trigger a workflow after
    // the user has logged out
    this.preloadQueue = [];

    this.client.reset();
  }

  identify(userId: string, context: object = {}) {
    logger.debug('[Appcues] identify', { userId, context: flattenObject(context) });
    this.client.identify(userId, flattenObject(context));

    this.userHasIdentified = true;
  }

  track(eventName: string, context: object = {}) {
    if (!this.userHasIdentified) return;

    logger.debug('[Appcues] track', { eventName, context: flattenObject(context) });
    this.client.track(eventName, flattenObject(context));
  }

  group(groupId: string, context: object = {}) {
    if (!this.userHasIdentified) return;

    logger.debug('[Appcues] group', { groupId, context: flattenObject(context) });
    this.client.group(groupId, flattenObject(context));
  }

  show(flowId: string) {
    logger.debug('[Appcues] show', { flowId });
    this.client.show(flowId);
  }
}
