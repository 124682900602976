import { KeyValueStorageInterface } from 'aws-amplify/utils';

export class AmplifyAuthStorage implements KeyValueStorageInterface {
  config: Record<string, string> = {};
  authKeysSet: Set<string> = new Set();

  constructor(config: Record<string, string>) {
    this.config = config;
  }

  async setItem(key: string, value: string): Promise<void> {
    window.localStorage.setItem(key, value);
    this.authKeysSet.add(key);
    this.getItem(key);
  }

  async getItem(key: string): Promise<string | null> {
    const value = window.localStorage.getItem(key);

    // write the token to cookies during get to ensure the cookie and localStorage are always in sync
    if (key.includes('idToken')) {
      const { cookieName, authDomain } = this.config;
      document.cookie = `${cookieName}=${value}; Domain=${authDomain}; Path=/; Secure; SameSite=Strict`;
    }

    return value;
  }

  async removeItem(key: string): Promise<void> {
    if (key.includes('idToken')) {
      const { cookieName } = this.config;
      document.cookie = `${cookieName}= ;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }

    this.authKeysSet.delete(key);
    return window.localStorage.removeItem(key);
  }

  async clear(): Promise<void> {
    this.authKeysSet.forEach(key => window.localStorage.removeItem(key));
    this.authKeysSet.clear();

    const { cookieName } = this.config;
    document.cookie = `${cookieName}= ;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
}
