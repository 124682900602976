import AddressService from '@/shared/services/address-service';

import { InternalTypes as Address } from '@Address/store/types';

const AddressActions = {
  async [Address.actions.resolveAddress]({ dispatch }, addressID = null) {
    if (!addressID) {
      throw new Error('addressID is required to resolve an address');
    }

    let address = await AddressService.getAddress(addressID);

    address = await dispatch(Address.actions.storeAddress, address);

    return address;
  },

  async [Address.actions.getAddress]({ getters, dispatch }, addressID = null) {
    let address = getters[Address.getters.address](addressID);

    if (!address) {
      address = await dispatch(Address.actions.resolveAddress, addressID);
    }

    return address;
  },

  async [Address.actions.getEstimateDetails]({ dispatch }, estimate) {
    if (!estimate) {
      throw new Error('Estimate not provided');
    }

    if (
      !['addressFinderLocationID', 'line1', 'line2'].every(key => Object.getOwnPropertyNames(estimate).includes(key))
    ) {
      throw new Error('Estimate invalid');
    }

    let address = await AddressService.getEstimateDetails(estimate);

    address = await dispatch(Address.actions.storeAddress, address);

    return address;
  },

  async [Address.actions.getSuggestionDetails]({ dispatch }, suggestionID) {
    if (!suggestionID) {
      throw new Error('Suggestion ID not provided');
    }

    let address = await AddressService.getSuggestionDetails(suggestionID);

    address = await dispatch(Address.actions.storeAddress, address);

    return address;
  },

  // destructure AddressService response into common format before committing
  [Address.actions.storeAddress]({ commit }, address) {
    commit(Address.mutations.SET_ADDRESS, address);

    return address;
  },
};

export default AddressActions;
