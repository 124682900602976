import { type Plugin, readonly, ref } from 'vue';

const routerProgress: Plugin = {
  install(app, { router }) {
    if (!router) return;

    const routerInProgress = ref(false);
    router.beforeEach(() => {
      routerInProgress.value = true;
    });
    router.afterEach(() => {
      routerInProgress.value = false;
    });
    router.onError(() => {
      routerInProgress.value = false;
    });
    router.inProgress = readonly(routerInProgress);
  },
};

export default routerProgress;
