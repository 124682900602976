<template>
  <component :is="svg" class="svg-icon" :class="{ [`svg-icon-${props.size}`]: true, spin: props.spin }" />
</template>

<script lang="ts" setup>
  import { type Component } from 'vue';

  import { IconSize } from './types';

  const props = withDefaults(
    defineProps<{
      svg: Component;
      size?: IconSize;
      spin?: boolean;
    }>(),
    {
      size: 'text',
    },
  );
</script>

<style lang="scss" scoped>
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  .svg-icon {
    fill: currentColor;

    // @see https://blog.prototypr.io/align-svg-icons-to-text-and-say-goodbye-to-font-icons-d44b3d7b26b4
    &.svg-icon-text {
      height: 1em; // default to the current text height
      top: 0.125em;
      position: relative;
    }

    // sizes based on https://flip-eng.atlassian.net/wiki/spaces/PR/pages/2239660037/Icons+and+illustrations+-+Streamline
    &.svg-icon-small {
      width: 16px;
      height: 16px;
    }

    &.svg-icon-medium {
      width: 24px;
      height: 24px;
    }

    &.svg-icon-large {
      width: 32px;
      height: 32px;
    }

    &.svg-icon-illustration {
      height: 44px;
    }

    &.svg-icon-illustration-large {
      height: 64px;
    }

    &.spin {
      animation: spin 2s infinite linear;
    }
  }
</style>
