<template>
  <AuthWrapper :title="authWrapperTitle">
    <Transition name="wizard" mode="out-in">
      <form
        v-if="forgotPasswordStatus === 'ForgotPassword'"
        key="ForgotPassword"
        novalidate
        @submit.prevent="requestVerificationCode"
      >
        <ErrorBoundary v-bind="errorBoundary">
          <FormFieldWrapper v-slot="inputAttrs" label="Username or email" :validation="$v.username">
            <ControlText
              v-model="username"
              start-focused
              name="username"
              autocomplete="username"
              sensitive
              :disabled="initialUsername"
              v-bind="inputAttrs"
            />
          </FormFieldWrapper>

          <br />
          <div class="buttons is-marginless">
            <BaseButton
              :loading="loading"
              :disabled="$v.username.$invalid"
              variant="primary"
              class="is-fullwidth"
              type="submit"
            >
              Send verification code
            </BaseButton>
          </div>
        </ErrorBoundary>
      </form>
      <div v-else-if="forgotPasswordStatus === 'ResetPassword'" data-testref="ResetPassword">
        <ErrorBoundary v-bind="errorBoundary">
          <ErrorBoundaryDisplay v-slot="{ errors }" :name="errorBoundary.name">
            <div v-if="errors && errors.length">
              <RouterLink to="login">Back</RouterLink>
            </div>
            <div v-else class="is-full has-text-centered">
              <ProgressIndicator size="large" />
            </div>
          </ErrorBoundaryDisplay>
        </ErrorBoundary>
      </div>
      <form
        v-else-if="forgotPasswordStatus === 'ForgotPasswordRequested'"
        key="ForgotPasswordRequested"
        novalidate
        data-testref="ForgotPasswordRequested"
        @submit.prevent="submitForgotPassword"
      >
        <ErrorBoundary v-bind="errorBoundary">
          <BannerMessage v-if="codeFailed" message="" type="danger">
            <p>
              The code is invalid <br />
              Try re-entering or <a @click="resendVerificationCode">resend code</a>.
            </p>
          </BannerMessage>
          <slot name="instructions"></slot>

          <FormFieldWrapper
            v-slot="inputAttrs"
            label="Verification Code"
            :validation="$v.verificationCode"
            :validate-on="['input']"
          >
            <ControlText
              ref="verificationCode"
              v-model="verificationCode"
              start-focused
              autocomplete="one-time-code"
              sensitive
              v-bind="inputAttrs"
            />
          </FormFieldWrapper>

          <br />

          <FormFieldWrapper
            v-slot="inputAttrs"
            label="Enter new password"
            :validation="$v.password"
            :validate-on="['blur', 'change']"
            :ignored-errors="['required', 'minLength']"
          >
            <ControlText
              id="passwordToConfirm"
              v-model="password"
              type="password"
              name="password"
              autocomplete="new-password"
              v-bind="inputAttrs"
            />
          </FormFieldWrapper>

          <FormFieldWrapper
            v-slot="inputAttrs"
            label="Confirm new password"
            :validation="$v.confirmPassword"
            :validate-on="['blur', 'change']"
          >
            <ControlText
              id="confirmPassword"
              v-model="confirmPassword"
              type="password"
              name="confirmPassword"
              autocomplete="new-password"
              v-bind="inputAttrs"
            />
          </FormFieldWrapper>

          <ValidationCheck :validation="$v.password" validation-key="minLength"
            >Must be at least 8 characters long</ValidationCheck
          >
          <ValidationCheck :validation="$v.password" validation-key="uppercase"
            >Must contain at least one uppercase letter</ValidationCheck
          >
          <ValidationCheck :validation="$v.password" validation-key="lowercase"
            >Must contain at least one lowercase letter</ValidationCheck
          >
          <ValidationCheck :validation="$v.password" validation-key="number"
            >Must contain at least one number</ValidationCheck
          >
          <br />
          <div class="buttons is-marginless">
            <BaseButton
              :loading="loading"
              :disabled="$v.$invalid"
              variant="primary"
              class="is-fullwidth mb-2"
              type="submit"
            >
              Change password
            </BaseButton>

            <div class="is-full has-text-centered">
              <p>
                Didn't receive a verification code?<br />
                <BaseLink v-if="!isResendCodeLoading" @click="resendVerificationCode">Resend code.</BaseLink>
                <span v-else>Sending Code</span>
              </p>
            </div>
          </div>
        </ErrorBoundary>
      </form>
      <div
        v-else-if="forgotPasswordStatus === 'ForgotPasswordComplete'"
        key="ForgotPasswordComplete"
        data-testref="ForgotPasswordComplete"
      >
        <slot name="success"></slot>
        <RouterLink to="login">Login</RouterLink>
      </div>
    </Transition>
  </AuthWrapper>
</template>

<script>
  import { mapGetters } from 'vuex';

  import BannerMessage from '@/shared/components/BannerMessage.vue';
  import BaseButton from '@/shared/components/BaseButton.vue';
  import BaseLink from '@/shared/components/BaseLink.vue';
  import ControlText from '@/shared/components/controls/ControlText.vue';
  import FormFieldWrapper from '@/shared/components/controls/wrappers/FormFieldWrapper.vue';
  import ProgressIndicator from '@/shared/components/ProgressIndicator.vue';
  import ValidationCheck from '@/shared/components/ValidationCheck.vue';
  import { ErrorBoundary, ErrorBoundaryDisplay, useErrorBoundary } from '@/shared/errorHandling';
  import { noSpaces, required, requiredIf, sameAs } from '@/shared/validation/customValidations';
  import { passwordValidationRules } from '@/shared/validation/customValidations/passwordValidationRules';

  import Auth from '@Auth/store/types';

  import AuthWrapper from './AuthWrapper.vue';

  export default {
    name: 'ResetPasswordWizard',
    components: {
      ProgressIndicator,
      ErrorBoundary,
      ErrorBoundaryDisplay,
      ValidationCheck,
      BannerMessage,
      BaseButton,
      BaseLink,
      AuthWrapper,
      ControlText,
      FormFieldWrapper,
    },
    props: {
      initialUsername: {
        type: String,
        default: null,
      },
    },

    setup() {
      const { errorBoundary } = useErrorBoundary({
        name: 'error:reset-password',
      });
      return {
        errorBoundary,
      };
    },

    data() {
      return {
        username: '',

        verificationCode: '',
        password: '',
        confirmPassword: '',

        apiErrors: null,
        codeFailed: false,

        loading: false,
        isResendCodeLoading: false,
      };
    },

    validations() {
      return {
        username: { required, noSpaces },

        verificationCode: {
          required: requiredIf(this.isVerificationCodeSent),
          noSpaces,
          codeFailed: () => !this.codeFailed,
        },
        password: {
          required: requiredIf(this.isVerificationCodeSent),
          ...passwordValidationRules,
        },
        confirmPassword: { sameAsPassword: sameAs(this.password) },
      };
    },

    computed: {
      ...mapGetters({
        isVerificationCodeSent: Auth.getters.isVerificationCodeSent,
        verificationCodeDetails: Auth.getters.getVerificationCodeDetails,
        isPasswordResetComplete: Auth.getters.isPasswordResetComplete,
      }),

      authWrapperTitle() {
        return (
          {
            ResetPassword: 'Password Reset',
            ForgotPassword: 'Forgot Password',
            ForgotPasswordRequested: 'Password Reset',
            ForgotPasswordComplete: 'Password has been reset',
          }[this.forgotPasswordStatus] || null
        );
      },

      forgotPasswordStatus() {
        if (this.isPasswordResetComplete) return 'ForgotPasswordComplete';
        if (this.isVerificationCodeSent) return 'ForgotPasswordRequested';
        if (this.initialUsername) return 'ResetPassword';
        return 'ForgotPassword';
      },
    },

    watch: {
      verificationCode() {
        this.codeFailed = false;
      },
    },

    async created() {
      if (this.initialUsername) {
        this.username = this.initialUsername;
        await this.requestVerificationCode();
      }
    },

    unmounted() {
      this.$store.dispatch(Auth.actions.resetForgotPassword);
    },

    methods: {
      async requestVerificationCode() {
        try {
          this.errorBoundary.clearErrors();
          this.loading = true;
          await this.$store.dispatch(Auth.actions.forgotPassword, { username: this.username });
        } finally {
          this.loading = false;
        }
      },

      async submitForgotPassword() {
        try {
          this.errorBoundary.clearErrors();
          this.loading = true;
          await this.$store.dispatch(Auth.actions.forgotPasswordComplete, {
            username: this.username,
            code: this.verificationCode,
            password: this.password,
          });
        } catch (e) {
          if (
            [
              'InvalidParameterException',
              'ExpiredCodeException',
              'CodeMismatchException',
              'UserNotFoundException',
            ].includes(e.code) ||
            [
              'InvalidParameterException',
              'ExpiredCodeException',
              'CodeMismatchException',
              'UserNotFoundException',
            ].includes(e.name)
          ) {
            this.codeFailed = true;
          } else {
            throw e;
          }
        } finally {
          this.loading = false;
        }
      },

      async resendVerificationCode() {
        try {
          // reset state of verification code validations
          this.codeFailed = false;
          this.verificationCode = '';
          this.$v.verificationCode.$reset();

          this.$refs.verificationCode.focus();
          this.apiErrors = null;
          this.isResendCodeLoading = true;
          await this.$store.dispatch(Auth.actions.forgotPassword, { username: this.username });
        } finally {
          this.isResendCodeLoading = false;
        }
      },
    },
  };
</script>

<style scoped>
  .is-full {
    width: 100%;
  }
</style>
