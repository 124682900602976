import { InternalTypes as Consignment } from './types';

const ConsignmentMutations = {
  [Consignment.mutations.SET_VALIDATION_ERRORS](state, errors) {
    state.serverValidation = errors;
  },

  [Consignment.mutations.SET_CONSIGNMENT](state, { consignment, version = null }) {
    state.consignment = consignment;
    state.meta.version = version;
  },

  [Consignment.mutations.SET_CONSIGNMENT_VERSION](state, { version = null }) {
    state.meta.version = version;
  },

  [Consignment.mutations.SET_CONSIGNMENT_ENQUIRIES](state, enquiries) {
    state.enquiries = enquiries;
  },
};

export default ConsignmentMutations;
