<template>
  <AppLayout theme="dark" class="view-login" hide-header>
    <AuthWrapper>
      <div class="content">
        <form novalidate @submit.prevent="verify">
          <div class="logo">
            <img id="brand-img" src="@/assets/efm-logo-login.svg?url" height="100%" alt="efm" />
          </div>

          <h1 class="title">Enter your verification code</h1>
          <p>
            Use your authentication app to generate <br />
            a code and enter it below.
          </p>
          <ErrorBoundary v-bind="errorBoundary">
            <FormFieldWrapper v-slot="inputAttrs" :validation="$v.token" :validate-on="['input']">
              <ControlText
                v-model="token"
                name="token"
                placeholder="Enter verification code"
                sensitive
                start-focused
                v-bind="inputAttrs"
              />
            </FormFieldWrapper>

            <br />
            <div class="buttons">
              <BaseButton id="login-btn" variant="primary" class="is-fullwidth" :loading="isLoading" type="submit">
                Verify
              </BaseButton>
            </div>
          </ErrorBoundary>
        </form>
      </div>
    </AuthWrapper>
  </AppLayout>
</template>

<script>
  import { withMessage } from 'veritas';
  import { mapActions, mapGetters } from 'vuex';

  import BaseButton from '@/shared/components/BaseButton.vue';
  import ControlText from '@/shared/components/controls/ControlText.vue';
  import FormFieldWrapper from '@/shared/components/controls/wrappers/FormFieldWrapper.vue';
  import { ErrorBoundary, useErrorBoundary } from '@/shared/errorHandling';
  import {
    AuthFeedbackError,
    NoAuthenticatedUserError,
    TempPasswordExpiredError,
    TOTPMismatchError,
  } from '@/shared/services/auth/errors';
  import { noSpaces, required } from '@/shared/validation/customValidations';
  import { createLoader } from '@/shared/vuex-async-loader';

  import criticalBoundary from '@App/errorHandling/criticalBoundary';
  import AppLayout from '@App/layout/views/AppLayout.vue';
  import Auth from '@Auth/store/types';

  import AuthWrapper from '../components/AuthWrapper.vue';

  export default {
    name: 'MFAVerify',
    components: {
      AppLayout,
      AuthWrapper,
      ErrorBoundary,
      ControlText,
      FormFieldWrapper,
      BaseButton,
    },

    setup() {
      const { errorBoundary } = useErrorBoundary({
        name: 'error:login',
        onErrorCaptured: ({ error }, { markAsCritical, markAsHandled }) => {
          if (
            error instanceof NoAuthenticatedUserError ||
            error instanceof TempPasswordExpiredError ||
            error instanceof AuthFeedbackError
          ) {
            markAsHandled(error);
          } else if (criticalBoundary.shouldSetCritical(error)) {
            markAsCritical(error);
          }
        },
      });
      return {
        errorBoundary,
      };
    },

    data() {
      return {
        token: '',
        tokenMismatch: false,
      };
    },

    validations() {
      return {
        token: {
          required,
          noSpaces,
          tokenMismatch: withMessage('Verification code does not match', () => !this.tokenMismatch),
        },
      };
    },

    computed: {
      ...mapGetters({
        userRequiresMFAVerification: Auth.getters.userRequiresMFAVerification,
      }),

      isLoading: createLoader(Auth.actions.verifyMFATOTP),
    },

    watch: {
      token() {
        this.tokenMismatch = false;
      },
    },

    created() {
      if (!this.userRequiresMFAVerification) {
        this.$router.push({ name: 'auth.login' });
      }
    },

    methods: {
      ...mapActions({
        verifyMFATOTP: Auth.actions.verifyMFATOTP,
      }),

      async verify() {
        try {
          await this.verifyMFATOTP(this.token.trim());

          // force reload on successful login
          // this is a good opportunity to force the user to reload the app so they get
          // the latest release daily when they log in.
          // @see https://flip-eng.atlassian.net/browse/GEPPES-2223
          const targetRoute = this.$router.resolve({
            name: 'app.home',
            query: { force_reload: `${Date.now()}` },
          });
          window.location = targetRoute.fullPath;
        } catch (error) {
          if (error instanceof TOTPMismatchError) {
            this.tokenMismatch = true;
            return;
          }

          logger.error(error);
          // other errors may be thrown due to session expiry
          this.$router.push({ name: 'auth.login' });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
  }
  :global(.view-login .login-button) {
    display: none;
  }

  h1 {
    font-weight: 400;
    text-align: center;
  }

  p {
    text-align: center;
  }
</style>
