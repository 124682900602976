<template>
  <teleport :to="to">
    <div v-if="modalStack.length">
      <div v-for="modal in modalStack" :key="modal.key" class="modal is-active">
        <ModalWithContext :modal="modal" />
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
  import { useEventListener } from '@vueuse/core';
  import { nextTick, watch } from 'vue';

  import ModalInstance from './ModalInstance';
  import ModalWithContext from './ModalWithContext';

  type ModalViewProps = { modalStack: ModalInstance[]; to?: string };

  const props = withDefaults(defineProps<ModalViewProps>(), {
    to: 'body',
  });

  function updateBodyStyles() {
    const { body } = document;
    if (props.modalStack.length) {
      body.style.height = '100vh';
      body.style.overflowY = 'hidden';
    } else {
      body.style.height = '';
      body.style.overflowY = '';
    }
  }

  watch(
    () => props.modalStack,
    () => {
      updateBodyStyles();

      // TODO controlled by prop? should it live in the ModalCard/DrawerCard?
      if (props.modalStack.length) {
        nextTick(() => {
          const focusableContent = getFocusableContent();
          const firstFocusableElement = focusableContent[0];
          firstFocusableElement?.focus();
        });
      }
    },
  );

  function getFocusableContent() {
    const focusableElements =
      'button:not([tabindex="-1"]):not([disabled]), [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = [...document.querySelectorAll('.modal.is-active')].at(-1);

    return modal?.querySelectorAll<HTMLElement>(focusableElements) || [];
  }

  // TODO controlled by prop?
  useEventListener('keydown', e => {
    const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

    if (!isTabPressed) {
      return;
    }

    const focusableContent = getFocusableContent();
    const firstFocusableElement = focusableContent[0];
    const lastFocusableElement = focusableContent[focusableContent.length - 1];

    if (e.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus();
        e.preventDefault();
      }
      // if focused has reached to last focusable element then focus first focusable
      // element after pressing tab
    } else if (document.activeElement === lastFocusableElement) {
      firstFocusableElement.focus();
      e.preventDefault();
    }
  });
</script>

<style lang="scss" scoped></style>
