import { Temporal } from '@js-temporal/polyfill';

import DateRange from '@/shared/DateTime/DateRange';
import { AnyDateType, DateLike, temporalFromAnything } from '@/shared/DateTime/helpers';
import { AnyTemporalDateType, Interval, TimeRange } from '@/shared/DateTime/index';
import {
  CanBecomeDate,
  temporalInstantToDate,
  temporalPlainDateToDate,
  temporalToDate,
} from '@/shared/DateTime/mappers';

// import { DateLike } from '@App/filters/formatDateRange';

import DATETIME_LOCALE_FORMATS from './dateTimeLocaleFormats';

export const temporalDurationToHumanString = (duration?: Temporal.Duration) => {
  if (!duration) return '';

  const parts = [];

  if (duration.weeks) parts.push(`${duration.weeks} ${duration.weeks === 1 ? 'week' : 'weeks'}`);
  if (duration.days) parts.push(`${duration.days} ${duration.days === 1 ? 'day' : 'days'}`);
  if (duration.hours) parts.push(`${duration.hours} ${duration.hours === 1 ? 'hour' : 'hours'}`);
  if (duration.minutes) parts.push(`${duration.minutes} ${duration.minutes === 1 ? 'minute' : 'minutes'}`);

  return parts.join(' ');
};

// GEPPES-2882 PlainDateTime.toLocaleString throws errors on macOS 14 in Chrome
// https://github.com/js-temporal/temporal-polyfill/issues/263
export const temporalPlainDateToLocaleString = (
  plainDate: Temporal.PlainDate | undefined,
  locales: string | string[],
  options: object,
) => {
  if (!plainDate) return '';
  const date = temporalPlainDateToDate(plainDate);
  return date.toLocaleString(locales, options);
};

// 02/09/2022
export const temporalPlainDateToLocalString = (plainDate?: Temporal.PlainDate) =>
  temporalPlainDateToLocaleString(plainDate, 'en-AU', DATETIME_LOCALE_FORMATS.DATE);

// Returns: a string in the ISO 8601 date format representing datetime.
export const temporalPlainDateTimeToString = (plainDateTime?: Temporal.PlainDateTime) =>
  plainDateTime ? plainDateTime.toString({ smallestUnit: 'second' }) : '';

export function to12hString(date: Date | CanBecomeDate | Temporal.PlainTime): string {
  let jsDate = new Date();
  if (date instanceof Date) jsDate = date;
  else if (date instanceof Temporal.PlainTime) {
    jsDate.setHours(date.hour);
    jsDate.setMinutes(date.minute);
    jsDate.setSeconds(date.second);
  } else {
    jsDate = temporalToDate(date);
  }

  const amPm = jsDate.getHours() >= 12 ? 'pm' : 'am';
  const hour = jsDate.getHours() % 12 || 12;
  return `${hour}:${jsDate.getMinutes().toString().padStart(2, '0')}${amPm}`;
}

export function toISODate(date: Date | CanBecomeDate): string {
  const jsDate = date instanceof Date ? date : temporalToDate(date);
  return `${jsDate.getFullYear()}-${(jsDate.getMonth() + 1).toString().padStart(2, '0')}-${jsDate.getDate().toString().padStart(2, '0')}`;
}

export function dateRangeToString(dateRange: DateRange): string {
  if (!dateRange || !Array.isArray(dateRange)) return '';
  return dateRange.join(',');
}

export function to24hHHMM(date: Date | CanBecomeDate | Temporal.PlainTime): string {
  let jsDate = new Date();
  if (date instanceof Date) jsDate = date;
  else if (date instanceof Temporal.PlainTime) {
    jsDate.setHours(date.hour);
    jsDate.setMinutes(date.minute);
  } else {
    jsDate = temporalToDate(date);
  }
  return `${jsDate.getHours().toString().padStart(2, '0')}:${jsDate.getMinutes().toString().padStart(2, '0')}`;
}

const getLocaleForTimeZone = (tz: string) => {
  const { locale } = Intl.DateTimeFormat().resolvedOptions();
  // force localisation
  if (/^Australia/.test(tz)) return 'en-AU';
  return locale;
};

type SupportedDateTypes = Date | string | AnyTemporalDateType;
const relativeDate = (date?: SupportedDateTypes): Date => {
  if (!date) return new Date();
  if (date instanceof Date) return date;
  if (typeof date === 'string') temporalInstantToDate(Temporal.Instant.from(date));
  if (typeof date === 'object') {
    // Temporal.Instance does not contain calendar information, using epochMilliseconds
    if ('epochMilliseconds' in date) return new Date(date.epochMilliseconds);
    // use calendar information in case of raw Temporal types
    return new Date(date.year, date.month - 1, date.day);
  }
  throw new Error(`could not get relative date out of ${date}`);
};

export const getTimeZoneAbbreviation = (tz: string | Temporal.TimeZone, date?: SupportedDateTypes) => {
  if (!tz) return null;
  try {
    const formatter = new Intl.DateTimeFormat(getLocaleForTimeZone(tz.toString()), {
      timeZoneName: 'short',
      timeZone: tz.toString(),
    });
    const abbr = formatter.formatToParts(relativeDate(date)).find(part => part.type === 'timeZoneName')?.value;

    if (!abbr) {
      logger.warn(`could not find abbreviation for ${tz} and ${date}`);
      return null;
    }

    return /[-+]\d+/.test(abbr) ? '' : abbr;
  } catch {
    // failed to parse
    return null;
  }
};

export const getTimeZoneOffset = (tz: string | Temporal.TimeZone, date?: SupportedDateTypes) => {
  if (!tz) return null;
  try {
    const formatter = new Intl.DateTimeFormat(getLocaleForTimeZone(tz.toString()), {
      timeZoneName: 'shortOffset',
      timeZone: tz.toString(),
    });
    const offset = formatter.formatToParts(relativeDate(date)).find(part => part.type === 'timeZoneName')?.value;

    if (!offset) {
      logger.warn(`could not find abbreviation for ${tz} and ${date}`);
      return null;
    }

    return offset.replace('GMT', 'UTC');
  } catch (error) {
    logger.debug('Failed to parse timeZone ', tz, error);
    // failed to parse
    return null;
  }
};

export function formatDate(date: AnyDateType, locale = navigator.language) {
  if (!date) return '-';

  return temporalFromAnything(date).toLocaleString(locale, DATETIME_LOCALE_FORMATS.DATE);
}

export function formatTime(date: AnyDateType, hour12 = true, locale = navigator.language) {
  if (!date) return '';

  if (date instanceof Temporal.PlainDate || (typeof date === 'string' && !date.includes('T'))) {
    // TODO: what is the desired behaviour when there is no time component?
    return '';
  }

  return temporalFromAnything(date)
    .toLocaleString(locale, {
      timeStyle: 'short',
      hour12,
    })
    .toUpperCase(); // pm to PM per designs
}

export function formatDateTime(date: AnyDateType, hour12 = true) {
  if (!date) return '-';

  return `${formatDate(date)} at ${formatTime(date, hour12)}`;
}

export function formatDateShort(date: AnyDateType, locale = navigator.language) {
  if (!date) return '-';

  return temporalFromAnything(date).toLocaleString(locale, DATETIME_LOCALE_FORMATS.DATE_SHORT);
}

export function formatDateReadable(date: AnyDateType, locale = navigator.language) {
  if (!date) return '-';

  return temporalFromAnything(date).toLocaleString(locale, DATETIME_LOCALE_FORMATS.DATE_D_MON_YYYY);
}

export default function formatTimeRange(timeRange: TimeRange) {
  if (!timeRange) return '-';

  const from = timeRange.start;
  const to = timeRange.end;

  return `${to12hString(from)} ${to ? `- ${to12hString(to)}` : ''}`;
}

export const formatTimeInterval = (interval: Interval) =>
  `${to12hString(interval.start)} - ${to12hString(interval.end)}`;

export function formatDateRange(
  dateRange: string | [string, string?] | [DateLike, DateLike?] | undefined | null,
  locale = navigator.language,
) {
  if (!dateRange) return '-';

  const dates = Array.isArray(dateRange) ? dateRange : [dateRange];

  return dates.length === 1
    ? formatDate(dates[0], locale)
    : `${formatDate(dates[0], locale)} - ${formatDate(dates[1], locale)}`;
}
