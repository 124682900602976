<template>
  <button type="button" class="header-button">
    <slot></slot>
  </button>
</template>

<style scoped lang="scss">
  .header-button {
    @include Paragraph-default;
    font-weight: $font-weight-regular;
    text-align: left;
    height: 44px;
    padding: 4px 12px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--chrome-50);
    border-radius: $radius;
    cursor: pointer;
    transition:
      border-color 0.2s ease,
      box-shadow 0.2s ease;

    &:hover:not(:disabled):not(.disabled) {
      border-color: var(--chrome-100);
      box-shadow: $panel-shadow;
    }

    &:active,
    &:focus,
    &.expanded {
      outline: none;
      border-color: var(--brand-100);
      box-shadow: 0 0 4px rgba(71, 43, 136, 0.25);
    }

    &:disabled,
    &.disabled {
      cursor: not-allowed;
    }
  }
</style>
