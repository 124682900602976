import createFeatures, { CreateFeatureContext } from '@/shared/createFeatures';
import developmentFlags from '@/shared/developmentFlags';

import { OrganisationWithSites, Site } from '@/shared/models';

import appFlags from '@App/config/flags';
import { hasContext, useMaybeOrg, useSite } from '@App/context';

type GlobalFlags = Record<keyof typeof appFlags, boolean | string>;
export interface AppContext {
  flags: GlobalFlags;
  org?: OrganisationWithSites;
  site?: Site;
}

// resolve flags once, globally as these can't change at runtime
const globalFlags = developmentFlags.resolve({ namespace: 'sender-ui', definition: appFlags }) as GlobalFlags;

function createAppContext(): CreateFeatureContext<AppContext> {
  if (hasContext()) {
    return {
      flags: globalFlags,
      org: useMaybeOrg(),
      site: useSite(),
    };
  }

  return {
    flags: globalFlags,
  };
}
const { createFeature, useFeature } = createFeatures(createAppContext);

// helper functions to check if a feature is enabled on any site in the current org
type SiteCheckCallback = (site: Site) => boolean;
export function anySites(siteCheck: SiteCheckCallback) {
  return ({ org }: AppContext) => Object.values(org?.sites || {}).some(siteCheck);
}

// helper functions to check if a feature is enabled on all sites in the current org
export function allSites(siteCheck: SiteCheckCallback) {
  return ({ org }: AppContext) => Object.values(org?.sites || {}).every(siteCheck);
}

// helper functions to check if a feature is enabled on the current site,
// or if no site is selected, any site in the current org
export function currentSiteOrAnySites(siteCheck: SiteCheckCallback) {
  return (context: AppContext) => (context.site ? siteCheck(context.site) : anySites(siteCheck)(context));
}

export { useFeature, createFeature };
