import config from '@/config';

import modules from '@App/modules/modules';
import actions from '@App/store/actions';
import getters from '@App/store/getters';
import mutations from '@App/store/mutations';
import { extractModuleStoreConfiguration } from '@App/store/store-utils';

const storeConfig = {
  strict: config.debug, // strict mode is slow and should not be used in prod

  // Load all module stores
  modules: extractModuleStoreConfiguration(modules),

  // Root store
  state: {
    userHasChangedSite: false,
  },
  getters,
  mutations,
  actions,
};

export default storeConfig;
