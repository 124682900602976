import AddressService from '@/shared/services/address-service';

import ConsignmentResultSetStore from '@App/store/modules/ConsignmentResultSetStore';

import QuotesStore from '@App/store/modules/QuotesStore';

import { name as moduleName } from '../module';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const addressTypes = {
  actions: {
    fetchAddress: 'fetchAddress',
  },
  mutations: {
    SET_ADDRESS: 'SET_ADDRESS',
  },
};

const consignmentsModuleName = 'list';

/**
 * Consignment store definition
 */
const ConsignmentStore = {
  namespaced: true,
  state: {
    consignment: null,
    meta: {
      version: null,
    },
    enquiries: [],

    serverValidation: null,
  },
  getters,
  mutations,
  actions,
  modules: {
    quotes: QuotesStore,
    [consignmentsModuleName]: ConsignmentResultSetStore,
    address: {
      state: {
        addresses: {},
      },
      actions: {
        [addressTypes.actions.fetchAddress]: async ({ commit }, id) => {
          const address = await AddressService.getAddress(id);

          commit(addressTypes.mutations.SET_ADDRESS, address);
        },
      },
      getters: {
        getAddressById: state => id => state.addresses[id],
        getAllAddresses: state => state.addresses,
      },
      mutations: {
        [addressTypes.mutations.SET_ADDRESS]: (state, { id, ...attrs }) => {
          state.addresses[id] = { id, ...attrs };
        },
      },
    }, // view logic I need address resolution
  },
};

const consignmentsNamespace = `${moduleName}/${consignmentsModuleName}`;

export { ConsignmentStore as default, consignmentsNamespace };
