<template>
  <div class="app-layout" :class="`theme-${theme}`">
    <AppHeader v-show="!hideHeader">
      <template #header-navigation>
        <slot name="header-navigation"></slot>
      </template>
      <template #header-context>
        <slot name="header-context"></slot>
      </template>
    </AppHeader>
    <main class="main container is-fluid">
      <slot></slot>
    </main>
  </div>
</template>

<script>
  import AppHeader from '@App/layout/components/AppHeader.vue';

  export default {
    name: 'AppLayout',
    components: {
      AppHeader,
    },
    props: {
      theme: {
        type: String,
        default: 'light',
        validator: value => ['light', 'dark'].includes(value),
      },
      hideHeader: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .app-layout {
    padding-bottom: 2rem;
    flex-grow: 1;
    &.theme-dark {
      background-color: $brand-110;
    }
  }

  .main {
    padding: 40px 24px 0 24px;
    //padding-top: 40px;
    min-height: 20rem;
  }
</style>
