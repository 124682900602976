/**
 * OrgStore - mutation methods
 * @see https://vuex.vuejs.org/guide/mutations.html
 */
import { InternalTypes as Org } from './types';

const OrgMutations = {
  [Org.mutations.SET_USER](state, user) {
    state.user = user;
  },
  [Org.mutations.SET_USER_ACCEPT_TERMS](state, { acceptTime }) {
    state.user.acceptedTermsAt = acceptTime;
  },
};

export default OrgMutations;
