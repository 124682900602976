import { Temporal } from '@js-temporal/polyfill';

type PlainDateRange = Temporal.PlainDate | [Temporal.PlainDate, Temporal.PlainDate];

export const isValidPlainDateRange = (range: unknown): range is PlainDateRange =>
  range instanceof Temporal.PlainDate ||
  (Array.isArray(range) &&
    range.length === 2 &&
    range[0] instanceof Temporal.PlainDate &&
    range[1] instanceof Temporal.PlainDate);

export default PlainDateRange;
