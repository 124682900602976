<template>
  <slot></slot>
</template>

<script>
  import { getConfig } from '@/shared/init';

  import handleErrorCaptured from './handleErrorCaptured';
  import { errorIsHandled } from './helpers';

  export default {
    name: 'MainErrorBoundary',

    errorCaptured(error, vm, info) {
      const { debug } = getConfig();

      handleErrorCaptured(error, vm, info);

      const wasHandled = errorIsHandled(error);

      if (wasHandled) {
        // log a warn in development mode for clarity
        const handledLogLevel = debug ? 'warn' : 'debug';
        logger[handledLogLevel](`handled error - ${error.message}`, { error, info }, { vm, info });
      }

      // if handled, prevent error to be sent to app.config.errorHandle
      return !wasHandled;
    },
  };
</script>
