const DATE: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

const DATE_SHORT: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
};

const DATE_D_MON_YYYY: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};

const TIME_SHORT: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
};

const TIME_HHMM: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  second: undefined,
  hour12: false,
};

const DATETIME_LOCALE_FORMATS = {
  // date
  DATE,
  DATE_SHORT,
  DATE_D_MON_YYYY,
  // time
  TIME_SHORT,
  TIME_HHMM,
};

export default DATETIME_LOCALE_FORMATS;
