import { Component, h } from 'vue';

import configureErrorHandling from '@/shared/errorHandling/config';

import ErrorBoundary from './ErrorBoundary.vue';
import ErrorBoundaryDisplay from './ErrorBoundaryDisplay.vue';
import ErrorBoundaryDisplayStub from './ErrorBoundaryDisplayStub';
import ErrorBoundaryStub from './ErrorBoundaryStub';
import MainErrorBoundary from './MainErrorBoundary.vue';
import useErrorBoundary, { ErrorBoundaryConfig } from './useErrorBoundary';

const ErrorHandling = {
  install: configureErrorHandling,
  createProtectedApp(AppComponent: Component) {
    return {
      render() {
        return h(
          MainErrorBoundary,
          {},
          {
            default() {
              return h(AppComponent);
            },
          },
        );
      },
    };
  },
};

export {
  ErrorHandling as default,
  ErrorBoundary,
  ErrorBoundaryDisplay,
  ErrorBoundaryStub,
  ErrorBoundaryDisplayStub,
  useErrorBoundary,
};

export type { ErrorBoundaryConfig };
